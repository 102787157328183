import { Length, success } from '../../utils/flash';
import { logError } from '../../utils/logger';
import { backendGet, backendPost } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { contactField } from './contact-field';
import { googleCaptcha } from './google-captcha';
import { percyModal } from './percy-modal';
const getNestedElValue = (el, nestedTag) => {
    const nestedEl = el.querySelector(nestedTag);
    return nestedEl.value;
};
const sendInquiry = (name, email, msg, whatsapp, subject, token) => {
    const inquiryPayload = {
        subject: `[${subject.charAt(0).toUpperCase()}${subject.slice(1)}] ${getNestedElValue(name, 'input')}`,
        body: `<strong>Name</strong>: ${getNestedElValue(name, 'input')}<br><br>
<strong>E-mail</strong>: ${getNestedElValue(email, 'input')}<br><br>
<strong>Whatsapp</strong>: ${getNestedElValue(whatsapp, 'input')}<br><br>
<strong>Message</strong>: ${getNestedElValue(msg, 'textarea')}`,
        token,
    };
    backendPost('contact-us', JSON.stringify(inquiryPayload), (_res) => {
        var _a;
        success(getTranslation('form-success'), Length.long);
        (_a = document.querySelector('#contact-us-modal')) === null || _a === void 0 ? void 0 : _a.remove();
    }, logError);
};
export const contactUsModal = (subject) => {
    var _a, _b, _c, _d, _e, _f;
    const result = document.createElement('contact-us-modal');
    const currentName = window.store.currentUser ? window.store.currentUser.name : '';
    const currentEmail = window.store.currentUser ? window.store.currentUser.email : (_a = document.querySelector('#magic-link-email')) === null || _a === void 0 ? void 0 : _a.textContent;
    const modalBody = document.createElement('modal-body');
    const form = document.createElement('form');
    form.id = 'contact-form';
    const nameField = contactField('input', 'text', 'name', true, currentName);
    const emailField = contactField('input', 'email', 'email', true, currentEmail);
    const messageField = contactField('textarea', 'text', 'message', true);
    const whatsappField = contactField('input', 'tel', 'whatsapp', false);
    const btn = document.createElement('button');
    btn.textContent = getTranslation('contact-submit');
    btn.className = 'primary-button';
    form.append(nameField, emailField, whatsappField, messageField, btn);
    modalBody.appendChild(form);
    const modal = percyModal(getTranslation('contact-page'), [], '', false, false, modalBody, '', '', () => result.remove());
    modal.id = 'contact-us-modal';
    (_b = document.querySelector('percy-body')) === null || _b === void 0 ? void 0 : _b.appendChild(modal);
    (_c = modal.querySelector('modal-wrapper')) === null || _c === void 0 ? void 0 : _c.setAttribute('style', 'max-width: 680px; width: 100%; overflow-y: auto;');
    const captcha = googleCaptcha();
    if (subject !== 'email-not-sent') {
        const admin = ((_e = (_d = window.store) === null || _d === void 0 ? void 0 : _d.selectedTeam) === null || _e === void 0 ? void 0 : _e.admin) || window.store.currentUser.email;
        backendGet(`credits-balance-for?admin=${admin}`, (res) => {
            const balance = res;
            if (balance <= 0) {
                form.insertBefore(captcha, btn);
            }
        }, logError);
    }
    else {
        form.insertBefore(captcha, btn);
    }
    (_f = nameField.querySelector('input')) === null || _f === void 0 ? void 0 : _f.select();
    form.addEventListener('submit', (event) => {
        event.preventDefault();
        sendInquiry(nameField, emailField, messageField, whatsappField, subject, captcha.getAttribute('token'));
    });
    return result;
};
