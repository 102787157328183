import { getTranslation } from '@klauswuestefeld/jux-web';
import { showTeamMemberModal } from '../teams';
import { isAdminOrSubadmin } from './edit-members-modal';
import { memberStatus } from './member-status';
import { userPicture } from './user-picture';
const isOnlyAppraised = (member) => {
    var _a, _b;
    return !((_a = member.role) === null || _a === void 0 ? void 0 : _a.includes('appraiser')) && !((_b = member.role) === null || _b === void 0 ? void 0 : _b.includes('sub-admin'));
};
const teamMemberRole = (teamMember, adminOrSubadmin) => {
    const role = teamMember.role || [];
    let text = adminOrSubadmin ? 'Admin' : '';
    if (role.includes('appraiser') && role.includes('appraised')) {
        text += adminOrSubadmin ? ', ' + getTranslation('appraiser-appraised') : getTranslation('appraiser-appraised');
    }
    if (role.includes('appraiser') && !role.includes('appraised')) {
        text += adminOrSubadmin ? ' & ' + getTranslation('appraiser') : getTranslation('appraiser');
    }
    if (!role.includes('appraiser') && role.includes('appraised')) {
        text += adminOrSubadmin ? ' & ' + getTranslation('appraised') : getTranslation('appraised');
    }
    return text;
};
export const membersList = (teamMembers, isTeamAdmin = false) => {
    const result = document.createElement('members-list');
    const alphabeticallySortedMembers = teamMembers.sort((a, b) => a.name.localeCompare(b.name));
    alphabeticallySortedMembers.forEach((teamMember) => {
        const adminOrSubadmin = isAdminOrSubadmin(teamMember);
        const row = document.createElement('member-info');
        row.onclick = () => showTeamMemberModal(teamMember.email, isTeamAdmin);
        const largeColumn = document.createElement('member-data');
        const modifiedAttrs = teamMember['modified-attributes'];
        const picture = userPicture(teamMember.picture);
        if (adminOrSubadmin) {
            const crown = document.createElement('member-crown');
            crown.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.11 18.19" width="16px" height="16px">
        <defs>
          <style>.cls-1{fill:#ff6;stroke:#000;stroke-miterlimit:10;}</style>
        </defs>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_2-2" data-name="Layer 2">
            <g id="Coroa_no_perfil" data-name="Coroa no perfil">
              <polygon class="cls-1" points="0.68 10.7 12.81 17.5 17.98 8.3 11.76 10.86 11.91 4.9 6.9 8.14 5.84 1.5 0.68 10.7"/>
            </g>
          </g>
        </g>
      </svg>
      `;
            picture.appendChild(crown);
        }
        if (modifiedAttrs === null || modifiedAttrs === void 0 ? void 0 : modifiedAttrs.includes('picture')) {
            picture === null || picture === void 0 ? void 0 : picture.classList.add('modified-attribute');
        }
        const name = document.createElement('member-name');
        if (modifiedAttrs === null || modifiedAttrs === void 0 ? void 0 : modifiedAttrs.includes('name')) {
            name.classList.add('modified-attribute');
        }
        name.textContent = teamMember.name;
        const email = document.createElement('member-email');
        email.textContent = teamMember.email;
        const role = document.createElement('member-role');
        if (modifiedAttrs === null || modifiedAttrs === void 0 ? void 0 : modifiedAttrs.includes('role')) {
            role.classList.add('modified-attribute');
        }
        role.textContent = teamMemberRole(teamMember, adminOrSubadmin);
        if (isOnlyAppraised(teamMember)) {
            role.style.textAlign = 'right';
        }
        largeColumn.append(picture, name, email, role);
        if (teamMember['license-status']) {
            const license = document.createElement('member-license');
            largeColumn.className = 'member-data-with-license-info';
            // We only have active and no-license as license-status value yet, in the future, when expired is implemented, change this logic
            license.textContent = teamMember['license-status'] === 'active' ? getTranslation('licensed') : getTranslation('unlicensed');
            license.className = teamMember['license-status'] === 'active' ? 'status-finished' : 'status-pending';
            largeColumn.appendChild(license);
        }
        row.appendChild(largeColumn);
        if (teamMember.status) {
            const statusColumn = memberStatus({
                text: getTranslation(`status-${teamMember.status}`),
                tooltip: getTranslation(`tooltip-${teamMember.status}`),
                style: `status-${teamMember.status}`,
            });
            row.appendChild(statusColumn);
        }
        result.appendChild(row);
    });
    return result;
};
