import { loadAppraisalsPage } from '../appraisals';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { appraisalSubheader } from './appraisal-subheader';
import { showAllowAppraisalsModal } from './round-status';
export const closedRound = () => {
    const team = window.store.selectedTeam;
    const result = document.createElement('closed-round');
    const closedRoundMsg = document.createElement('closed-round-msg');
    closedRoundMsg.id = 'closed-round';
    const container = document.createElement('msg-container');
    container.style.display = 'block';
    if (!team['is-admin']) {
        closedRoundMsg.textContent = getTranslation('closed-round-msg');
        container.append(closedRoundMsg);
        result.append(appraisalSubheader(false), container);
        return result;
    }
    const br = document.createElement('br');
    const link = document.createElement('percy-link');
    link.id = 'closed-round-link';
    link.setAttribute('class', 'link');
    link.addEventListener('click', async () => {
        const text = document.createElement('b');
        text.textContent = getTranslation('unpublish-alert');
        showAllowAppraisalsModal(text, null, true, loadAppraisalsPage);
    });
    link.textContent = getTranslation('closed-round-link');
    closedRoundMsg.textContent = getTranslation('closed-round-msg-admin');
    container.append(closedRoundMsg, br, link);
    result.append(appraisalSubheader(false), container);
    return result;
};
