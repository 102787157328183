import { changePage } from '../../utils/navigation';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { isFirstTimer } from '../session';
export const hamburgerLinks = (isSuperAdmin = false) => {
    // TODO transformar hamburger links em componentes individuais
    const result = document.createElement('hamburger-links');
    const listItems = [
        { id: 'superadmin-menu', fn: () => changePage('superadmin-tab') },
        { id: 'admin-menu', fn: () => changePage('teams-tab') },
        { id: 'appraisals-menu', fn: () => changePage('appraisals-tab') },
        { id: 'results-menu', fn: () => changePage('results-tab') },
        { id: 'account-menu', fn: () => changePage('account-tab') },
    ];
    for (const item of listItems) {
        if (item.id === 'superadmin-menu' && !isSuperAdmin) {
            continue;
        }
        else if (item.id === 'results-menu' && isFirstTimer()) {
            continue;
        }
        else if (item.id === 'appraisals-menu' && isFirstTimer()) {
            continue;
        }
        const newItem = document.createElement('li');
        newItem.id = item.id;
        newItem.addEventListener('click', item.fn);
        newItem.textContent = getTranslation(item.id);
        result.appendChild(newItem);
    }
    ;
    return result;
};
