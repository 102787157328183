import { getSelectedTeam } from '../api-client';
import { adminQuestion } from './admin-question';
import { membersActions } from './members-actions';
import { membersSection } from './members-section';
import { hideSpinner, showSpinner } from './percy-spinner';
import { roundControl } from './round-control';
import { teamNameSection } from './team-name-section';
const teamSection = (result, team) => {
    if (!team) {
        hideSpinner(result);
        return result;
    }
    const isAdmin = team['is-admin'];
    result.append(teamNameSection(isAdmin), adminQuestion(team.question, team['question-details'], isAdmin), membersActions(isAdmin), membersSection());
    if (isAdmin) {
        result.appendChild(roundControl());
    }
    hideSpinner(result);
    return result;
};
export const percyTeam = (isUpdate = false) => {
    const result = document.createElement('percy-team');
    let isRequestDone = false;
    setTimeout(() => {
        if (!isRequestDone) {
            showSpinner(result);
        }
    }, 2000);
    if (isUpdate) {
        isRequestDone = true;
        const team = window.store.selectedTeam;
        return teamSection(result, team);
    }
    getSelectedTeam().then((team) => {
        isRequestDone = true;
        window.store.selectedTeam = team;
        return teamSection(result, team);
    });
    return result;
};
