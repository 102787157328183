import { currentPage, Page } from '../utils/navigation';
import { getStorage, setStorage } from './api-client';
import { OnboardModal } from './components/OnboardModal';
const pageToOnboarding = () => {
    switch (currentPage()) {
        case Page.TEAMS:
            return 'accessed-admin-onboarding';
        case Page.APPRAISALS:
            return 'accessed-appraisals-onboarding';
        case Page.RESULTS:
            return 'accessed-results-onboarding';
        default:
            return 'accessed-admin-onboarding';
    }
};
export const firstAccessOnboard = async () => {
    const onboard = pageToOnboarding();
    const res = await getStorage(onboard);
    if (!res) {
        await setStorage(onboard, true);
        new OnboardModal({}).show();
    }
};
