import { Length, warning } from '../../utils/flash';
import { logError } from '../../utils/logger';
import { singularOrPluralString, currencyFormatter } from '../../utils/string';
import { backendGet, setStorage } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { contactUsModal } from './contact-us-modal';
import { PayPalButton } from './PayPalButton';
import { percyModal } from './percy-modal';
let licensePrice = 0;
let timeout;
const createPaypalTransaction = (amount, country, notaFiscal) => {
    const totalPrice = licensePrice * Number(amount);
    const currency = country === 'BR' || country === 'TE' ? 'BRL' : 'USD';
    const currencyTxt = country === 'BR' ? 'BRL' : 'USD';
    const description = getTranslation('purchase-description');
    const licensesPrice = document.querySelector('licenses-price');
    licensesPrice.innerText = currencyFormatter(currencyTxt, totalPrice);
    const paypalBtn = document.querySelector('paypal-button');
    if (!paypalBtn) {
        logError('paypal-button não foi encontrado', 'generic-help-msg');
        return;
    }
    paypalBtn.setAttribute('country', country);
    paypalBtn.setAttribute('currency', currency);
    paypalBtn.setAttribute('amount', amount);
    paypalBtn.setAttribute('description', description);
    paypalBtn.setAttribute('nota-fiscal', notaFiscal.toString());
};
const valuesChanged = (amount, country, notaFiscal) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
        createPaypalTransaction(amount, country, notaFiscal);
    }, 300);
};
const requestPaymentMethods = (amount) => {
    var _a;
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(contactUsModal('request-for-other-payments'));
    const messageField = document.querySelector('contact-field textarea');
    messageField.value = getTranslation('request-for-other-payments') + singularOrPluralString(Number(amount), 'licenses');
};
const setTermsAndConditions = async (ev) => {
    const isChecked = ev.target.checked;
    await setStorage('accepted-terms-and-conditions', isChecked);
};
export const paymentModal = (amount, country, notaFiscal = false) => {
    const result = document.createElement('payment-modal');
    if (!country) {
        warning(getTranslation('no-selected-country'), Length.long);
        return result;
    }
    backendGet(`billing/price?country-code=${country}`, (res) => {
        licensePrice = res;
        if (!licensePrice) {
            logError('endpoint price retornou licensePrice inválido', 'generic-help-msg');
            return result;
        }
        const modalBody = document.createElement('modal-body');
        modalBody.id = 'payment-modal-content';
        const paypalSpinner = document.createElement('percy-spinner');
        paypalSpinner.id = 'paypal-spinner';
        paypalSpinner.className = 'large';
        paypalSpinner.style.display = 'none';
        paypalSpinner.style.zIndex = '99';
        const licensesRow = document.createElement('percy-row');
        licensesRow.className = 'licenses-row';
        const licensesContainer = document.createElement('licenses-container');
        const paidLicensesText = document.createElement('label');
        paidLicensesText.id = 'paid-licenses-text';
        paidLicensesText.textContent = getTranslation('paid-licenses-text');
        paidLicensesText.htmlFor = 'licenses-purchase';
        const licensesPurchase = document.createElement('input');
        licensesPurchase.id = 'licenses-purchase';
        licensesPurchase.type = 'number';
        licensesPurchase.classList.add('textfield', 'small');
        licensesPurchase.min = '1';
        licensesContainer.append(paidLicensesText, licensesPurchase);
        const licensesPrice = document.createElement('licenses-price');
        licensesRow.append(licensesContainer, licensesPrice);
        const beneficiary = document.createElement('p');
        beneficiary.id = 'beneficiary-text';
        beneficiary.textContent = getTranslation('beneficiary-text');
        backendGet(`storage-get?key=accepted-terms-and-conditions`, (ret) => {
            var _a;
            const termsRow = document.createElement('percy-row');
            termsRow.className = 'terms-row';
            const input = document.createElement('input');
            input.type = 'checkbox';
            input.id = 'terms-of-use-input';
            input.checked = ret;
            input.addEventListener('change', (ev) => setTermsAndConditions(ev));
            const label = document.createElement('label');
            label.htmlFor = 'terms-of-use-input';
            label.className = 'terms-label';
            const percyTerms = document.createElement('terms-text');
            percyTerms.innerHTML = getTranslation('check-terms-of-use');
            label.appendChild(percyTerms);
            termsRow.append(input, label);
            const paypalButtonContainer = document.createElement('paypal-button-container');
            paypalButtonContainer.appendChild(new PayPalButton());
            const paypalButtonSpinner = document.createElement('percy-spinner');
            paypalButtonSpinner.id = 'paypal-button-spinner';
            paypalButtonSpinner.classList.add('large', 'spinner-paypal');
            modalBody.append(paypalSpinner, licensesRow, beneficiary, termsRow, paypalButtonContainer, paypalButtonSpinner);
            if (country === 'BR') {
                const otherPayment = document.createElement('percy-row');
                otherPayment.innerHTML = getTranslation('other-payment-methods');
                otherPayment.className = 'extra-info';
                otherPayment.style.marginTop = '10px';
                (_a = otherPayment.querySelector('a')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', () => requestPaymentMethods(licensesPurchase.value));
                beneficiary.innerHTML += '</br>' + getTranslation('send-nf');
                modalBody.appendChild(otherPayment);
            }
            licensesPurchase.setAttribute('value', amount);
            licensesPurchase.onchange = (event) => { var _a; return valuesChanged((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value, country, notaFiscal); };
            licensesPurchase.oninput = (event) => { var _a; return valuesChanged((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value, country, notaFiscal); };
            const modal = percyModal(getTranslation('payment-modal-title'), [], '', false, false, modalBody, '', '', () => result.remove());
            modal.id = 'payment-modal';
            result.appendChild(modal);
            const licensesInput = result.querySelector('#licenses-purchase');
            licensesInput.focus();
            licensesInput.select();
            createPaypalTransaction(amount, country, notaFiscal);
        }, logError);
        return result;
    }, logError);
    return result;
};
