let cleared = false;
const clearCookieValidation = () => {
    var _a;
    (_a = document.querySelector('#cookie-test-iframe')) === null || _a === void 0 ? void 0 : _a.remove();
    cleared = true;
};
const checkCookiesEnable = (ev, onEnabled, onDisabled) => {
    if (ev.data === 'MM:3PCunsupported') {
        clearCookieValidation();
        if (onDisabled) {
            onDisabled();
        }
    }
    else if (ev.data === 'MM:3PCsupported') {
        clearCookieValidation();
        onEnabled();
    }
};
const cookieTest = () => {
    const frame = document.createElement('iframe');
    frame.id = 'cookie-test-iframe';
    frame.src = 'https://mindmup.github.io/3rdpartycookiecheck/start.html';
    frame.style.display = 'none';
    document.body.appendChild(frame);
};
export const validateThirdPartyCookies = (onEnabled, onDisabled) => {
    cleared = false;
    const listener = (ev) => checkCookiesEnable(ev, onEnabled, onDisabled);
    window.addEventListener('message', listener);
    let cookieValidationTryNumber = 0;
    cookieTest();
    const timer = setInterval(() => {
        cookieValidationTryNumber++;
        if (cleared) {
            window.removeEventListener('message', listener);
            clearInterval(timer);
        }
        if (cookieValidationTryNumber > 20) {
            console.log('Cookie test failed. Assuming cookies are disabled.');
            window.removeEventListener('message', listener);
            clearInterval(timer);
            if (onDisabled) {
                onDisabled();
            }
        }
    }, 50);
};
