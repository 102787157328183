import { getTranslation } from '@klauswuestefeld/jux-web';
import { percyModal } from './percy-modal';
const pictureEditionModal = (url, onPictureURLChange) => {
    const input = document.createElement('input');
    input.className = 'textfield';
    if (url) {
        input.value = url;
    }
    else {
        input.placeholder = getTranslation('photo-url-placeholder');
    }
    const result = percyModal(getTranslation('photo-url'), ['ok', 'cancel'], '', false, false, input);
    result.setAttribute('data-cy', 'picture-edition-modal');
    result.addEventListener('ok', () => {
        if (url !== input.value) {
            onPictureURLChange(input.value);
        }
        result.remove();
    });
    return result;
};
export const openPictureEditionModal = ({ url, hostElement, onPictureURLChange }) => {
    hostElement.appendChild(pictureEditionModal(url, onPictureURLChange));
};
