import { loginButton } from '../components/login-button';
import { magicLinkModal } from '../components/magic-link-modal';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { onMicrosoftSignIn } from '../session';
const onMagicLinkRequest = () => {
    var _a;
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(magicLinkModal());
};
export const loginPage = () => {
    const result = document.createElement('login-page');
    const container = document.createElement('percy-container');
    const salutation = document.createElement('sign-in-salutation');
    salutation.textContent = getTranslation('sign-in-salutation');
    const explanation = document.createElement('sign-in-explanation');
    explanation.textContent = getTranslation('sign-in-explanation');
    const msg = document.createElement('sign-in-msg');
    if (window.location.search.includes('buy-now')) {
        msg.setAttribute('data-cy', 'sign-in-msg-buy-now');
        msg.textContent = getTranslation('sign-in-msg-buy-now');
    }
    else if (window.location.search.includes('trial')) {
        msg.setAttribute('data-cy', 'sign-in-msg-trial');
        msg.textContent = getTranslation('sign-in-msg-trial');
    }
    else {
        msg.setAttribute('data-cy', 'sign-in-msg-general');
        msg.textContent = getTranslation('sign-in-msg-general');
    }
    const microsoft = loginButton('microsoft.svg', onMicrosoftSignIn, 'Microsoft');
    const newGoogle = document.createElement('new-google-button');
    const email = loginButton('email.png', onMagicLinkRequest, 'Email', 15, 'email-icon');
    result.style.backgroundImage = "url('/images/percival-login.jpg')";
    container.append(salutation, explanation, msg, newGoogle, microsoft, email);
    result.appendChild(container);
    return result;
};
