import { Length, success } from '../../utils/flash';
import { logError } from '../../utils/logger';
import { backendGetPromise, backendPostPromise, getTeamId } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { refreshTeamProfiles } from '../session';
import { googleCaptcha } from './google-captcha';
import { percyModal, toggleBusyStatus } from './percy-modal';
const sendPendingInvites = async (token) => {
    const teamId = getTeamId();
    const ret = backendPostPromise('send-invites', JSON.stringify({ 'team-id': teamId, token }))
        .then((_res) => {
        refreshTeamProfiles();
        setTimeout(() => success(getTranslation('invites-sent'), Length.long), 500);
        return true;
    })
        .catch((err) => {
        logError(err);
        return false;
    });
    return ret;
};
export const sendInvitesModal = async () => {
    var _a, _b;
    const result = document.createElement('send-invites-modal');
    const admin = ((_b = (_a = window.store) === null || _a === void 0 ? void 0 : _a.selectedTeam) === null || _b === void 0 ? void 0 : _b.admin) || window.store.currentUser.email;
    const balance = await backendGetPromise(`credits-balance-for?admin=${admin}`)
        .then((res) => res)
        .catch(logError);
    const body = document.createElement('send-invites-wrapper');
    const content = document.createElement('send-invites-content');
    content.textContent = getTranslation('send-invites-content');
    body.appendChild(content);
    const captcha = googleCaptcha();
    if (balance <= 0) {
        body.appendChild(captcha);
    }
    const modal = percyModal(getTranslation('send-invites-title'), ['ok', 'cancel'], '', false, false, body, getTranslation('send-invites'));
    modal.setAttribute('data-cy', 'send-invites-modal');
    result.appendChild(modal);
    modal.addEventListener('ok', async () => {
        toggleBusyStatus();
        const ret = await sendPendingInvites(captcha.getAttribute('token'));
        toggleBusyStatus();
        if (ret) {
            modal.remove();
            result.remove();
        }
    });
    return result;
};
