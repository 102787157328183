import { formatToDate } from '../../utils/date';
import { error, info, Length } from '../../utils/flash';
import { logError } from '../../utils/logger';
import { currencyFormatter } from '../../utils/string';
import { createTableCell, createTableRow } from '../../utils/table';
import { backendGetPromise, refreshOrder } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { orderDetailsModal } from './order-details-modal';
const updateOrder = (id, sandbox) => {
    let isRequestDone = false;
    const overlay = document.createElement('dark-overlay');
    overlay.className = 'fullscreen-overlay';
    const spinner = document.createElement('percy-spinner');
    spinner.className = 'refresh-order-spinner';
    setTimeout(() => {
        var _a;
        if (!isRequestDone) {
            (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.append(overlay, spinner);
        }
    }, 2000);
    refreshOrder(id, sandbox, () => {
        isRequestDone = true;
        info(getTranslation('order-updated-msg'), Length.long);
    }, () => {
        isRequestDone = true;
        error(getTranslation('order-error-msg'), Length.long);
    });
};
const openOrderDetails = (order) => {
    var _a;
    (_a = document.querySelector('account-page')) === null || _a === void 0 ? void 0 : _a.appendChild(orderDetailsModal(order));
};
const bindDataBefore = (el) => {
    const els = el.children;
    els[0].setAttribute('data-before', getTranslation('date') + ':');
    els[1].setAttribute('data-before', getTranslation('description') + ':');
    els[2].setAttribute('data-before', getTranslation('licenses-title') + ':');
    els[3].setAttribute('data-before', getTranslation('price') + ':');
    els[4].setAttribute('data-before', getTranslation('status') + ':');
};
const toggleTableDisplay = (self, show = false) => {
    const table = document.querySelector('#licenses-history-table');
    if (!table)
        return;
    table.style.display = show ? 'flex' : 'none';
    self.remove();
    const container = document.querySelector('licenses-row');
    container === null || container === void 0 ? void 0 : container.appendChild(tableVisibilityElement(!show));
};
const tableVisibilityElement = (show = false) => {
    const result = document.createElement('a');
    const showOrHide = show ? 'show' : 'hide';
    result.textContent = getTranslation(showOrHide);
    result.addEventListener('click', (_ev) => toggleTableDisplay(result, show));
    return result;
};
const licensesRow = (hasElements) => {
    const result = document.createElement('licenses-row');
    const title = document.createElement('history-title');
    title.textContent = getTranslation('licenses-history-title');
    title.className = 'section-title';
    result.appendChild(title);
    if (!hasElements) {
        return result;
    }
    result.appendChild(tableVisibilityElement(true));
    return result;
};
export const licensesHistory = () => {
    const result = document.createElement('licenses-history');
    const spinner = document.createElement('percy-spinner');
    spinner.classList.add('history-table-spinner');
    let isRequestDone = false;
    setTimeout(() => {
        if (!isRequestDone) {
            result.appendChild(spinner);
        }
    }, 2000);
    backendGetPromise('billing-summary')
        .then((history) => {
        isRequestDone = true;
        result.appendChild(licensesRow(history.length));
        if (!history.length) {
            const blank = document.createElement('blank-records-state');
            blank.style.display = 'block';
            blank.textContent = getTranslation('licenses-empty-history');
            result.appendChild(blank);
            spinner === null || spinner === void 0 ? void 0 : spinner.remove();
            return result;
        }
        const table = document.createElement('table');
        table.style.display = 'none';
        table.id = 'licenses-history-table';
        const thead = document.createElement('thead');
        const headerRow = createTableRow([
            { text: 'date', span: 1, cell: 'th' },
            { text: 'description', span: 2, cell: 'th' },
            { text: 'licenses-title', span: 1, cell: 'th' },
            { text: 'price', span: 1, cell: 'th' },
            { text: 'status', span: 1, cell: 'th' },
        ]);
        thead.appendChild(headerRow);
        const tbody = document.createElement('tbody');
        history = history.sort((a, b) => b.timestamp - a.timestamp);
        history.forEach((entry) => {
            const descriptionWithoutEmail = entry.obs ? entry.obs.split('(')[0] : entry.type;
            const historyRow = createTableRow([
                { text: formatToDate(entry['external-update-time'] || entry.timestamp), span: 1, cell: 'td' },
                { text: entry.status ? entry.type : descriptionWithoutEmail, span: 2, cell: 'td' },
                { text: entry.credits, span: 1, cell: 'td' },
                { text: currencyFormatter(entry.currency, entry.value), span: 1, cell: 'td' },
            ], () => openOrderDetails(entry));
            if (entry.sandbox) {
                historyRow.style.backgroundColor = 'gold';
            }
            entry.status = entry.status || 'finished';
            const statusHistory = createTableCell(entry.status, 1, 'td');
            if (entry.status === 'pending') {
                const refreshableOrder = document.createElement('refreshable-order');
                const statusText = document.createElement('order-text');
                statusText.textContent = statusHistory.textContent;
                const refreshButton = document.createElement('img');
                refreshButton.src = 'images/refresh-btn.svg';
                refreshButton.alt = 'Refresh icon';
                refreshButton.className = 'refresh-button';
                refreshButton.onclick = (event) => {
                    event.stopPropagation();
                    updateOrder(entry['external-id'], entry.sandbox === true);
                };
                refreshableOrder.append(statusText, refreshButton);
                statusHistory.innerHTML = '';
                statusHistory.appendChild(refreshableOrder);
            }
            statusHistory.className = entry.status;
            historyRow.appendChild(statusHistory);
            bindDataBefore(historyRow);
            tbody.appendChild(historyRow);
        });
        table.append(thead, tbody);
        result.append(table);
        spinner === null || spinner === void 0 ? void 0 : spinner.remove();
        return result;
    })
        .catch((err) => {
        isRequestDone = true;
        logError(err);
        spinner === null || spinner === void 0 ? void 0 : spinner.remove();
    });
    return result;
};
