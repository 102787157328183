export const accountsSearch = () => {
    const result = document.createElement('accounts-search');
    const searchBar = document.createElement('input');
    searchBar.className = 'textfield';
    searchBar.type = 'text';
    searchBar.placeholder = 'Search for team admins...';
    searchBar.setAttribute('aria-label', 'Search for team admins');
    searchBar.style.width = '100%';
    searchBar.onkeyup = ({ currentTarget }) => result.dispatchEvent(new CustomEvent('action', { detail: { value: currentTarget.value } }));
    result.appendChild(searchBar);
    return result;
};
