import { getTranslation } from '@klauswuestefeld/jux-web';
const adjustSelectWidth = (e) => {
    const displayedText = e.options[e.selectedIndex].innerText;
    const dummy = document.createElement('div');
    dummy.innerText = displayedText;
    dummy.style.position = 'absolute';
    dummy.style.visibility = 'hidden';
    document.body.insertBefore(dummy, document.body.firstChild);
    const measuredWidth = dummy.clientWidth;
    document.body.removeChild(dummy);
    e.style.width = (measuredWidth + 50) + 'px';
    e.style.overflow = 'hidden';
    e.style.whiteSpace = 'nowrap';
    e.style.textOverflow = 'ellipsis';
};
const hasMemberResult = (member) => window.store.resultsData.results.find((r) => r.email === member);
export const appraisalSelect = (member = window.store.currentUser.email, onAppraisalSelectionChange) => {
    const result = document.createElement('select');
    result.className = 'appraisal-name';
    window.store.resultsData.results.forEach((r) => {
        const option = document.createElement('option');
        option.value = r.email;
        option.textContent = r.name;
        result.appendChild(option);
    });
    const defaultOption = document.createElement('option');
    defaultOption.textContent = getTranslation('select-appraiser');
    defaultOption.selected = true;
    defaultOption.disabled = true;
    result.appendChild(defaultOption);
    if (hasMemberResult(member)) {
        result.value = member;
        result.title = window.store.selectedTeam.members.find((m) => m.email === member).name;
    }
    adjustSelectWidth(result);
    result.addEventListener('change', (ev) => {
        const { value } = ev.target;
        onAppraisalSelectionChange(value);
    });
    return result;
};
