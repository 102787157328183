import { getTranslation } from '@klauswuestefeld/jux-web';
const addChangePageEvent = (el, selector, direction, changeOnboardPage) => {
    var _a;
    (_a = el.querySelectorAll(selector)) === null || _a === void 0 ? void 0 : _a.forEach((btn) => {
        if (btn) {
            btn.onclick = () => changeOnboardPage(direction);
        }
    });
};
const changePageBtn = (direction) => {
    const result = document.createElement('button');
    result.className = `onboard-${direction}-btn`;
    result.setAttribute('aria-label', getTranslation(`move-page-${direction}`));
    const img = document.createElement('img');
    img.src = `images/arrow-${direction}.svg`;
    img.alt = '';
    result.appendChild(img);
    return result;
};
export const onboardPage = (changeOnboardPage) => {
    const result = document.createElement('onboard-page');
    const img = document.createElement('onboard-img');
    img.setAttribute('class', 'fade');
    const help = document.createElement('onboard-help');
    const leftBtn = changePageBtn('left');
    const msg = document.createElement('onboard-message');
    msg.setAttribute('class', 'fade');
    const h3 = document.createElement('h3');
    h3.setAttribute('class', 'onboard-title');
    const p = document.createElement('p');
    p.setAttribute('class', 'onboard-text');
    const dot = document.createElement('onboard-dot-container');
    msg.append(h3, p, dot);
    const rightBtn = changePageBtn('right');
    help.append(leftBtn, msg, rightBtn);
    result.append(img, help);
    addChangePageEvent(result, '.onboard-left-btn', -1, changeOnboardPage);
    addChangePageEvent(result, '.onboard-right-btn', 1, changeOnboardPage);
    return result;
};
