import { showModal } from '../appraisals';
import { certaintyLevel } from './certainty-level';
import { profilePicture } from './profile-picture';
const dragStart = (event, appraisal) => {
    var _a, _b;
    window.store.currentAppraisal = appraisal;
    (_a = event === null || event === void 0 ? void 0 : event.dataTransfer) === null || _a === void 0 ? void 0 : _a.setData('card-id', (_b = event === null || event === void 0 ? void 0 : event.target) === null || _b === void 0 ? void 0 : _b.id);
};
export const appraisalCard = (id, appraisal) => {
    var _a;
    const result = document.createElement('appraisal-card');
    result.id = id;
    result.className = 'card';
    const { picture, name, certainty } = appraisal;
    result.setAttribute('certainty', (_a = certainty === null || certainty === void 0 ? void 0 : certainty.toString()) !== null && _a !== void 0 ? _a : '0');
    result.onclick = () => {
        var _a, _b;
        if (((_b = (_a = result.parentElement) === null || _a === void 0 ? void 0 : _a.tagName) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === 'user-list') {
            return;
        }
        showModal(id);
    };
    result.ondragstart = (event) => dragStart(event, appraisal);
    result.draggable = true;
    const card = document.createElement('card-data');
    const memberName = document.createElement('member-name');
    memberName.className = 'card-name';
    memberName.textContent = name;
    const level = certaintyLevel(Number(certainty || 0));
    card.append(memberName, level);
    if (name && name.length < 15) {
        memberName.style.alignSelf = 'center';
    }
    const img = profilePicture(picture);
    result.append(img, card);
    return result;
};
