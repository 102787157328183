import { getTranslation } from '@klauswuestefeld/jux-web';
import { logError } from '../../utils/logger';
import { addMember, deleteTeamMemberAction } from '../api-client';
import { loadAppraisalsPage } from '../appraisals';
import { saveTeamMemberAction } from '../teams';
import { percyModal, toggleBusyStatus } from './percy-modal';
import { openPictureEditionModal } from './picture-edition-modal';
import { userPicture } from './user-picture';
export var ModalMode;
(function (ModalMode) {
    ModalMode[ModalMode["ADD"] = 0] = "ADD";
    ModalMode[ModalMode["EDIT"] = 1] = "EDIT";
    ModalMode[ModalMode["JOIN"] = 2] = "JOIN";
})(ModalMode || (ModalMode = {}));
const selectedTeamCreator = () => { var _a, _b; return (_b = (_a = window.store) === null || _a === void 0 ? void 0 : _a.selectedTeam) === null || _b === void 0 ? void 0 : _b.creator; };
export const isAdminOrSubadmin = (teamMember) => {
    var _a;
    if ((_a = teamMember.role) === null || _a === void 0 ? void 0 : _a.includes('sub-admin'))
        return true;
    if (selectedTeamCreator() === teamMember.email)
        return true;
    else
        return false;
};
export const getRole = (appraiser, appraised, admin) => {
    const role = [];
    if (admin.checked)
        role.push('sub-admin');
    if (appraiser.checked)
        role.push('appraiser');
    if (appraised.checked)
        role.push('appraised');
    return role;
};
const labeledInput = (id, text) => {
    const result = document.createElement('labeled-input');
    const checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.id = id;
    const label = document.createElement('label');
    label.textContent = text;
    label.htmlFor = id;
    result.append(checkbox, label);
    return result;
};
export const editMembersModal = (mode, teamMember) => {
    var _a, _b;
    const result = document.createElement('edit-members-modal');
    const body = document.createElement('modal-body');
    const memberId = document.createElement('input');
    memberId.type = 'hidden';
    memberId.id = 'member-id-modal';
    const nameLabel = document.createElement('label');
    nameLabel.htmlFor = 'member-name-modal';
    nameLabel.textContent = getTranslation('member-name-label');
    const nameInput = document.createElement('input');
    nameInput.type = 'text';
    nameInput.className = 'textfield';
    nameInput.id = 'member-name-modal';
    nameInput.placeholder = getTranslation('name');
    nameInput.maxLength = 60;
    const emailLabel = document.createElement('label');
    emailLabel.htmlFor = 'member-email-modal';
    emailLabel.textContent = 'E-mail:';
    const emailInput = document.createElement('input');
    emailInput.type = 'text';
    emailInput.className = 'textfield';
    emailInput.id = 'member-email-modal';
    emailInput.placeholder = 'E-mail';
    emailInput.maxLength = 60;
    const roleLabel = document.createElement('label');
    roleLabel.htmlFor = 'member-role-modal';
    roleLabel.textContent = getTranslation('role');
    roleLabel.id = 'role-label';
    const appraiserComponent = labeledInput('appraiser-checkbox', getTranslation('appraiser'));
    const appraisedComponent = labeledInput('appraised-checkbox', getTranslation('appraised'));
    const adminComponent = labeledInput('admin-checkbox', 'Admin');
    const appraiserCheckbox = appraiserComponent.querySelector('#appraiser-checkbox');
    const appraisedCheckbox = appraisedComponent.querySelector('#appraised-checkbox');
    const adminCheckbox = adminComponent.querySelector('#admin-checkbox');
    const adminLabel = adminComponent.querySelector('label');
    body.append(memberId, nameLabel, nameInput, emailLabel, emailInput, roleLabel, appraiserComponent, appraisedComponent, adminComponent);
    if (mode === ModalMode.EDIT) {
        const picture = userPicture(teamMember.picture);
        const img = picture.querySelector('img');
        picture.addEventListener('click', () => openPictureEditionModal({
            url: teamMember.picture,
            hostElement: result,
            onPictureURLChange: (newUrl) => img.src = newUrl
        }));
        body.insertBefore(picture, nameLabel);
        const modal = percyModal(getTranslation('edit-members-modal-title'), ['ok', 'cancel'], '', false, false, body, getTranslation('save-member-btn'), getTranslation('delete-member-btn'));
        modal.setAttribute('data-cy', 'edit-members-modal');
        modal.setAttribute('dont-remove', 'true');
        const adminOrSubadmin = isAdminOrSubadmin(teamMember);
        nameInput.value = (_a = teamMember.name) !== null && _a !== void 0 ? _a : teamMember.email.split('@')[0];
        adminCheckbox.checked = adminOrSubadmin;
        if (teamMember.email === selectedTeamCreator()) {
            adminCheckbox.disabled = true;
            adminLabel.classList.add('disabled-label');
        }
        appraiserCheckbox.checked = teamMember.role.includes('appraiser');
        appraisedCheckbox.checked = teamMember.role.includes('appraised');
        emailInput.value = teamMember.email;
        emailInput.disabled = true;
        memberId.value = teamMember.email;
        modal.addEventListener('ok', async () => {
            if (!getRole(appraiserCheckbox, appraisedCheckbox, adminCheckbox).length) {
                logError('role-warning');
                return;
            }
            toggleBusyStatus();
            await saveTeamMemberAction(teamMember.name, teamMember.role, teamMember.picture);
            toggleBusyStatus();
            modal.remove();
        });
        modal.addEventListener('cancel', async () => {
            toggleBusyStatus('.cancel');
            await deleteTeamMemberAction();
            toggleBusyStatus('.cancel');
            modal.remove();
        });
        result.appendChild(modal);
    }
    else {
        const modal = percyModal(getTranslation('add-members-modal-title'), ['ok', 'cancel'], '', false, false, body, getTranslation('save-member-btn2'));
        modal.setAttribute('data-cy', 'edit-members-modal');
        appraiserCheckbox.checked = true;
        appraisedCheckbox.checked = true;
        if (mode === ModalMode.JOIN) {
            nameInput.value = (_b = teamMember.name) !== null && _b !== void 0 ? _b : teamMember.email.split('@')[0];
            adminCheckbox.checked = true;
            adminCheckbox.disabled = true;
            adminLabel.classList.add('disabled-label');
            emailInput.value = teamMember.email;
            emailInput.disabled = true;
            memberId.value = teamMember.email;
        }
        modal.addEventListener('ok', async () => {
            const role = getRole(appraiserCheckbox, appraisedCheckbox, adminCheckbox);
            if (!role.length) {
                logError('role-warning');
                return;
            }
            toggleBusyStatus();
            if (mode === ModalMode.JOIN) {
                const added = await addMember(nameInput.value, emailInput.value, role);
                if (added) {
                    loadAppraisalsPage();
                }
            }
            else {
                await saveTeamMemberAction();
            }
            toggleBusyStatus();
        });
        result.appendChild(modal);
    }
    nameInput.focus();
    return result;
};
