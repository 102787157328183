import { displayPage, Page } from '../../utils/navigation';
import { contactUsModal } from '../components/contact-us-modal';
import { getTranslation } from '@klauswuestefeld/jux-web';
export const magicLinkRequestedPage = () => {
    const result = document.createElement('magic-link-requested-page');
    const container = document.createElement('percy-container');
    const magicLinkSent = document.createElement('p');
    magicLinkSent.id = 'magic-link-sent';
    magicLinkSent.textContent = getTranslation(magicLinkSent.id);
    const magicLinkEmail = document.createElement('p');
    magicLinkEmail.id = 'magic-link-email';
    magicLinkEmail.setAttribute('class', 'strong');
    const magicLinkHowTo = document.createElement('p');
    magicLinkHowTo.id = 'magic-link-how-to';
    magicLinkHowTo.innerHTML = getTranslation(magicLinkHowTo.id);
    const btn = document.createElement('button');
    btn.id = 'email-not-received-btn';
    btn.addEventListener('click', () => { var _a; return (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(contactUsModal('email-not-sent')); });
    btn.textContent = getTranslation('magic-link-let-us-know');
    const returnLink = document.createElement('a');
    returnLink.textContent = getTranslation('return-link');
    returnLink.onclick = () => {
        displayPage(Page.LOGIN);
    };
    container.append(magicLinkSent, magicLinkEmail, magicLinkHowTo, btn, returnLink);
    result.style.backgroundImage = "url('/images/percival-login.jpg')";
    result.appendChild(container);
    return result;
};
