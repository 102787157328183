import { backendPostPromise, getTeamId } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { firstAccessOnboard } from '../onboard';
import { appraisalSelect } from './appraisal-select';
import { blankPage } from './blank-page';
import { certaintySection } from './certainty-section';
import { dataSection } from './data-section';
import { slicingSection } from './slicing-section';
let isResultDone = false;
let canvas;
export const calculateBarWidth = (actualValue, maxValue) => {
    const maxHeight = window.screen.width < 588 ? 90 : 100;
    return `${(actualValue / maxValue) * maxHeight}%`;
};
export const buildGraphBar = (newElement, width, value, onAppraisalSelectionChange) => {
    var _a, _b;
    const result = document.createElement(newElement);
    result.style.width = width;
    const barLabel = document.createElement('bar-value');
    barLabel.innerText = value;
    const leftOffset = displayTextWidth(value);
    const appraisalMadeByEN = 'APPRAISAL MADE BY:';
    const appraisalMadeByBR = 'AVALIAÇÃO FEITA POR:';
    if (value !== appraisalMadeByEN && value !== appraisalMadeByBR) {
        barLabel.style.left = leftOffset + 'px';
        result.appendChild(barLabel);
        return result;
    }
    barLabel.style.left = value === appraisalMadeByEN ? `${leftOffset + 16}px` : `${leftOffset + 1}px`;
    result.appendChild(barLabel);
    result.style.height = '17px';
    const isAdmin = (_a = window.store) === null || _a === void 0 ? void 0 : _a.selectedTeam['is-admin'];
    const isPublishedAnonymously = ((_b = window.store) === null || _b === void 0 ? void 0 : _b.selectedTeam.round) === 'published-anonymously';
    if (isAdmin) {
        barLabel.appendChild(appraisalSelect(window.store.resultsData.appraiser, onAppraisalSelectionChange));
    }
    else {
        const member = window.store.resultsData.results.find((r) => r.email === window.store.resultsData.appraiser);
        barLabel.textContent += member.name;
        if (isPublishedAnonymously)
            result.style.visibility = 'hidden';
    }
    return result;
};
export const buildNameLine = (name, extraClass = null) => {
    const result = document.createElement('appraised-name');
    result.style.display = 'block';
    result.className = 'bar-name';
    if (extraClass) {
        result.classList.add(extraClass);
    }
    result.innerText = name || '';
    return result;
};
export const createProfileColumn = (row, picture = undefined) => {
    const pic = document.createElement('results-pic');
    pic.style.backgroundImage = picture ? `url(${picture})` : 'url("/images/avatar.svg")';
    if (picture !== undefined) {
        pic.style.backgroundSize = 'contain';
    }
    row.appendChild(pic);
    const divider = document.createElement('results-divider');
    row.appendChild(divider);
};
export const buildResultRow = (received, given, onAppraisalSelectionChange) => {
    const result = document.createElement('results-row');
    result.className = 'caption-row';
    const p = document.createElement('p');
    p.className = 'caption-text';
    p.textContent = getTranslation('key');
    p.style.left = p.textContent === 'KEY' ? '-15px' : '-24px';
    // Profile pic
    createProfileColumn(result);
    // Graph Container
    const container = document.createElement('graph-container');
    container.appendChild(p);
    // Name
    container.appendChild(buildNameLine(getTranslation('name'), 'header'));
    // Certainty Received Bar
    const receivedBar = buildGraphBar(received.element, '25%', received.value);
    const receivedBarValue = receivedBar.querySelector('bar-value');
    receivedBarValue.className = 'caption-bar-value';
    container.appendChild(receivedBar);
    // Certainty Given Bar
    const givenBar = buildGraphBar(given.element, '25%', given.value, onAppraisalSelectionChange);
    const givenBarValue = givenBar.querySelector('bar-value');
    givenBarValue.className = 'caption-bar-value';
    container.appendChild(givenBar);
    result.appendChild(container);
    return result;
};
export const displayTextWidth = (text) => {
    const myCanvas = canvas !== null && canvas !== void 0 ? canvas : (canvas = document.createElement('canvas'));
    const context = myCanvas.getContext('2d');
    context.font = 'bold ' + context.font.replace(/\d+px/, '15px');
    const metrics = context.measureText(' ' + text);
    return metrics.width;
};
export const showBlankMessage = (element, message, extraClass = '') => {
    var _a;
    const result = element;
    (_a = document.querySelector('blank-results-msg')) === null || _a === void 0 ? void 0 : _a.remove();
    const blankResultsMsg = document.createElement('blank-results-msg');
    blankResultsMsg.setAttribute('class', `msg-container ${extraClass}`);
    blankResultsMsg.textContent = getTranslation(message);
    result.appendChild(blankResultsMsg);
    return result;
};
const resultSection = (menu, result) => {
    switch (menu) {
        case 'certainty-menu':
            return certaintySection(result);
        case 'ranking-menu':
            return slicingSection(result);
        case 'data-menu':
            return dataSection(result);
        default:
            return result;
    }
};
const fetchMemberResult = (menu, result, spinner) => {
    backendPostPromise('results-ranking-list-comparison2', JSON.stringify({ 'team-id': getTeamId(), 'member-to-compare': window.store.currentUser.email }))
        .then((res) => {
        isResultDone = true;
        window.store.resultsData = res;
        window.store.resultsData.appraiser = window.store.currentUser.email;
        window.store.resultsData.results = res[window.store.currentUser.email].results;
        if (!window.store.resultsData.results.length) {
            return blankPage('blank-results-msg');
        }
        firstAccessOnboard();
        return resultSection(menu, result);
    })
        .catch((err) => showBlankMessage(result, getTranslation(err), 'blank-results-msg-no-padding'))
        .finally(() => spinner === null || spinner === void 0 ? void 0 : spinner.remove());
};
const fetchAllTeamMembersResults = (menu, result, spinner) => {
    var _a, _b;
    const currentUserResults = ((_a = window.store) === null || _a === void 0 ? void 0 : _a.resultsData) && ((_b = window.store.resultsData[window.store.currentUser.email]) === null || _b === void 0 ? void 0 : _b.results);
    if (!currentUserResults) {
        backendPostPromise('results-ranking-list-comparison2', JSON.stringify({ 'team-id': getTeamId(), 'member-to-compare': window.store.currentUser.email }))
            .then((res) => {
            window.store.resultsData = res;
            fetchAllTeamMembersResults(menu, result, spinner);
        });
    }
    else {
        backendPostPromise('all-team-members-results', JSON.stringify({ 'team-id': getTeamId() }))
            .then((res) => {
            isResultDone = true;
            window.store.resultsData = res;
            window.store.resultsData.appraiser = window.store.currentUser.email;
            window.store.resultsData.results = currentUserResults;
            firstAccessOnboard();
            return resultSection(menu, result);
        })
            .catch((err) => showBlankMessage(result, getTranslation(err), 'blank-results-msg-no-padding'))
            .finally(() => spinner === null || spinner === void 0 ? void 0 : spinner.remove());
    }
};
export const resultsList = (menu = 'certainty-menu') => {
    const result = document.createElement('results-list');
    const spinner = document.createElement('percy-spinner');
    spinner.classList.add('large');
    isResultDone = false;
    if (window.store.resultsData) {
        return resultSection(menu, result);
    }
    else {
        if (window.store.selectedTeam['is-admin']) {
            fetchAllTeamMembersResults(menu, result, spinner);
        }
        else {
            fetchMemberResult(menu, result, spinner);
        }
    }
    setTimeout(() => {
        if (!isResultDone) {
            result.appendChild(spinner);
        }
    }, 2000);
    return result;
};
