import { THRESHOLD_WIDTH } from '../../utils/constants';
import { Visibility } from '../../utils/enums';
import { reportError } from '../../utils/logger';
import { appraise } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { appraisalCard } from './appraisal-card';
export class MembersPool extends HTMLElement {
    constructor(appraisals, visibility = window.innerWidth > THRESHOLD_WIDTH ? Visibility.VISIBLE : Visibility.HIDDEN) {
        super();
        this._searchBarText = '';
        this._drop = async (event) => {
            var _a;
            const appraisal = window.store.currentAppraisal;
            if (!appraisal) {
                reportError('Appraisals > drop > window.store.currentAppraisal não encontrado');
                return;
            }
            const targetLevel = this._findLevel(event.target);
            if (!targetLevel) {
                return;
            }
            if (targetLevel.nodeName === 'USER-LIST' && appraisal.certainty === 0) {
                return;
            }
            event.preventDefault();
            const cardId = (_a = event === null || event === void 0 ? void 0 : event.dataTransfer) === null || _a === void 0 ? void 0 : _a.getData('card-id');
            const appraiseLevel = 0;
            const appraiseCertainty = 0;
            const card = document.getElementById(cardId);
            targetLevel.classList.remove('drag-target');
            await appraise({
                certainty: appraiseCertainty,
                appraised: appraisal.email,
                level: appraiseLevel
            });
            appraisal.level = appraiseLevel;
            appraisal.certainty = appraiseCertainty;
            // TODO: Simplificar essa gambiarra toda
            targetLevel.appendChild(card);
            if (!this.appraisals.includes(appraisal)) {
                this.appraisals.push(appraisal);
                const appraisals = this.appraisals;
                this.appraisals = appraisals;
            }
        };
        this._dragOver = (event) => {
            const targetLevel = this._findLevel(event.target);
            if (targetLevel) {
                event.preventDefault();
            }
            if (window.store.previousLevel === targetLevel) {
                return;
            }
            this._levelChanged(targetLevel);
            window.store.previousLevel = targetLevel;
        };
        this._findLevel = (element) => {
            if (!element) {
                return null;
            }
            return (element.tagName === 'USER-LIST')
                ? element
                : this._findLevel(element.parentElement);
        };
        this._levelChanged = (currentLevel) => {
            if (window.store.previousLevel) {
                window.store.previousLevel.classList.remove('drag-target');
            }
            if (currentLevel) {
                currentLevel.classList.add('drag-target');
                // this.querySelector('user-list')?.classList.add('drag-target');
            }
        };
        this._renderCards = () => {
            const el = document.querySelector('user-list');
            if (!el) {
                reportError('MembersPool > _renderCards > user-list não encontrado');
                return;
            }
            el.innerHTML = '';
            this.appraisals.forEach((appraisal) => {
                const newCard = appraisalCard('card-' + appraisal.email, appraisal);
                el.appendChild(newCard);
            });
            this._originalList = Array.from(el.children);
        };
        this.visibility = visibility;
        this._appraisals = appraisals;
    }
    static get observedAttributes() {
        return ['visibility', 'appraisals'];
    }
    get visibility() {
        return this.getAttribute('visibility') === '0' ? Visibility.VISIBLE : Visibility.HIDDEN;
    }
    set visibility(visibility) {
        this.setAttribute('visibility', visibility.toString());
    }
    get appraisals() {
        return this._appraisals.sort((a, b) => a.name.localeCompare(b.name));
    }
    set appraisals(appraisals) {
        this._appraisals = appraisals;
        this.setAttribute('appraisals', this.appraisals.toString());
    }
    connectedCallback() {
        this._render();
    }
    attributeChangedCallback(name, _oldValue, _newValue) {
        if (name === 'visibility') {
            this.style.display = this.visibility === Visibility.VISIBLE ? 'flex' : 'none';
            return;
        }
        this._renderCards();
        this.handleValueChange(this._searchBarText);
    }
    handleValueChange(value) {
        var _a;
        this._searchBarText = value;
        const list = this.querySelector('user-list');
        if (!list) {
            reportError('MembersPool > handleValueChange > user-list não encontrado');
            return;
        }
        const els = (_a = this._originalList) === null || _a === void 0 ? void 0 : _a.filter((el) => {
            const textToMatch = this._searchBarText.toLowerCase();
            const memberName = el.textContent.toLowerCase();
            const memberEmail = el.id.split('@')[0].replace('card-', '');
            return memberName.includes(textToMatch) || memberEmail.includes(textToMatch);
        });
        list.innerHTML = '';
        els === null || els === void 0 ? void 0 : els.forEach((el) => list.append(el));
    }
    _render() {
        const searchBar = document.createElement('input');
        searchBar.className = 'textfield';
        searchBar.type = 'text';
        searchBar.placeholder = getTranslation('search-for-users') + '...';
        searchBar.setAttribute('aria-label', getTranslation('search-for-users'));
        searchBar.value = this._searchBarText;
        searchBar.onkeyup = ({ currentTarget }) => this.handleValueChange(currentTarget.value);
        const list = document.createElement('user-list');
        list.ondrop = (event) => this._drop(event);
        list.ondragover = (event) => this._dragOver(event);
        this.append(searchBar, list);
        this._renderCards();
    }
}
customElements.define('members-pool', MembersPool);
