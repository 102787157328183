import { hamburgerLinks } from './hamburger-links';
export const hamburgerMenu = (isSuperAdmin = false) => {
    const result = document.createElement('hamburger-menu');
    // A fake / hidden checkbox is used as click receiver, so you can use the :checked selector on it
    const cb = document.createElement('input');
    cb.setAttribute('type', 'checkbox');
    // Hamburger Menu
    const line1 = document.createElement('hamburger-line');
    const line2 = document.createElement('hamburger-line');
    const line3 = document.createElement('hamburger-line');
    const links = hamburgerLinks(isSuperAdmin);
    result.append(cb, line1, line2, line3, links);
    return result;
};
