import { percyFlash } from '../percival/components/percy-flash';
export var Length;
(function (Length) {
    Length[Length["short"] = 3000] = "short";
    Length[Length["long"] = 8000] = "long";
    Length[Length["persistent"] = 9999999] = "persistent";
})(Length || (Length = {}));
export const flash = (type, msg, length = Length.short) => {
    var _a, _b;
    // TODO permitir exibição de múltiplos flashes
    (_a = document.querySelector('percy-flash')) === null || _a === void 0 ? void 0 : _a.remove();
    const f = percyFlash(type, msg);
    (_b = document.querySelector('percy-body')) === null || _b === void 0 ? void 0 : _b.appendChild(f);
    setTimeout(() => {
        f.remove();
    }, length);
};
export const success = (msg = 'success-msg', length) => {
    flash('success', msg, length);
};
export const error = (msg = 'error-msg', length) => {
    flash('error', msg, length);
};
export const warning = (msg = 'warning-msg', length = Length.long) => {
    flash('warning', msg, length);
};
export const info = (msg = 'info-msg', length) => {
    flash('info', msg, length);
};
