import { authSignIn, cookieError, googleAuthSignOut, initGapi } from '../jux/auth';
import { logError, reportError } from '../utils/logger';
import { changePage, displayPage, Page } from '../utils/navigation';
import { backendGet, backendGetPromise, createTeam, getSelectedTeam, getTeams, googleAuthUrl, microsoftAuthUrl, openMagicLink, requestMagicLink, setSelectedTeam } from './api-client';
import { loadAppraisalsPage } from './appraisals';
import { percyHeader } from './components/percy-header';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { PercyStore } from './PercyStore';
import { loadTeamsPage } from './teams';
import * as msal from '@azure/msal-browser';
import { validateThirdPartyCookies } from '../utils/cookies';
import { initDemo, isRunningDemo, startNewDemo } from './demo';
export const refreshCurrentUserHeader = () => {
    var _a;
    const app = document.querySelector('percy-app');
    const body = document.querySelector('percy-body');
    (_a = document.querySelector('percy-header')) === null || _a === void 0 ? void 0 : _a.remove();
    app === null || app === void 0 ? void 0 : app.insertBefore(percyHeader(), body);
};
const canAppraise = () => {
    const team = window.store.selectedTeam;
    if (!team) {
        return false;
    }
    const user = team === null || team === void 0 ? void 0 : team.members.find((member) => member.email === window.store.currentUser.email);
    return user && user.role.includes('appraiser');
};
export const refreshTeamProfiles = async () => {
    const teams = await getTeams();
    window.store.currentUserIsAdmin = teams ? teams.some((team) => team.admin === window.store.currentUser.email) : false;
    window.store.isSuperAdmin = await backendGetPromise('is-superadmin').then(res => res === true);
    let destination;
    if (window.location.href.includes('destination')) {
        destination = extractTokenFromWindowLocation('destination');
    }
    else if (window.location.href.includes('state')) {
        destination = extractTokenFromWindowLocation('state');
    }
    if (destination === 'buy-now') {
        if (!window.store.currentUserIsAdmin) {
            await createTeam(getTranslation('first-team-name'));
        }
        changePage('account-tab');
        return;
    }
    if (!(teams === null || teams === void 0 ? void 0 : teams.length)) {
        loadTeamsPage();
        return;
    }
    let selectedTeam = await getSelectedTeam();
    if (!selectedTeam && !teams.length) {
        loadTeamsPage();
        return;
    }
    if (!selectedTeam && (teams === null || teams === void 0 ? void 0 : teams.length) === 1) {
        setSelectedTeam(teams[0].id);
        selectedTeam = await getSelectedTeam();
    }
    if ((teams === null || teams === void 0 ? void 0 : teams.length) === 1 && !selectedTeam['is-admin']) {
        if (selectedTeam.round === 'closed' || selectedTeam.round === 'published' || selectedTeam.round === 'published-anonymously') {
            displayPage(Page.RESULTS);
        }
        else if (!canAppraise()) {
            loadTeamsPage();
        }
        else {
            loadAppraisalsPage();
        }
        return;
    }
    // Envia usuário para aba Times
    loadTeamsPage();
};
export const extractTokenFromWindowLocation = (tokenParam, additionalParam = '') => {
    if (!tokenParam) {
        return;
    }
    let token = '';
    const tokenParameterName = `${tokenParam}=`;
    const tokenParameterIndex = window.location.search.indexOf(tokenParameterName);
    if (tokenParameterIndex !== -1) {
        const tokenParameterNameLength = tokenParameterName.length;
        const nextParameterIndex = window.location.search.indexOf('&', tokenParameterIndex);
        token = (nextParameterIndex === -1) ?
            window.location.search.substring(tokenParameterIndex + tokenParameterNameLength) :
            window.location.search.substring(tokenParameterIndex + tokenParameterNameLength, nextParameterIndex);
        let searchWithoutToken = window.location.search.replace(`${tokenParam}=` + token, '').replace(additionalParam, '').replace('\&cypress=true', '');
        if (searchWithoutToken === '?') {
            searchWithoutToken = '';
        }
        window.history.replaceState({}, document.title, window.location.pathname + searchWithoutToken);
    }
    return token;
};
const setBackendToken = (token) => {
    window.store.backendToken = token;
    if (token) {
        localStorage.setItem('token', token);
    }
};
const setSuperToken = () => {
    const superToken = extractTokenFromWindowLocation('super-token');
    if (superToken) {
        localStorage.setItem('super-token', superToken);
    }
};
const getBackendToken = () => {
    var _a;
    const token = (_a = localStorage.getItem('token')) !== null && _a !== void 0 ? _a : '';
    window.store.backendToken = token;
    return token;
};
const onTokenAcquired = (token) => {
    setBackendToken(token);
    setSuperToken();
    refreshTeamProfiles();
};
export const onAuthentication = (user, type) => {
    if ((user.token && getBackendToken()) && getBackendToken() !== user.token) {
        clearSession();
    }
    window.store.currentUser = user;
    if (type === 'Percival Token Authentication') {
        user.token = localStorage.getItem('token');
    }
    onTokenAcquired(user.token);
};
const onAuthenticationFailure = (msg) => {
    localStorage.removeItem('token');
    displayPage(Page.LOGIN);
    alert(getTranslation(msg));
};
export const onUserChanged = async (googleUser) => {
    var _a;
    document.documentElement.classList.remove('progress');
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.classList.remove('disabled');
    if (googleUser) {
        try {
            await backendGetPromise(googleAuthUrl + googleUser.googletoken)
                .then(res => {
                const { token } = res;
                window.store.currentUser = res;
                onTokenAcquired(token);
            })
                .catch((error) => { throw Error(error); });
        }
        catch (error) {
            logError(error);
            return;
        }
    }
    else {
        setBackendToken('');
        if (isRunningDemo()) {
            initDemo(setBackendToken, onAuthentication);
        }
        else {
            displayPage(Page.LOGIN);
        }
    }
};
export const initSession = () => {
    if (startNewDemo()) {
        initDemo(setBackendToken, onAuthentication);
        return;
    }
    const magicToken = extractTokenFromWindowLocation('magic-link');
    if (magicToken) {
        openMagicLink(magicToken, (res) => onAuthentication(res, 'Magic Link'), () => onAuthenticationFailure('unable-magic-login'));
        return;
    }
    if (getBackendToken()) {
        backendGet('profile', (res) => onAuthentication(res, 'Percival Token Authentication'), (_err) => onAuthenticationFailure('login-failed'));
        return;
    }
    ;
    validateThirdPartyCookies(initGapi, () => displayPage(Page.LOGIN));
};
export const disableSignInLayout = () => {
    var _a;
    document.documentElement.classList.remove('progress');
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.classList.remove('disabled');
};
const enableSignInLayout = () => {
    var _a;
    document.documentElement.classList.add('progress');
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.classList.add('disabled');
};
export const onGoogleSignIn = () => {
    validateThirdPartyCookies(() => {
        enableSignInLayout();
        authSignIn();
    }, cookieError);
};
export const onMicrosoftSignIn = async () => {
    const msalConfig = { auth: { clientId: process.env.MICROSOFT_CLIENT_ID } };
    // @ts-ignore
    const msalInstance = new msal.PublicClientApplication(msalConfig);
    const loginRequest = { scopes: ['user.read'] };
    enableSignInLayout();
    try {
        const loginResponse = await msalInstance.loginPopup(loginRequest);
        const { accessToken } = loginResponse;
        await backendGetPromise(microsoftAuthUrl + accessToken)
            .then(res => {
            const { token } = res;
            window.store.currentUser = res;
            onTokenAcquired(token);
        })
            .catch(logError)
            .finally(() => disableSignInLayout());
    }
    catch (err) {
        reportError(err);
        disableSignInLayout();
    }
};
export const clearSession = () => {
    onUserChanged(null);
    localStorage.clear();
    window.store = new PercyStore();
};
export const goBackToSuperAdmin = () => {
    const superToken = localStorage.getItem('super-token');
    clearSession();
    window.open(`/?magic-link=${superToken}`, '_self');
};
export const signOut = () => {
    clearSession();
    validateThirdPartyCookies(() => initGapi(async () => googleAuthSignOut()));
};
export const handleSignOut = () => {
    const superToken = localStorage.getItem('super-token');
    if (superToken) {
        goBackToSuperAdmin();
    }
    else {
        signOut();
    }
};
export const handleMagicLinkRequest = (token, email = '') => {
    if (!email) {
        const mailMagic = document.querySelector('#mail-magic');
        email = mailMagic.value;
    }
    const sandbox = window.location.hostname === 'localhost';
    const payload = { email, token, sandbox };
    requestMagicLink(payload, _res => {
        displayPage(Page.MAGIC_LOGIN);
        const magicLinkEmail = document.querySelector('#magic-link-email');
        if (!magicLinkEmail) {
            logError('#magic-link-email não foi encontrado', 'generic-help-msg');
            return;
        }
        magicLinkEmail.textContent = email;
    });
};
window.onclick = (event) => {
    var _a;
    const session = document.querySelector('user-avatar');
    const userPicture = document.querySelector('#user-picture');
    if (!session) {
        return;
    }
    if (session.contains(event.target) || event.target === userPicture) {
        return;
    }
    (_a = document.querySelector('session-dropdown')) === null || _a === void 0 ? void 0 : _a.classList.add('force-display-none');
};
export const isFirstTimer = () => {
    var _a;
    const team = window.store.selectedTeam;
    return !team || (!team['is-admin'] && !((_a = team === null || team === void 0 ? void 0 : team.members) === null || _a === void 0 ? void 0 : _a.length));
};
