import { getTranslation } from '@klauswuestefeld/jux-web';
import { showNewTeamModal } from './new-team-modal';
import { percyModal } from './percy-modal';
export const showLeavingFreeTrialAlert = () => {
    var _a;
    const modal = leavingFreeTrialModal();
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(modal);
};
export const leavingFreeTrialModal = () => {
    const result = document.createElement('leaving-free-trial-modal');
    const body = document.createElement('modal-body');
    const txt = document.createElement('b');
    txt.textContent = getTranslation('leaving-free-trial');
    body.appendChild(txt);
    const modal = percyModal('', ['ok', 'cancel'], '', false, false, body, getTranslation('create-team'));
    modal.setAttribute('data-cy', 'leaving-free-trial-modal');
    modal.addEventListener('ok', () => {
        modal.remove();
        showNewTeamModal();
    });
    result.appendChild(modal);
    return result;
};
