import { formatToDate } from '../../utils/date';
import { logError } from '../../utils/logger';
import { displayPage, Page } from '../../utils/navigation';
import { singularOrPluralString } from '../../utils/string';
import { createTableRow } from '../../utils/table';
import { backendPost, getSelectedTeam } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { percyModal } from './percy-modal';
const accountModal = (body) => {
    const result = percyModal('Account', [], '', false, false, body, '', '', () => { var _a; return (_a = document.querySelector('give-licenses-modal')) === null || _a === void 0 ? void 0 : _a.remove(); });
    return result;
};
const sendLicenses = (admin, licenses, obs) => {
    backendPost('give-credits', JSON.stringify({ admin, credits: licenses, obs }), async (_res) => {
        const team = await getSelectedTeam();
        displayPage(Page.SUPER_ADMIN, team);
    }, logError);
};
export const giveLicensesModal = (account) => {
    const result = document.createElement('give-licenses-modal');
    if (!account) {
        return result;
    }
    const { admin, licenses, history } = account;
    const orderedHistory = history.sort((a, b) => b.timestamp - a.timestamp);
    result.className = 'modal';
    const body = document.createElement('modal-body');
    // START FORM
    const form = document.createElement('form');
    form.onsubmit = (event) => {
        event.preventDefault();
        const obs = obsInput.value;
        const licensesGiven = licensesInput.value;
        sendLicenses(admin, parseInt(licensesGiven, 10), obs);
        result.remove();
    };
    const emailRow = document.createElement('account-email');
    emailRow.textContent = admin;
    const licensesRow = document.createElement('account-licenses');
    const licensesLabel = document.createElement('licenses-amount');
    licensesLabel.textContent = getTranslation('paid-licenses-text');
    const licensesAmount = document.createElement('licenses-amount');
    licensesAmount.textContent = singularOrPluralString(licenses, 'licenses');
    licensesRow.append(licensesLabel, licensesAmount);
    const manageLicensesRow = document.createElement('manage-licenses');
    const licensesInput = document.createElement('input');
    licensesInput.id = 'licenses-input';
    licensesInput.name = 'licenses';
    licensesInput.type = 'number';
    licensesInput.className = 'textfield';
    licensesInput.required = true;
    const licensesInputLabel = document.createElement('label');
    licensesInputLabel.innerText = 'Add/Remove Licenses:';
    licensesInputLabel.htmlFor = 'licenses-input';
    manageLicensesRow.append(licensesInputLabel, licensesInput);
    const obsRow = document.createElement('observation-text');
    const obsInput = document.createElement('textarea');
    obsInput.id = 'observation-input';
    obsInput.name = 'obs';
    obsInput.className = 'textfield';
    obsInput.required = true;
    const obsLabel = document.createElement('label');
    obsLabel.textContent = 'Obs.:';
    obsLabel.htmlFor = 'observation-input';
    obsRow.append(obsLabel, obsInput);
    const submitLicenses = document.createElement('input');
    submitLicenses.className = 'primary-button';
    submitLicenses.type = 'submit';
    submitLicenses.value = 'Submit';
    form.append(emailRow, licensesRow, manageLicensesRow, obsRow, submitLicenses);
    // END FORM
    // START HISTORY TABLE
    const historyTitle = document.createElement('history-title');
    historyTitle.textContent = 'History';
    if (!history.length) {
        const blank = document.createElement('blank-records-state');
        blank.textContent = 'No records were found for this account.';
        body.append(form, historyTitle, blank);
        result.appendChild(accountModal(body));
        return result;
    }
    const table = document.createElement('table');
    const thead = document.createElement('thead');
    const headerRow = createTableRow([
        { text: 'Date', span: 1, cell: 'th' },
        { text: 'Obs', span: 2, cell: 'th' },
        { text: 'Licenses', span: 1, cell: 'th' }
    ]);
    thead.appendChild(headerRow);
    const tbody = document.createElement('tbody');
    orderedHistory === null || orderedHistory === void 0 ? void 0 : orderedHistory.forEach((order) => {
        const historyRow = createTableRow([
            { text: formatToDate(order.timestamp), span: 1, cell: 'td' },
            { text: order.obs || getTranslation(order.type), span: 2, cell: 'td' },
            { text: order.credits, span: 1, cell: 'td' }
        ]);
        tbody.appendChild(historyRow);
    });
    table.append(thead, tbody);
    // END HISTORY TABLE
    body.append(form, historyTitle, table);
    result.appendChild(accountModal(body));
    return result;
};
