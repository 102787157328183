import { error, Length, success, warning } from '../../utils/flash';
import { logError } from '../../utils/logger';
import { loadAccountPage } from '../account';
import { backendPostPromise, refreshOrder } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
const isSandbox = process.env.PAYPAL_SANDBOX === 'true';
const clientId = process.env.PAYPAL_CLIENT_ID;
export class PayPalButton extends HTMLElement {
    constructor() {
        super(...arguments);
        this._closePaymentModal = () => {
            var _a;
            const spinner = document.querySelector('#paypal-spinner');
            if (spinner) {
                spinner.style.display = 'none';
            }
            (_a = document.querySelector('#payment-modal')) === null || _a === void 0 ? void 0 : _a.remove();
        };
    }
    static get observedAttributes() { return ['currency']; }
    set country(country) {
        this.setAttribute('country', country);
    }
    get country() {
        var _a;
        return (_a = this.getAttribute('country')) !== null && _a !== void 0 ? _a : 'US';
    }
    set notaFiscal(notaFiscal) {
        this.setAttribute('nota-fiscal', notaFiscal.toString());
    }
    get notaFiscal() {
        return this.getAttribute('nota-fiscal') === 'true';
    }
    set currency(currency) {
        this.setAttribute('currency', currency);
    }
    get currency() {
        var _a;
        return (_a = this.getAttribute('currency')) !== null && _a !== void 0 ? _a : 'USD';
    }
    set amount(amount) {
        this.setAttribute('amount', amount.toString());
    }
    get amount() {
        return Number(this.getAttribute('amount'));
    }
    get source() {
        return `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${this.currency}&disable-funding=credit,card`;
    }
    get description() {
        var _a;
        return (_a = this.getAttribute('description')) !== null && _a !== void 0 ? _a : '';
    }
    set description(description) {
        this.setAttribute('description', description);
    }
    connectedCallback() {
        this._render();
    }
    attributeChangedCallback() {
        const script = this.querySelector('script');
        script === null || script === void 0 ? void 0 : script.setAttribute('src', this.source);
    }
    _render() {
        const buttonSpinner = document.querySelector('#paypal-button-spinner');
        if (buttonSpinner) {
            buttonSpinner.style.display = 'block';
        }
        const container = document.createElement('paypal-container');
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.addEventListener('load', (_) => {
            var _a;
            if (typeof paypal !== 'undefined') {
                try {
                    (_a = paypal === null || paypal === void 0 ? void 0 : paypal.Buttons()) === null || _a === void 0 ? void 0 : _a.close();
                    // tslint:disable-next-line: no-empty
                }
                catch (err) {
                }
            }
            paypal.Buttons({
                style: {
                    shape: 'pill',
                    color: 'blue',
                    layout: 'vertical',
                    label: 'pay',
                },
                onInit: (_data, actions) => {
                    const termsInput = document.querySelector('.terms-row input');
                    actions.disable();
                    if (termsInput.checked) {
                        actions.enable();
                    }
                    termsInput.addEventListener('change', (_event) => {
                        if (termsInput.checked) {
                            actions.enable();
                        }
                        else {
                            actions.disable();
                        }
                    });
                },
                onClick: () => {
                    const termsInput = document.querySelector('.terms-row input');
                    if (!termsInput.checked) {
                        warning(getTranslation('before-buying-msg'), Length.long);
                    }
                },
                createOrder: (_data, _actions) => {
                    const spinner = document.querySelector('#paypal-spinner');
                    if (spinner) {
                        spinner.style.display = 'block';
                    }
                    return backendPostPromise('billing/create-order', JSON.stringify({
                        credits: this.amount,
                        country: this.country,
                        description: this.description,
                        sandbox: isSandbox,
                        'nota-fiscal': this.notaFiscal,
                    }))
                        .then(res => res);
                },
                onApprove: (data, actions) => {
                    return actions.order.capture().then((_details) => {
                        this._closePaymentModal();
                        refreshOrder(data.orderID, isSandbox, () => success(getTranslation('transaction-completed'), Length.long), () => error(getTranslation('paypal-error-msg'), Length.long));
                    });
                },
                onCancel: (_data) => {
                    loadAccountPage();
                    setTimeout(() => warning(getTranslation('order-canceled'), Length.long), 300);
                    const spinner = document.querySelector('#paypal-spinner');
                    if (spinner) {
                        spinner.style.display = 'none';
                    }
                },
                onError: (err) => {
                    logError(err);
                }
            }).render(container).then(() => {
                const spinner = document.querySelector('#paypal-button-spinner');
                if (spinner) {
                    spinner.style.display = 'none';
                }
            });
        });
        script.src = this.source;
        this.innerHTML = '';
        container.appendChild(script);
        this.append(container);
    }
}
customElements.define('paypal-button', PayPalButton);
