export class PercyStore {
    constructor() {
        this.backendToken = '';
        this.isSuperAdmin = false;
        this.currentUserIsAdmin = false;
        this.allAppraisals = [];
        this.currentUser = null;
        this.selectedTeam = null;
        this.teams = [];
        this.previousLevel = null;
    }
}
