import { getTranslation } from '@klauswuestefeld/jux-web';
export const singularOrPluralString = (amount, suffix) => {
    if (amount > 1) {
        return `${amount} ${getTranslation(`${suffix}-plural`)}`;
    }
    return `${amount} ${getTranslation(`${suffix}-singular`)}`;
};
export const currencyFormatter = (currency, amount) => {
    if (!currency || !amount) {
        return '';
    }
    const lang = currency === 'BRL' ? 'pt-BR' : 'en-US';
    return new Intl.NumberFormat(lang, { style: 'currency', currency }).format(amount);
};
export const cnpjFormatter = (v) => {
    v = v.replace(/\D/g, ""); // Remove tudo o que não é dígito
    v = v.replace(/^(\d{2})(\d)/, "$1.$2"); // Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); // Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); // Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/(\d{4})(\d)/, "$1-$2"); // Coloca um hífen depois do bloco de quatro dígitos
    return v;
};
export const cpfFormatter = (v) => {
    v = v.replace(/\D/g, ""); // Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, "$1.$2"); // Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, "$1.$2"); // Coloca um ponto entre o terceiro e o quarto dígitos
    // de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); // Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
};
export const isCnpjValid = (v) => {
    if (!v) {
        return false;
    }
    const cnpj = v.replace(/[^\d]+/g, '');
    if (cnpj === '') {
        return false;
    }
    if (cnpj.length !== 14) {
        return false;
    }
    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999") {
        return false;
    }
    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== Number(digitos.charAt(0))) {
        return false;
    }
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== Number(digitos.charAt(1))) {
        return false;
    }
    return true;
};
export const isCPFValid = (v) => {
    if (!v) {
        return false;
    }
    const cpf = v.replace(/[\s.-]*/igm, '');
    if (!cpf ||
        cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999") {
        return false;
    }
    let soma = 0;
    let resto;
    for (let i = 1; i <= 9; i++) {
        soma += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) {
        resto = 0;
    }
    if (resto !== parseInt(cpf.substring(9, 10), 10)) {
        return false;
    }
    soma = 0;
    for (let i = 1; i <= 10; i++) {
        soma = soma + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) {
        resto = 0;
    }
    if (resto !== parseInt(cpf.substring(10, 11), 10)) {
        return false;
    }
    return true;
};
export const formatDisplayValue = (value, append = '') => {
    if (!value) {
        return '';
    }
    else if (value > 10) {
        value = Math.round(value * 10) / 10;
    }
    else {
        value = Math.round(value * 100) / 100;
    }
    return value.toLocaleString() + append;
};
export const tsvToObj = (tsv) => {
    var _a;
    const lines = tsv.trim().split('\n');
    const headers = (_a = lines.shift()) === null || _a === void 0 ? void 0 : _a.split('\t');
    return lines.map(line => {
        const data = line.trim().split('\t');
        return headers === null || headers === void 0 ? void 0 : headers.reduce((obj, nextKey, index) => {
            nextKey = nextKey.replace('\r', '');
            nextKey = nextKey.replace('\n', '');
            obj[nextKey] = data[index];
            return obj;
        }, {});
    });
};
export const validateCnpjInput = (data) => {
    const cleanedData = data.replace(/\D/g, '');
    if (cleanedData.length > 11) {
        return isCnpjValid(data);
    }
    else {
        return isCPFValid(data);
    }
};
export const isValidEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};
String.prototype.replaceAll = function (strReplace, strWith) {
    const esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const reg = new RegExp(esc, 'ig');
    return this.replace(reg, strWith);
};
