import { disableSignInLayout, onUserChanged } from '../percival/session';
import { warning } from '../utils/flash';
import { logError } from '../utils/logger';
let authStatus;
let authError;
const highLevelUser = (gUser) => {
    const profile = gUser.getBasicProfile();
    return {
        name: profile.getName(),
        email: profile.getEmail(),
        picture: profile.getImageUrl(),
        googletoken: gUser.getAuthResponse().id_token
    };
};
const onGoogleUserChanged = (gUser) => {
    onUserChanged(gUser.isSignedIn()
        ? highLevelUser(gUser)
        : null);
};
const getAuth = () => {
    if (typeof gapi === 'undefined') {
        logError('gapi is undefined');
        return null;
    }
    return gapi.auth2 && gapi.auth2.getAuthInstance();
};
export const cookieError = () => {
    disableSignInLayout();
    warning('cookie-error');
    return;
};
const onAuthError = (error) => {
    authError = error;
    logError(error, authError);
};
const authAlert = (error) => {
    alert('Google authentication is not working at this moment.\n\nPlease try again later or use a magic sign-in link.\n\nError: ' + JSON.stringify(error));
    location.reload();
};
const onSignInError = (error) => {
    disableSignInLayout();
    logError(error);
    if (error && error.error === 'popup_closed_by_user') {
        onUserChanged(null);
        return;
    }
    authAlert(error);
};
const googleSignIn = () => getAuth().signIn().catch(onSignInError);
export const authSignIn = () => {
    if (authError) {
        authAlert(authError);
        return;
    }
    if (authStatus === 'LOADING') {
        setTimeout(authSignIn, 400);
    }
    else {
        try {
            googleSignIn();
        }
        catch (err) {
            try {
                initGapi(() => googleSignIn());
            }
            catch (error) {
                onSignInError(error);
            }
        }
        ;
    }
};
export const googleAuthSignOut = async () => {
    const auth = getAuth();
    if (auth) {
        console.info('Google Auth: Signing out');
        await auth.signOut();
        await auth.disconnect();
    }
};
const initGapiClient = (callbackFn) => {
    const clientId = process.env.GOOGLE_CLIENT_ID;
    gapi.auth2.init({ client_id: clientId, prompt: 'select_account' }).then(() => {
        authStatus = 'LOADED';
        const currentUser = getAuth().currentUser;
        currentUser.listen(onGoogleUserChanged);
        callbackFn ? callbackFn(clientId) : onGoogleUserChanged(currentUser.get());
    }, onAuthError);
};
export const initGapi = (callbackFn = null) => {
    if (!window.location.protocol.startsWith('http')) {
        onAuthError('To use Google Auth you must access this page using http(s), not ' + window.location.protocol);
        return;
    }
    if (typeof gapi === 'undefined') {
        onAuthError('Unable to load the Google API');
        return;
    }
    authStatus = 'LOADING';
    gapi.load('auth2', {
        callback: () => initGapiClient(callbackFn),
        onerror: onAuthError,
        ontimeout: onAuthError,
        timeout: 5000
    });
};
