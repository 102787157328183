import { logError } from '../../utils/logger';
import { currentPage, Page } from '../../utils/navigation';
import { getStorage } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { consentTermsModal } from './consent-terms-modal';
import { onboardPage } from './onboard-page';
import { percyModal } from './percy-modal';
export class OnboardModal extends HTMLElement {
    constructor({ onClose: onClose }) {
        super();
        this._displayedPage = 0;
        this._pageIndex = 1;
        // Thumbnail image controls
        this._moveToPage = (n) => {
            this._showPage(this._pageIndex = n);
        };
        this._fillPage = (page) => {
            this._cleanPages();
            const img = page.querySelector('onboard-img');
            if (!img) {
                logError('onboard-img não foi encontrado', 'generic-help-msg');
                return;
            }
            if (this._data[this._pageIndex - 1].hasOwnProperty('img')) {
                img.style.backgroundImage = `url(${this._data[this._pageIndex - 1].img})`;
                img.style.backgroundSize = 'contain';
            }
            const title = page.querySelector('.onboard-title');
            if (!title) {
                logError('.onboard-title não foi encontrado', 'generic-help-msg');
                return;
            }
            title.innerText = this._data[this._pageIndex - 1].title;
            const text = page.querySelector('.onboard-text');
            if (!text) {
                logError('.onboard-text não foi encontrado', 'generic-help-msg');
                return;
            }
            text.innerText = this._data[this._pageIndex - 1].text;
            this._dotSetup(page);
            const onboardLeftBtn = page.querySelector('.onboard-left-btn');
            if (!onboardLeftBtn) {
                logError('.onboard-left-btn não foi encontrado', 'generic-help-msg');
                return;
            }
            onboardLeftBtn.style.visibility = (this._pageIndex === 1) ? 'hidden' : 'visible';
            const onboardRightBtn = page.querySelector('.onboard-right-btn');
            if (!onboardRightBtn) {
                logError('.onboard-right-btn não foi encontrado', 'generic-help-msg');
                return;
            }
            onboardRightBtn.style.visibility = (this._pageIndex === this._data.length) ? 'hidden' : 'visible';
        };
        this._dotSetup = (page) => {
            const dotsContainer = page.querySelector('onboard-dot-container');
            if (!dotsContainer) {
                logError('onboard-dot-container não foi encontrado', 'generic-help-msg');
                return;
            }
            dotsContainer.innerHTML = '';
            for (let i = 1; i <= this._data.length; i++) {
                const dot = document.createElement('onboard-dot');
                dot.onclick = () => {
                    this._moveToPage(i);
                };
                dotsContainer.appendChild(dot);
                if (i === this._pageIndex) {
                    dot.classList.add('active');
                }
            }
        };
        // Next/previous controls
        this._changeOnboardPage = (n) => {
            this._pageIndex += n;
            this._showPage(this._pageIndex);
        };
        this._showConsentTermsModal = () => {
            var _a;
            (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(consentTermsModal());
        };
        this._firstAccessTerms = async () => {
            const res = await getStorage('tips-and-updates');
            if (res === null) {
                this._showConsentTermsModal();
            }
        };
        this._cleanPages = () => {
            const pages = document.querySelectorAll('onboard-page');
            pages.forEach((_page, index) => {
                const img = pages[index].querySelector('onboard-img');
                if (!img) {
                    logError('onboard-img não foi encontrado', 'generic-help-msg');
                    return;
                }
                img.innerHTML = '';
                img.setAttribute('style', 'null');
            });
        };
        this._closeOnboard = () => {
            this._cleanPages();
            this.remove();
            if (this._onClose) {
                setTimeout(this._onClose, 250);
            }
            this._firstAccessTerms();
        };
        this._switchPages = () => {
            const currentOnboardPage = document.querySelectorAll('onboard-page')[this._displayedPage];
            if (!currentOnboardPage) {
                logError(`onboard-page[${this._displayedPage}] não foi encontrado`, 'generic-help-msg');
                return;
            }
            currentOnboardPage.style.display = 'none';
            // @ts-ignore
            // tslint:disable-next-line: no-bitwise
            this._displayedPage = !this._displayedPage | 0;
            const nextOnboardPage = document.querySelectorAll('onboard-page')[this._displayedPage];
            if (!nextOnboardPage) {
                logError(`onboard-page[${this._displayedPage}] não foi encontrado`, 'generic-help-msg');
                return;
            }
            nextOnboardPage.style.display = 'flex';
        };
        this._showPage = (n) => {
            if (n > this._data.length) {
                this._pageIndex = 1;
            }
            if (n < 1) {
                this._pageIndex = this._data.length;
            }
            // @ts-ignore
            // tslint:disable-next-line: no-bitwise
            this._fillPage(document.querySelectorAll('onboard-page')[!this._displayedPage | 0]);
            this._switchPages();
            this.querySelector('.close').focus();
        };
        this._onClose = onClose;
    }
    connectedCallback() {
        this._render();
    }
    show() {
        var _a;
        (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(this);
    }
    get _page2data() {
        return [
            { title: getTranslation('onboard-page2a-title'), text: getTranslation('onboard-page2a-text'), img: '/images/onboard-2a.png', imgSize: '95%' },
            { title: getTranslation('onboard-page2b-title'), text: getTranslation('onboard-page2b-text'), img: '/images/onboard-2b.jpg', imgSize: 'auto 85%' },
            { title: getTranslation('onboard-page2c-title'), text: getTranslation('onboard-page2c-text'), img: '/images/onboard-2c.jpg', imgSize: 'auto 85%' },
            { title: getTranslation('onboard-page2d-title'), text: getTranslation('onboard-page2d-text'), img: '/images/onboard-2d.jpg', imgSize: '110%' },
        ];
    }
    get _page3data() {
        return [
            { title: getTranslation('onboard-page3a-title'), text: getTranslation('onboard-page3a-text'), img: '/images/onboard-3a.jpg', imgSize: '100%' },
            { title: getTranslation('onboard-page3b-title'), text: getTranslation('onboard-page3b-text'), img: '/images/onboard-3b.png', imgSize: '100%' }
        ];
    }
    get _page4data() {
        return [
            { title: getTranslation('onboard-page4a-title'), text: getTranslation('onboard-page4a-text'), img: 'images/onboard-4a.jpg', imgSize: 'auto 75%' },
            { title: getTranslation('onboard-page4b-title'), text: getTranslation('onboard-page4b-text'), img: 'images/onboard-4b.jpg', imgSize: '100%' },
            { title: getTranslation('onboard-page4c-title'), text: getTranslation('onboard-page4c-text'), img: 'images/onboard-4c.png', imgSize: '110%' }
        ];
    }
    _render() {
        this.innerHTML = '';
        const body = document.createElement('modal-body');
        const firstOnboardPage = onboardPage(this._changeOnboardPage);
        const secondOnboardPage = onboardPage(this._changeOnboardPage);
        body.append(firstOnboardPage, secondOnboardPage);
        const modal = percyModal('', [], '', false, false, body, '', '', () => this._closeOnboard());
        this.appendChild(modal);
        this._displayedPage = 0;
        this._pageIndex = 1;
        switch (currentPage()) {
            case Page.TEAMS:
            case Page.TEAMLESS_ADMIN:
                this._data = this._page2data;
                break;
            case Page.APPRAISALS:
            case Page.CLOSED_ROUND:
                this._data = this._page3data;
                break;
            case Page.RESULTS:
            case Page.RESULTS_DATA:
                this._data = this._page4data;
                break;
            default:
                this._data = this._page2data;
        }
        this._showPage(this._pageIndex);
    }
}
customElements.define('onboard-modal', OnboardModal);
