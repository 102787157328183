import { setStorage } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { firstAccessOnboard } from '../onboard';
import { percyModal, toggleBusyStatus } from './percy-modal';
export const consentTermsModal = () => {
    const result = document.createElement('consent-terms-modal');
    const modal = percyModal(getTranslation('welcome-title'), ['ok', 'cancel'], getTranslation('consent-text'), true, false, null, getTranslation('approval-text'), getTranslation('refusal-text'), () => result.remove());
    modal.setAttribute('data-cy', 'consent-modal');
    modal.addEventListener('ok', async () => {
        toggleBusyStatus();
        await setStorage('tips-and-updates', true);
        firstAccessOnboard();
        toggleBusyStatus();
        modal.remove();
        result.remove();
    });
    modal.addEventListener('cancel', async () => {
        toggleBusyStatus();
        await setStorage('tips-and-updates', false);
        firstAccessOnboard();
        toggleBusyStatus();
        modal.remove();
        result.remove();
    });
    result.appendChild(modal);
    return result;
};
