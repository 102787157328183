import { chosenLanguage, getTranslation } from '@klauswuestefeld/jux-web';
import { Length, warning } from '../../utils/flash';
import { logError } from '../../utils/logger';
import { validateCnpjInput } from '../../utils/string';
import { createTableRow } from '../../utils/table';
import { backendGetPromise, backendPost } from '../api-client';
import { paymentModal } from './payment-modal';
const getDifference = (b, c) => b - c;
const getSuggestedAmount = (b, c) => {
    if (getDifference(b, c) > 0) {
        return c > 0 ? c : 1;
    }
    else if (getDifference(b, c) < 0) {
        return Math.abs(getDifference(b, c));
    }
    else {
        return 10;
    }
};
const getCountry = () => window.store.currentUser['paypal-country-code'];
const handleOrderCreation = (b, c) => {
    var _a;
    const cnpjInput = document.querySelector('user-billing-info input');
    if (getCountry() === 'BR') {
        if (!validateCnpjInput(cnpjInput.value)) {
            warning(getTranslation('invalid-cnpj'), Length.long);
            return;
        }
    }
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(paymentModal(getSuggestedAmount(b, c).toString(), getCountry()));
};
const generateUsersReport = () => {
    const popupWindow = window.open('', '_blank');
    if (!popupWindow) {
        logError('popupWindow não foi encontrado', 'generic-help-msg');
        return;
    }
    popupWindow.document.write(getTranslation('loading-report'));
    backendPost('export/open-members-report', JSON.stringify({ language: chosenLanguage }), (res) => {
        popupWindow.location.href = res;
    }, (error) => {
        popupWindow.close();
        logError(error);
        alert(getTranslation('export-report-error') + '\n\n' + ((error === null || error === void 0 ? void 0 : error.message) || error));
    });
};
const usersReport = () => {
    const result = document.createElement('a');
    result.textContent = getTranslation('users-report');
    result.addEventListener('click', generateUsersReport);
    return result;
};
const licensesTable = (teamsLicenses) => {
    const table = document.createElement('table');
    const th = document.createElement('thead');
    const header = createTableRow([
        { text: 'teams-tab', span: 1, cell: 'th' },
        { text: 'users', span: 1, cell: 'th' },
        { text: 'active-licenses', span: 1, cell: 'th' },
    ]);
    th.appendChild(header);
    const tb = document.createElement('tbody');
    const { teams, aggregated } = teamsLicenses;
    const sortedTeams = teams === null || teams === void 0 ? void 0 : teams.sort((a, b) => a['team-name'].localeCompare(b['team-name']));
    sortedTeams === null || sortedTeams === void 0 ? void 0 : sortedTeams.forEach((team) => {
        let teamName = team['team-name'];
        if (team.deleted)
            teamName += getTranslation('deleted-team-hint');
        tb.appendChild(createTableRow([
            { text: teamName, span: 1, cell: 'td' },
            { text: team['team-size'], span: 1, cell: 'td' },
            { text: team['in-use'], span: 1, cell: 'td' },
        ]));
    });
    if (teams && teams.length > 1) {
        tb.appendChild(createTableRow([
            { text: 'all-teams', span: 1, cell: 'td', style: 'highlighted-cell' },
            { text: aggregated['team-size'], span: 1, cell: 'td' },
            { text: aggregated['in-use'], span: 1, cell: 'td' },
        ]));
    }
    table.append(th, tb);
    return table;
};
const cost = (teamsLicenses) => teamsLicenses && teamsLicenses.aggregated
    ? teamsLicenses.aggregated['team-size'] - teamsLicenses.aggregated['in-use']
    : 0;
export const licensesSection = () => {
    const result = document.createElement('licenses-section');
    const content = document.createElement('licenses-content');
    const title = document.createElement('p');
    title.className = 'section-title';
    title.textContent = getTranslation('licenses-title');
    const explanation = document.createElement('p');
    explanation.textContent = getTranslation('licenses-explanation');
    content.append(title, explanation);
    result.appendChild(content);
    const admin = window.store.currentUser.email;
    backendGetPromise(`credits-balance-for?admin=${admin}`)
        .then((balance) => {
        const btn = document.createElement('licenses-button');
        btn.tabIndex = 0;
        btn.setAttribute('class', 'primary-button');
        btn.textContent = getTranslation('licenses-button');
        const balanceDescription = document.createElement('p');
        balanceDescription.textContent = getTranslation('available-licenses') + balance;
        content.append(balanceDescription, btn);
        backendGetPromise('teams-licenses-info')
            .then((teamsLicenses) => {
            if (teamsLicenses) {
                const table = licensesTable(teamsLicenses);
                const usersInfo = document.createElement('users-info');
                const usersDescription = document.createElement('p');
                usersDescription.innerHTML = getTranslation('users-description') + '&nbsp;';
                usersInfo.appendChild(usersDescription);
                if ((teamsLicenses === null || teamsLicenses === void 0 ? void 0 : teamsLicenses.teams.length) > 1) {
                    usersInfo.appendChild(usersReport());
                }
                content.insertBefore(table, balanceDescription);
                content.insertBefore(usersInfo, balanceDescription);
            }
            btn.addEventListener('click', () => handleOrderCreation(balance, cost(teamsLicenses)));
            return result;
        })
            .catch(logError);
        return result;
    })
        .catch(logError);
    return result;
};
