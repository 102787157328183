import { getTranslation } from '@klauswuestefeld/jux-web';
import { showTeamMemberModal } from '../teams';
import { actionBtn } from './action-btn';
import { importMembersModal } from './import-members-modal';
import { sendInvitesModal } from './send-invites-modal';
const renderSendInvitesModal = async (isTeamAdmin) => {
    var _a;
    if (!isTeamAdmin) {
        return;
    }
    const modal = await sendInvitesModal();
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(modal);
};
const renderImportMembersModal = (isTeamAdmin) => {
    var _a;
    if (!isTeamAdmin) {
        return;
    }
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(importMembersModal());
};
export const membersActions = (isAdmin) => {
    var _a, _b;
    const result = document.createElement('members-actions');
    const hasPendingInvites = (_b = (_a = window.store.selectedTeam) === null || _a === void 0 ? void 0 : _a.members) === null || _b === void 0 ? void 0 : _b.some((member) => member.status === 'pending');
    const title = document.createElement('team-members-title');
    title.textContent = getTranslation('team-members-title');
    title.className = 'section-title';
    if (!isAdmin) {
        result.appendChild(title);
        return result;
    }
    const btns = document.createElement('members-actions-btns');
    const importBtn = actionBtn('secondary-button small import-members-btn', 'import-members', 'import-team');
    importBtn.addEventListener('action', () => renderImportMembersModal(isAdmin));
    const addBtn = actionBtn('primary-button small', 'add-member', 'add-member');
    addBtn.addEventListener('action', () => showTeamMemberModal(null, isAdmin));
    if (hasPendingInvites) {
        const inviteBtn = actionBtn('secondary-button small', 'email-icon-green', 'send-invites', '.png');
        inviteBtn.addEventListener('action', () => renderSendInvitesModal(isAdmin));
        btns.append(inviteBtn, importBtn, addBtn);
    }
    else {
        btns.append(importBtn, addBtn);
    }
    result.append(title, btns);
    return result;
};
