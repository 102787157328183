import { getTranslation } from '@klauswuestefeld/jux-web';
import { appraisalSubheader } from './appraisal-subheader';
import { editMembersModal, ModalMode } from './edit-members-modal';
export const teamlessAdmin = () => {
    const result = document.createElement('teamless-admin');
    const teamlessMsg = document.createElement('teamless-msg');
    teamlessMsg.id = 'teamless';
    teamlessMsg.textContent = getTranslation('teamless');
    const br = document.createElement('br');
    const link = document.createElement('percy-link');
    link.id = 'teamless-link';
    link.setAttribute('class', 'link');
    link.addEventListener('click', async () => {
        var _a;
        const { name, email } = window.store.currentUser;
        const modal = editMembersModal(ModalMode.JOIN, {
            name: email === name ? '' : name,
            email,
        });
        (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(modal);
        modal.querySelector('#member-name-modal').focus();
    });
    link.textContent = getTranslation('teamless-link');
    const container = document.createElement('msg-container');
    container.style.display = 'block';
    container.append(teamlessMsg, br, link);
    result.append(appraisalSubheader(false), container);
    return result;
};
