import { appraisalSubheader } from '../components/appraisal-subheader';
import { publishBarContainer } from '../components/publish-bar-container';
import { resultsSection } from '../components/results-section';
export const resultsPage = () => {
    const result = document.createElement('results-page');
    window.store.resultsData = null;
    const section = resultsSection();
    const resultsWrapper = document.createElement('results-wrapper');
    resultsWrapper.appendChild(section);
    result.append(appraisalSubheader(false), resultsWrapper, publishBarContainer());
    return result;
};
