import { getTranslation } from '@klauswuestefeld/jux-web';
import { formatDisplayValue } from '../../utils/string';
import { buildGraphBar, buildNameLine, buildResultRow, calculateBarWidth, createProfileColumn, displayTextWidth, showBlankMessage } from './results-list';
import { resultsPaywall } from './results-paywall';
const onAppraisalSelectionChange = (member) => {
    var _a;
    window.store.resultsData.results = (_a = window.store.resultsData[member]) === null || _a === void 0 ? void 0 : _a.results;
    window.store.resultsData.appraiser = member;
    const resultsList = document.querySelector('results-list');
    slicingSection(resultsList);
};
const buildRankingRow = (item, maxValue) => {
    var _a, _b;
    const result = document.createElement('results-row');
    // Profile pic
    createProfileColumn(result, item.picture);
    // Graph Container
    const container = document.createElement('graph-container');
    // Name & bar
    const barWidth = calculateBarWidth(item['team-appraisal'], maxValue);
    const teamAppraisalDisplay = formatDisplayValue(item['team-appraisal'], '%');
    container.appendChild(buildNameLine(item.name));
    container.appendChild(buildGraphBar('received-appraisal-bar', barWidth, teamAppraisalDisplay));
    // Dotted Line
    const dottedLine = document.createElement('given-appraisal-bar');
    dottedLine.style.width = calculateBarWidth(item['your-appraisal'], maxValue);
    const yourAppraisal = document.createElement('bar-value');
    yourAppraisal.setAttribute('class', 'given-appraisal-value');
    const yourAppraisalValue = formatDisplayValue(item['your-appraisal'], '%');
    yourAppraisal.innerText = yourAppraisalValue;
    // 1 extra pixel because of the border
    yourAppraisal.style.left = `${displayTextWidth(yourAppraisalValue) + 1}px`;
    dottedLine.appendChild(yourAppraisal);
    container.appendChild(dottedLine);
    const isAdmin = (_a = window.store) === null || _a === void 0 ? void 0 : _a.selectedTeam['is-admin'];
    const isPublishedAnonymously = ((_b = window.store) === null || _b === void 0 ? void 0 : _b.selectedTeam.round) === 'published-anonymously';
    if (!isAdmin && isPublishedAnonymously)
        dottedLine.style.visibility = 'hidden';
    result.appendChild(container);
    return result;
};
const maxAppraisalForMember = (email) => {
    return Math.max.apply(Math, window.store.resultsData[email].results.map((item) => {
        const teamAppraisal = item['team-appraisal'] || 0;
        const yourAppraisal = item['your-appraisal'] || 0;
        if (teamAppraisal > yourAppraisal) {
            return teamAppraisal;
        }
        return yourAppraisal;
    }));
};
const getMaxValue = () => {
    const membersMaxValue = [];
    const members = window.store.selectedTeam.members;
    members.forEach((member) => {
        if (window.store.resultsData[member.email]) {
            membersMaxValue.push(maxAppraisalForMember(member.email));
        }
    });
    return Math.max.apply(Math, membersMaxValue);
};
export const slicingSection = (element) => {
    var _a, _b, _c;
    const result = element;
    result.innerHTML = '';
    if (!((_b = (_a = window.store.resultsData) === null || _a === void 0 ? void 0 : _a.results) === null || _b === void 0 ? void 0 : _b.length)) {
        return showBlankMessage(result, 'blank-results-msg', 'blank-results-msg-no-padding');
    }
    if (window.store.resultsData['enough-credits'] === false) {
        result.appendChild(resultsPaywall(Number(window.store.resultsData.missing)));
        return result;
    }
    let maxValue = getMaxValue();
    const containerWidth = 750; // page-container width - 50
    const maxValueWidth = displayTextWidth(formatDisplayValue(maxValue, '%'));
    // Max value adjusted for profile space (40px) and valueLabelWidth
    maxValue *= (containerWidth / (containerWidth - 50 - maxValueWidth));
    result.appendChild(buildResultRow({ element: 'received-appraisal-bar', value: getTranslation('ranking-results-bar').toUpperCase() }, { element: 'given-appraisal-bar', value: getTranslation('ranking-results-divergence').toUpperCase() }, onAppraisalSelectionChange));
    const appraisers = (_c = window.store.selectedTeam) === null || _c === void 0 ? void 0 : _c.members.filter((m) => {
        return !m.role.includes('appraised') && m.role.includes('appraiser');
    });
    const appraisersEmails = appraisers === null || appraisers === void 0 ? void 0 : appraisers.map((appraiser) => appraiser.email);
    const sliceable = window.store.resultsData.results.filter((m) => !appraisersEmails.includes(m.email));
    sliceable.forEach((item) => result.appendChild(buildRankingRow(item, maxValue)));
    if (!window.store.resultsData.published) {
        const publishBarContainer = document.querySelector('publish-bar-container');
        publishBarContainer.style.display = 'block';
    }
    result.style.marginBottom = (!window.store.resultsData.published) ? '60px' : '';
    return result;
};
