import { getTranslation } from '@klauswuestefeld/jux-web';
import { deleteTeam } from '../api-client';
import { loadTeamsPage } from '../teams';
import { percyModal } from './percy-modal';
const onDeleteTeam = async () => {
    await deleteTeam();
    window.store.selectedTeam = null;
    window.store.teamId = null;
    localStorage.setItem('team-id', '');
    loadTeamsPage();
};
const teamDeletionModal = () => {
    const team = window.store.selectedTeam.name;
    const body = document.createElement('deletion-modal-body');
    const description = document.createElement('p');
    description.innerHTML = getTranslation('deletion-confirmation').replace('${team}', team);
    const typeInfo = document.createElement('p');
    typeInfo.innerHTML = getTranslation('deletion-type').replace('${team}', team);
    const input = document.createElement('input');
    input.type = 'text';
    body.append(description, typeInfo, input);
    const modal = percyModal(getTranslation('delete-team'), ['ok', 'cancel'], '', false, false, body, getTranslation('delete-member-btn'), getTranslation('alert-cancel-btn-txt'));
    modal.setAttribute('data-cy', 'deletion-modal');
    modal.className = 'deletion-modal';
    modal.addEventListener('ok', async () => {
        try {
            await onDeleteTeam();
            modal.remove();
        }
        catch (err) {
            console.error(err);
        }
    });
    const button = modal.querySelector('.ok');
    button.disabled = true;
    const spinner = button.querySelector('percy-spinner');
    spinner.style.opacity = '0';
    input.addEventListener('keyup', (_ev) => {
        button.disabled = true;
        spinner.style.opacity = '0';
        if (input.value === team) {
            button.disabled = false;
        }
    });
    document.body.appendChild(modal);
};
export const deleteTeamButton = () => {
    const result = document.createElement('delete-team-button');
    const btn = document.createElement('button');
    btn.setAttribute('class', 'primary-button small');
    btn.addEventListener('click', (_ev) => teamDeletionModal());
    const icon = document.createElement('img');
    icon.className = 'btn-icon';
    icon.src = './images/delete-team.png';
    const btnTxt = document.createElement('delete-team');
    btnTxt.textContent = getTranslation('delete-team');
    btn.append(icon, btnTxt);
    result.append(btn);
    return result;
};
