// Percival Palette
// https://color.adobe.com/pt/Percival-With-Alert-3-color-theme-15763939
export const PERCIVAL_DARK_GREEN = '#171F1C';
export const PERCIVAL_MEDIUM_GREEN = '#0D631E';
export const PERCIVAL_LIGHT_GREEN = '#20B03D';
export const PERCIVAL_WOOD = '#F2C89B';
export const PERCIVAL_RED = '#CC5E45';
/* Percival Palette Variations */
export const PERCIVAL_DARK_WOOD = '#EBAB66';
export const PERCIVAL_DIMMED_WOOD = '#F2C89B55';
/* Third Party Colors */
export const PERCIVAL_GOOGLE_BTN = '#444';
export const PERCIVAL_GOOGLE_BORDER = '#888';
export const PERCIVAL_GOOGLE_ACTIVE = '#EEE';
export const PERCIVAL_GOOGLE_BOX_SHADOW = '#808080';
/* Extra Colors */
export const PERCIVAL_BLUE = '#2B4588';
export const PERCIVAL_WHITE = '#FFF';
export const PERCIVAL_DIMMED_LIGHT_GREY = '#DDDDDD70';
export const PERCIVAL_LIGHT_GREY = '#DDDDDD';
export const PERCIVAL_GREY = '#808080';
export const PERCIVAL_MEDIUM_GREY = '#BABABA';
export const PERCIVAL_BLUISH_GREY = '#3D4550';
export const PERCIVAL_DIMMED_DARK_GREY = '#28282822';
export const PERCIVAL_DARK_GREY = '#282828';
export const PERCIVAL_BLACK = '#000';
export const PERCIVAL_DIMMED_BLACK = '#00000088';
// Header
export const PERCIVAL_HEADER_HEIGHT = 72;
export const PERCIVAL_QUESTION_HEIGHT = 74;
// Footer
export const FOOTER_HEIGHT = 50;
// QuerySelector
export const THRESHOLD_WIDTH = 640;
