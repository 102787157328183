import { changePage } from '../../utils/navigation';
import { singularOrPluralString } from '../../utils/string';
import { getTranslation } from '@klauswuestefeld/jux-web';
export const resultsPaywall = (needed) => {
    const result = document.createElement('results-paywall');
    const content = document.createElement('results-paywall-content');
    const firstRow = document.createElement('percy-row');
    const msg = document.createElement('p');
    msg.textContent = getTranslation('results-paywall-msg') + ' ' + singularOrPluralString(needed, 'licenses');
    firstRow.appendChild(msg);
    const btn = document.createElement('licenses-button');
    btn.tabIndex = 0;
    btn.setAttribute('class', 'primary-button');
    btn.textContent = getTranslation('licenses-button');
    btn.addEventListener('click', () => changePage('account-tab'));
    content.append(firstRow, btn);
    result.appendChild(content);
    return result;
};
