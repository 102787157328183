import { updateTeamName } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { percyModal, toggleBusyStatus } from './percy-modal';
export const editTeamModal = () => {
    const team = window.store.selectedTeam;
    const result = document.createElement('edit-team-modal');
    const body = document.createElement('modal-body');
    const nameLabel = document.createElement('label');
    nameLabel.htmlFor = 'team-name-modal';
    nameLabel.textContent = getTranslation('team-name-label');
    const teamNameInput = document.createElement('input');
    teamNameInput.type = 'text';
    teamNameInput.className = 'textfield';
    teamNameInput.id = 'team-name-modal';
    teamNameInput.placeholder = getTranslation('name');
    teamNameInput.maxLength = 30;
    body.append(nameLabel, teamNameInput);
    const modal = percyModal(getTranslation('edit-team-modal-title'), ['ok', 'cancel'], '', false, false, body, getTranslation('save-team-btn'));
    modal.setAttribute('data-cy', 'edit-team-modal');
    teamNameInput.value = team.name;
    modal.addEventListener('ok', async () => {
        toggleBusyStatus();
        team.name = teamNameInput.value;
        const ret = await updateTeamName();
        toggleBusyStatus();
        if (ret) {
            modal.remove();
        }
    });
    result.appendChild(modal);
    teamNameInput.focus();
    teamNameInput.select();
    return result;
};
