import { logError } from '../utils/logger';
import { displayPage, Page } from '../utils/navigation';
import { backendPostPromise, getTeamId } from './api-client';
import { certaintyModal } from './components/certainty-modal';
import { editAppraisalsButton } from './components/edit-appraisals-button';
export const moveCurrentAppraisalToMembersPool = (appraisal) => {
    const formerLevel = document.getElementById('cards-level' + appraisal.level);
    const card = document.getElementById('card-' + (appraisal === null || appraisal === void 0 ? void 0 : appraisal.email));
    if (!formerLevel || !card) {
        logError('Appraisals > moveCurrentAppraisalToMembersPool falhou', 'generic-help-msg');
        return;
    }
    formerLevel.removeChild(card);
    appraisal.level = 0;
    const membersPool = document.querySelector('members-pool');
    const notAppraised = membersPool.appraisals;
    if (!membersPool.appraisals.includes(appraisal)) {
        notAppraised.push(appraisal);
    }
    membersPool.appraisals = notAppraised;
};
export const loadAppraisalsPage = () => {
    const teamId = getTeamId();
    backendPostPromise('team-profiles2', JSON.stringify({ 'team-id': teamId }))
        .then((team) => {
        window.store.selectedTeam = team;
        if (team.round === 'open') {
            if (!window.store.currentUserIsAdmin) {
                window.store.currentUser.status = team.status;
            }
            showApprasingStatus();
            displayPage(Page.APPRAISALS);
        }
        else {
            displayPage(Page.CLOSED_ROUND, team);
        }
    })
        .catch(logError);
};
export const showApprasingStatus = async () => {
    var _a, _b, _c;
    const teamId = getTeamId();
    window.store.currentUser.status = await backendPostPromise(`get-appraising-status`, JSON.stringify({ 'team-id': teamId })).then(res => res);
    (_a = document.querySelector('edit-appraisals-button')) === null || _a === void 0 ? void 0 : _a.setAttribute('status', window.store.currentUser.status);
    (_b = document.querySelector('edit-appraisals-button')) === null || _b === void 0 ? void 0 : _b.remove();
    (_c = document.querySelector('appraisal-subheader')) === null || _c === void 0 ? void 0 : _c.appendChild(editAppraisalsButton(window.store.currentUser.status));
};
export const showModal = (clickedCardId) => {
    var _a, _b;
    const appraiseId = clickedCardId.substring(5); // email
    window.store.currentAppraisal = (_a = window.store.allAppraisals) === null || _a === void 0 ? void 0 : _a.find((eachAppraisal) => {
        return eachAppraisal.email === appraiseId;
    });
    if (!window.store.currentAppraisal) {
        logError('Appraisals > window.store.currentAppraisal não encontrado', 'no-appraisal');
        return;
    }
    (_b = document.querySelector('percy-body')) === null || _b === void 0 ? void 0 : _b.appendChild(certaintyModal(window.store.currentAppraisal));
};
