import { getTranslation } from '@klauswuestefeld/jux-web';
export const percyFooter = () => {
    const result = document.createElement('percy-footer');
    result.setAttribute('role', 'contentinfo');
    const links = document.createElement('footer-links');
    const privacyLink = document.createElement('a');
    privacyLink.href = getTranslation('privacy-policy-url');
    privacyLink.textContent = getTranslation('privacy-policy');
    privacyLink.target = '_blank';
    const cookiesLink = document.createElement('a');
    cookiesLink.href = getTranslation('cookies-policy-url');
    cookiesLink.textContent = getTranslation('cookies-policy');
    cookiesLink.target = '_blank';
    links.append(privacyLink, cookiesLink);
    const msg = document.createElement('copyright-msg');
    msg.textContent = getTranslation('copyright-msg');
    result.append(links, msg);
    return result;
};
