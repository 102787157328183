import { getTranslation } from '@klauswuestefeld/jux-web';
import { warning } from '../utils/flash';
import { displayPage, Page } from '../utils/navigation';
import { backendGet, setStorage } from './api-client';
import { juxButton } from './components/jux-button';
export const isRunningDemo = () => { var _a; return (_a = localStorage.getItem('token')) === null || _a === void 0 ? void 0 : _a.includes('demo-token-'); };
const demoUrls = ['/xp2022', '/demo'];
export const startNewDemo = () => {
    if (!demoUrls.includes(window.location.pathname))
        return false;
    if (isRunningDemo())
        return false;
    return true;
};
const onDemoLoginRequest = ({ userName, onAuthentication, setBackendToken }) => {
    if (!userName) {
        warning('no-demo-user-name');
        return;
    }
    setBackendToken();
    backendGet(`profile?name=${encodeURIComponent(userName)}`, async (res) => {
        const storageInteraction1 = setStorage('tips-and-updates', true);
        const storageInteraction2 = setStorage('accessed-admin-onboarding', true);
        const storageInteraction3 = setStorage('accessed-appraisals-onboarding', true);
        const storageInteraction4 = setStorage('accessed-results-onboarding', true);
        await storageInteraction1;
        await storageInteraction2;
        await storageInteraction3;
        await storageInteraction4;
        onAuthentication(res, 'Percival Token Authentication');
    }, (_err) => {
        localStorage.removeItem('token');
        displayPage(Page.DEMO, { onAuthentication, setBackendToken });
        alert(getTranslation('login-failed'));
    });
};
export const demoPage = ({ onAuthentication, setBackendToken }) => {
    const result = document.createElement('demo-page');
    const container = document.createElement('percy-container');
    const salutation = document.createElement('sign-in-salutation');
    salutation.textContent = getTranslation('sign-in-salutation');
    const explanation = document.createElement('sign-in-explanation');
    explanation.textContent = getTranslation('sign-in-explanation');
    const input = document.createElement('input');
    input.className = 'textfield';
    input.type = 'text';
    input.id = 'demo-input';
    input.placeholder = getTranslation('demo-placeholder');
    const label = document.createElement('label');
    label.textContent = getTranslation('demo-explanation');
    label.htmlFor = input.id;
    const button = juxButton('demo-button', 'primary-button', () => {
        button.disabled = true;
        onDemoLoginRequest({
            userName: input.value,
            onAuthentication,
            setBackendToken,
        });
    });
    container.append(salutation, explanation, label, input, button);
    result.style.backgroundImage = "url('/images/percival-login.jpg')";
    result.appendChild(container);
    return result;
};
export const initDemo = async (setBackendToken, onAuthentication) => {
    const demoToken = 'demo-token-' + Date.now();
    displayPage(Page.DEMO, { onAuthentication, setBackendToken: () => setBackendToken(demoToken) });
};
