export var Position;
(function (Position) {
    Position[Position["TOP"] = 0] = "TOP";
    Position[Position["BOTTOM"] = 1] = "BOTTOM";
    Position[Position["LEFT"] = 2] = "LEFT";
    Position[Position["RIGHT"] = 3] = "RIGHT";
    Position[Position["TOP_LEFT"] = 4] = "TOP_LEFT";
    Position[Position["TOP_RIGHT"] = 5] = "TOP_RIGHT";
    Position[Position["BOTTOM_LEFT"] = 6] = "BOTTOM_LEFT";
    Position[Position["BOTTOM_RIGHT"] = 7] = "BOTTOM_RIGHT";
})(Position || (Position = {}));
export var Visibility;
(function (Visibility) {
    Visibility[Visibility["VISIBLE"] = 0] = "VISIBLE";
    Visibility[Visibility["HIDDEN"] = 1] = "HIDDEN";
})(Visibility || (Visibility = {}));
