import { handleTeamCreation } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { percyModal, toggleBusyStatus } from './percy-modal';
export const showNewTeamModal = () => {
    var _a;
    const modal = newTeamModal();
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(modal);
    modal.querySelector('#team-name-modal').focus();
};
export const newTeamModal = () => {
    const result = document.createElement('new-team-modal');
    const body = document.createElement('modal-body');
    const label = document.createElement('label');
    label.htmlFor = 'team-name-modal';
    label.textContent = getTranslation('team-name-label');
    const input = document.createElement('input');
    input.type = 'text';
    input.className = 'textfield';
    input.id = 'team-name-modal';
    input.placeholder = getTranslation('name');
    input.maxLength = 30;
    body.append(label, input);
    const modal = percyModal(getTranslation('new-team-modal-title'), ['ok', 'cancel'], '', false, false, body, getTranslation('new-team'));
    modal.setAttribute('data-cy', 'new-team-modal');
    modal.addEventListener('ok', async () => {
        toggleBusyStatus();
        const ret = await handleTeamCreation(input.value);
        toggleBusyStatus();
        if (ret) {
            modal.remove();
        }
    });
    result.appendChild(modal);
    input.focus();
    input.select();
    return result;
};
