import { Length, success } from '../../utils/flash';
import { logError } from '../../utils/logger';
import { backendPostPromise, getTeamId } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { percyModal, toggleBusyStatus } from './percy-modal';
const updateRoundStatus = async (input = null, open) => {
    const status = open ? 'open' : 'closed';
    const teamId = getTeamId();
    const team = await backendPostPromise('set-round-status', JSON.stringify({ 'team-id': teamId, status }))
        .then((res) => {
        success(getTranslation('ok-text'), Length.short);
        return res;
    })
        .catch(logError);
    if (input) {
        input.checked = !input.checked;
    }
    return team;
};
export const showAllowAppraisalsModal = (text, input, open, callback) => {
    var _a;
    const modal = percyModal('', ['ok', 'cancel'], '', false, false, text);
    modal.setAttribute('data-cy', 'alert-modal');
    modal.id = 'update-allow-appraisals';
    modal.addEventListener('ok', async () => {
        toggleBusyStatus();
        window.store.selectedTeam = await updateRoundStatus(input, open);
        toggleBusyStatus();
        modal.remove();
        if (callback) {
            callback(window.store.selectedTeam);
        }
    });
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(modal);
};
const updateAllowAppraisalsAlert = () => {
    const text = document.createElement('b');
    const input = document.querySelector('round-status input');
    const open = !input.checked;
    text.textContent = !input.checked ? getTranslation('unpublish-alert') : getTranslation('stop-appraisals-alert');
    showAllowAppraisalsModal(text, input, open);
};
export const roundStatus = (round) => {
    const result = document.createElement('round-status');
    const label = document.createElement('label');
    const title = document.createElement('round-status-title');
    title.textContent = getTranslation('round-status-title');
    label.htmlFor = 'round-status-open';
    label.appendChild(title);
    const percySwitch = document.createElement('percy-switch');
    percySwitch.className = 'switch';
    const input = document.createElement('input');
    input.id = 'round-status-open';
    input.type = 'checkbox';
    input.checked = round === 'open';
    input.addEventListener('click', (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        updateAllowAppraisalsAlert();
    });
    percySwitch.addEventListener('click', (_ev) => {
        updateAllowAppraisalsAlert();
    });
    const slider = document.createElement('round-slider');
    slider.setAttribute('aria-hidden', 'true');
    percySwitch.append(input, slider);
    result.append(label, percySwitch);
    return result;
};
