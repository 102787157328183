import { backendPost, getTeamId } from '../api-client';
import { showApprasingStatus } from '../appraisals';
import { getTranslation } from '@klauswuestefeld/jux-web';
const getIsDone = (status) => {
    return status === 'done';
};
const getText = (status) => {
    return getIsDone(status) ? getTranslation('edit') : getTranslation('finish');
};
const getImage = (status) => {
    return getIsDone(status) ? 'images/edit-appraisal-white.svg' : 'images/close-appraisal.svg';
};
const refreshOverlay = (status) => {
    var _a;
    const darkOverlay = document.querySelector('dark-overlay');
    darkOverlay === null || darkOverlay === void 0 ? void 0 : darkOverlay.remove();
    if (getIsDone(status)) {
        const newOverlay = document.createElement('dark-overlay');
        (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(newOverlay);
    }
};
const onClick = (status) => {
    if (getIsDone(status)) {
        status = 'appraising';
        setAppraisingStatus(status);
        return;
    }
    status = 'done';
    setAppraisingStatus(status);
};
const setAppraisingStatus = (status) => {
    const teamId = getTeamId();
    backendPost('set-appraising-status', JSON.stringify({ 'team-id': teamId, status }), (_res) => {
        window.store.currentUser.status = status;
        showApprasingStatus();
    }, (_err) => {
        showApprasingStatus();
        alert('There was an error updating your appraisal status.');
    });
};
export const editAppraisalsButton = (status = '') => {
    const result = document.createElement('edit-appraisals-button');
    result.setAttribute('status', status);
    const btn = document.createElement('button');
    const img = document.createElement('img');
    img.src = getImage(status);
    img.alt = '';
    const b = document.createElement('b');
    b.textContent = getText(status);
    btn.append(img, b);
    result.appendChild(btn);
    result.onclick = () => onClick(status);
    refreshOverlay(status);
    return result;
};
