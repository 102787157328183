import { percyTeam } from '../components/percy-team';
import { percyTeamList } from '../components/percy-team-list';
export const teamsPage = () => {
    const result = document.createElement('teams-page');
    const section = document.createElement('teams-section');
    section.className = 'page-container';
    section.append(percyTeamList(), percyTeam());
    result.append(section);
    return result;
};
