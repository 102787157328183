import { getTranslation } from '@klauswuestefeld/jux-web';
import { resultsList } from './results-list';
const changeResultsPage = (page) => {
    var _a, _b;
    const resultsSection = document.querySelector('results-section');
    const header = resultsMenu(page);
    const list = resultsList(page);
    (_a = document.querySelector('results-header')) === null || _a === void 0 ? void 0 : _a.remove();
    (_b = document.querySelector('results-list')) === null || _b === void 0 ? void 0 : _b.remove();
    resultsSection === null || resultsSection === void 0 ? void 0 : resultsSection.append(header, list);
};
export const resultsMenu = (selectedMenu = 'certainty-menu') => {
    var _a;
    const result = document.createElement('results-header');
    const buttons = [
        { id: 'certainty-menu', class: 'big' },
        { id: 'ranking-menu', class: 'big' },
        { id: 'data-menu', class: '' }
    ];
    for (const button of buttons) {
        const newButton = document.createElement('button');
        newButton.id = button.id;
        newButton.textContent = getTranslation(button.id);
        newButton.className = button.class;
        newButton.addEventListener('click', () => changeResultsPage(button.id));
        if (button.id === selectedMenu) {
            newButton.classList.add('selected');
        }
        else {
            newButton.classList.remove('selected');
        }
        if ((_a = window.store) === null || _a === void 0 ? void 0 : _a.selectedTeam['is-admin']) {
            if (newButton.id === 'certainty-menu' || newButton.id === 'ranking-menu') {
                newButton.classList.remove('big');
            }
            else {
                newButton.style.display = 'inline-block';
            }
        }
        result.appendChild(newButton);
    }
    return result;
};
