import { chosenLanguage, getTranslation } from '@klauswuestefeld/jux-web';
import { logError } from '../../utils/logger';
import { backendPost, getTeamId } from '../api-client';
import { juxButton } from './jux-button';
const hasEnoughLicenses = () => window.store.resultsData['enough-credits'];
const requestURLLoading = (id, request, close = false) => {
    const popupWindow = window.open('', '_blank');
    if (!popupWindow) {
        logError('popupWindow não foi encontrado', 'generic-help-msg');
        return;
    }
    popupWindow.document.write(getTranslation('loading-results'));
    const button = document.querySelector(`#${id}`);
    button.disabled = true;
    backendPost(request, JSON.stringify({
        language: chosenLanguage,
        'team-id': getTeamId(),
    }), (res) => {
        button.disabled = false;
        popupWindow.location.href = res;
        if (close) {
            // TODO, check if this is the best solution
            setTimeout(() => popupWindow.close(), 1000);
        }
    }, (error) => {
        button.disabled = false;
        popupWindow.close();
        logError(error);
        alert(getTranslation('export-results-error') + '\n\n' + ((error === null || error === void 0 ? void 0 : error.message) || error));
    });
};
export const dataSection = (element) => {
    const result = element;
    result.innerHTML = '';
    const container = document.createElement('results-container');
    container.style.display = 'block';
    const title = document.createElement('results-data-title');
    title.textContent = getTranslation('results-data-title');
    title.style.display = 'block';
    const dataContainer = document.createElement('results-data-container');
    const historyBtn = juxButton('history-btn', 'secondary-button', () => requestURLLoading('history-btn', 'export/open-history-link'));
    if (hasEnoughLicenses()) {
        const finalResultBtn = juxButton('final-result-btn', 'primary-button darker-button', () => requestURLLoading('final-result-btn', 'export/open-current-link'));
        const downloadBtn = juxButton('download-result-btn', 'secondary-button', () => requestURLLoading('download-result-btn', 'export/download-current-link', true));
        dataContainer.append(finalResultBtn, downloadBtn, historyBtn);
    }
    else {
        dataContainer.appendChild(historyBtn);
    }
    container.append(title, dataContainer);
    result.appendChild(container);
    const publishBar = document.querySelector('publish-bar-container');
    if (publishBar) {
        publishBar.style.display = 'none';
    }
    return result;
};
