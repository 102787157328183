import { changePage, currentTab } from '../../utils/navigation';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { isFirstTimer } from '../session';
export const percyMenu = (isSuperAdmin = false) => {
    const result = document.createElement('percy-menu');
    // TODO criar custom elements para tabs
    result.setAttribute('role', 'navigation');
    const buttons = [
        { id: 'superadmin-tab', class: 'tab', fn: () => changePage('superadmin-tab') },
        { id: 'teams-tab', class: 'tab', fn: () => changePage('teams-tab') },
        { id: 'appraisals-tab', class: 'tab', fn: () => changePage('appraisals-tab') },
        { id: 'results-tab', class: 'tab', fn: () => changePage('results-tab') },
        { id: 'account-tab', class: 'tab', fn: () => changePage('account-tab') }
    ];
    for (const button of buttons) {
        if (button.id === 'superadmin-tab' && !isSuperAdmin) {
            continue;
        }
        else if (button.id === 'results-tab' && isFirstTimer()) {
            continue;
        }
        else if (button.id === 'appraisals-tab' && isFirstTimer()) {
            continue;
        }
        const newButton = document.createElement('button');
        newButton.id = button.id;
        newButton.setAttribute('class', button.class);
        newButton.addEventListener('click', button.fn);
        newButton.textContent = getTranslation(button.id);
        if (button.id === currentTab()) {
            newButton.classList.add('selected');
        }
        result.appendChild(newButton);
    }
    return result;
};
