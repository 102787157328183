import { THRESHOLD_WIDTH } from '../../utils/constants';
import { getTranslation } from '@klauswuestefeld/jux-web';
export const loginButton = (src, fn, type, height = 20, extraClass) => {
    const result = document.createElement('login-button');
    result.tabIndex = 0;
    result.setAttribute('data-cy', `${type.toLowerCase()}-button`);
    if (document.documentElement.lang === 'pt') {
        result.classList.add('login-pt-format');
    }
    const icon = document.createElement('login-icon');
    if (extraClass)
        icon.classList.add(extraClass);
    const img = document.createElement('img');
    img.width = 20;
    img.height = height;
    img.className = 'ico';
    img.alt = '';
    img.src = `./images/${src}`;
    icon.appendChild(img);
    const label = document.createElement('login-label');
    label.textContent = window.innerWidth <= THRESHOLD_WIDTH ? type : getTranslation(`${type.toLowerCase()}-label`);
    result.onclick = () => fn();
    result.append(icon, label);
    return result;
};
