import { getTranslation } from '@klauswuestefeld/jux-web';
import { certaintyClicked } from './certainty-button';
export const cancelAppraisalButton = (appraisal) => {
    const result = document.createElement('button');
    result.id = `certainty-0`;
    result.textContent = getTranslation('dont-appraise');
    result.onclick = () => certaintyClicked(0, appraisal);
    result.className = 'cancel-appraisal-button';
    return result;
};
