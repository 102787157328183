import { hamburgerMenu } from './hamburger-menu';
import { headerLogo } from './header-logo';
import { percyMenu } from './percy-menu';
import { percySession } from './percy-session';
const getName = () => {
    var _a;
    if ((_a = window.store) === null || _a === void 0 ? void 0 : _a.currentUser) {
        return window.store.currentUser.name ? window.store.currentUser.name + '  ▾' : window.store.currentUser.email + '  ▾';
    }
    return 'User';
};
export const percyHeader = ({ isLogin = false } = {}) => {
    var _a, _b, _c, _d;
    const result = document.createElement('percy-header');
    if (localStorage.getItem('super-token')) {
        result.classList.add('impersonating');
    }
    result.setAttribute('role', 'banner');
    if (isLogin) {
        result.appendChild(headerLogo());
        return result;
    }
    result.append(hamburgerMenu((_a = window.store) === null || _a === void 0 ? void 0 : _a.isSuperAdmin), headerLogo(), percyMenu((_b = window.store) === null || _b === void 0 ? void 0 : _b.isSuperAdmin), percySession(getName(), (_d = (_c = window.store) === null || _c === void 0 ? void 0 : _c.currentUser) === null || _d === void 0 ? void 0 : _d.picture));
    return result;
};
