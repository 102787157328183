var _a, _b, _c;
import { googleAuthSignOut } from '../jux/auth';
import { error, Length, success } from '../utils/flash';
import { logError } from '../utils/logger';
import { displayPage, Page } from '../utils/navigation';
import { loadAccountPage } from './account';
import { refreshTeamName, refreshTeamSize } from './components/percy-team-list';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { firstAccessOnboard } from './onboard';
import { extractTokenFromWindowLocation, refreshTeamProfiles } from './session';
import { handleTeamMemberAdded, showOkText, updateCurrentUser } from './teams';
const backendUrl = window.location.search.includes('cypress=true')
    ? (_a = process.env.CYPRESS_BACKEND_URL) !== null && _a !== void 0 ? _a : ''
    : (_b = process.env.BACKEND_URL) !== null && _b !== void 0 ? _b : '';
const secondaryBackendUrl = (_c = process.env.STAGING_BACKEND_URL) !== null && _c !== void 0 ? _c : '';
let backendUrlToTry = backendUrl;
const apiUrl = backendUrl + 'api/';
const newGoogleAuthUrl = backendUrl + 'auth-google-new';
export const googleAuthUrl = backendUrl + 'auth-google?google-id-token=';
export const microsoftAuthUrl = backendUrl + 'auth-microsoft?access-token=';
const magicAuthReqUrl = backendUrl + 'magic-link-request?email=';
const magicAuthUrl = backendUrl + 'auth-magic?token=';
let timeout;
const maxRetries = 20;
let retries = 0;
export const backendHelpMessage = (req) => {
    return req.response && req.response.error || req.response || req;
};
const renderOverlay = () => {
    var _a;
    const overlay = document.createElement('reconnect-overlay');
    const spinner = document.createElement('percy-spinner');
    spinner.className = 'reconnect-spinner';
    (_a = document.querySelector('body')) === null || _a === void 0 ? void 0 : _a.append(overlay, spinner);
};
const removeOverlay = () => {
    var _a, _b;
    (_a = document.querySelector('reconnect-overlay')) === null || _a === void 0 ? void 0 : _a.remove();
    (_b = document.querySelector('.reconnect-spinner')) === null || _b === void 0 ? void 0 : _b.remove();
};
export const backendRequest = (requestType, url, postContent, onJsonResponse, onHelpMessage = logError) => {
    if (url.includes(backendUrl)) {
        url = url.replace(backendUrl, backendUrlToTry);
    }
    else if (url.includes(secondaryBackendUrl)) {
        url = url.replace(secondaryBackendUrl, backendUrlToTry);
    }
    clearInterval(timeout);
    const req = new XMLHttpRequest();
    req.open(requestType, url, true);
    const backendToken = window.store.backendToken;
    if (backendToken) {
        req.setRequestHeader('auth', backendToken);
    }
    req.setRequestHeader('Content-Type', 'application/json');
    req.responseType = 'json';
    req.onerror = req.ontimeout = () => {
        if (req.status === 401) {
            localStorage.removeItem('token');
            alert(getTranslation('session-expired-msg'));
            location.reload();
            googleAuthSignOut();
        }
        else if (req.status === 0) {
            if (retries >= maxRetries) {
                removeOverlay();
                displayPage(Page.DISCONNECTED);
                return;
            }
            retries++;
            if (!document.querySelector('reconnect-overlay')) {
                renderOverlay();
            }
            const backupBackendUrl = backendUrlToTry === backendUrl
                ? secondaryBackendUrl
                : backendUrl;
            url = url.replace(backendUrlToTry, backupBackendUrl);
            backendUrlToTry = backupBackendUrl;
            timeout = setTimeout(() => {
                backendRequest(requestType, url, postContent, onJsonResponse, onHelpMessage);
            }, 2000);
        }
        else {
            onHelpMessage(backendHelpMessage(req));
        }
    };
    req.onload = () => {
        retries = 0;
        removeOverlay();
        if (req.status === 200 || req.status === 202) {
            onJsonResponse(req.response);
        }
        else {
            if (req.onerror) {
                // @ts-ignore
                req.onerror();
            }
        }
    };
    req.timeout = 25000;
    req.send(postContent);
};
export const backendGet = (endpoint, onJsonResponse, onHelpMessage) => {
    let api = apiUrl + endpoint;
    if (endpoint.includes(googleAuthUrl) || endpoint.includes(microsoftAuthUrl)) {
        api = endpoint;
    }
    backendRequest('GET', api, undefined, onJsonResponse, onHelpMessage);
};
export const backendGetPromise = (endpoint) => new Promise((resolve, reject) => backendGet(endpoint, resolve, reject));
export const backendPostPromise = (endpoint, postContent) => new Promise((resolve, reject) => backendPost(endpoint, postContent, resolve, reject));
export const backendPost = (endpoint, postContent, onJsonResponse, onHelpMessage) => {
    let url = apiUrl + endpoint;
    if (endpoint === newGoogleAuthUrl) {
        url = endpoint;
    }
    backendRequest('POST', url, postContent, onJsonResponse, onHelpMessage);
};
export const requestMagicLink = (data, onJsonResponse) => {
    backendRequest('GET', magicAuthReqUrl + encodeURIComponent(data.email) + '&destination=' + extractTokenFromWindowLocation('destination') + '&token=' + data.token, undefined, onJsonResponse);
};
export const openMagicLink = (magicToken, onJsonResponse, onHelpMessage) => {
    backendRequest('GET', magicAuthUrl + magicToken, undefined, onJsonResponse, onHelpMessage);
};
export const getGoogleUserProfile = (token, onSuccess) => {
    return backendPostPromise(newGoogleAuthUrl, JSON.stringify({ token }))
        .then(onSuccess)
        .catch(logError);
};
export const getProfile = () => {
    return backendGetPromise('profile')
        .then(res => res)
        .catch(logError);
};
export const handleTeamCreation = async (name, showError = true) => {
    const newTeam = await createTeam(name, showError);
    if (!newTeam) {
        return false;
    }
    refreshTeamProfiles();
    setTimeout(() => firstAccessOnboard(), 2000);
    return true;
};
export const createTeam = (name, showError = true) => {
    const question = getTranslation('default-question');
    return backendPostPromise('create-team', JSON.stringify({ name, question }))
        .then(async (res) => {
        setSelectedTeam(res.id);
        return res;
    })
        .catch((err) => {
        if (showError) {
            logError(err);
        }
    });
};
export const deleteTeam = () => {
    const teamId = getTeamId();
    return backendPostPromise('delete-team', JSON.stringify({ 'team-id': teamId }))
        .then(res => res);
};
export const getTeams = () => {
    return backendGetPromise('teams').then(res => res);
};
export const setSelectedTeam = (id) => {
    localStorage.setItem('team-id', id.toString());
    window.store.teamId = id;
};
export const getTeamId = () => {
    if (window.store.teamId === null || window.store.teamId === undefined) {
        const id = localStorage.getItem('team-id');
        if (id === null || id === undefined || id === '') {
            return null;
        }
        window.store.teamId = Number(id);
    }
    return window.store.teamId;
};
export const getSelectedTeam = () => {
    const teamId = getTeamId();
    if (teamId === null) {
        return Promise.resolve(null);
    }
    return backendPostPromise('team-profiles2', JSON.stringify({ 'team-id': teamId }))
        .then((team) => {
        if (!team) {
            return;
        }
        window.store.selectedTeam = team;
        return team;
    })
        .catch(logError);
};
export const setStorage = async (key, value) => {
    await backendPostPromise('storage-set', JSON.stringify({ key, value }))
        .catch(logError);
};
export const getStorage = (key) => {
    return backendGetPromise(`storage-get?key=${key}`)
        .then((res) => res)
        .catch(logError);
};
export const setCnpj = async (cnpj) => {
    await backendPostPromise('set-cnpj', JSON.stringify({ cnpj }))
        .then((_res) => window.store.currentUser.cnpj = cnpj)
        .catch(logError);
};
export const getTokenFor = (admin) => {
    return backendGetPromise(`impersonate?email=${admin}`)
        .then((res) => res)
        .catch(logError);
};
export const appraise = (obj) => {
    const teamId = getTeamId();
    obj['team-id'] = teamId;
    return backendPostPromise('appraise', JSON.stringify(obj))
        .catch(logError);
};
export const refreshOrder = (orderId, sandbox, onSuccess, onError) => {
    backendPost('billing/refresh-order-status', JSON.stringify({ id: orderId, sandbox }), (_res) => {
        loadAccountPage();
        setTimeout(() => onSuccess(), 300);
    }, (err) => {
        loadAccountPage();
        logError(err);
        setTimeout(() => onError(), 300);
    });
};
// START Teams stuff
export const addMember = async (name, email, role) => {
    var _a;
    if ((_a = window.store.selectedTeam) === null || _a === void 0 ? void 0 : _a.members.find((member) => member.email === email)) {
        error(getTranslation('duplicated-user-error'), Length.long);
        return;
    }
    const teamId = getTeamId();
    const ret = await backendPostPromise('team-member-add', JSON.stringify({ 'team-id': teamId, name, email, role }))
        .then(async (team) => {
        window.store.selectedTeam = team;
        if (email === window.store.currentUser.email) {
            await updateCurrentUser();
        }
        handleTeamMemberAdded(team);
        return true;
    })
        .catch((err) => {
        logError(err);
        return false;
    });
    return ret;
};
export const updateTeamName = async () => {
    const team = window.store.selectedTeam;
    const teamId = getTeamId();
    const ret = await backendPostPromise('team-name-set', JSON.stringify({ 'team-id': teamId, name: team.name }))
        .then(async (_res) => {
        refreshTeamName(team.id, team.name);
        const prompt = document.querySelector('team-name-prompt');
        if (prompt) {
            prompt.textContent = team.name;
        }
        success(getTranslation('ok-text'), Length.short);
        return true;
    })
        .catch((err) => {
        logError(err);
        return false;
    });
    return ret;
};
export const updateMember = async (name, email, role, picture) => {
    const teamId = getTeamId();
    await backendPostPromise('team-member-update', JSON.stringify({ 'team-id': teamId, name, email, role, picture }))
        .then(async (team) => {
        window.store.selectedTeam = team;
        if (email === window.store.currentUser.email) {
            await updateCurrentUser();
        }
        showOkText(team);
    })
        .catch(logError);
};
export const deleteTeamMemberAction = async () => {
    const modal = document.querySelector('percy-modal');
    const emailToDelete = (modal === null || modal === void 0 ? void 0 : modal.querySelector('#member-id-modal')).value;
    const name = (modal === null || modal === void 0 ? void 0 : modal.querySelector('#member-name-modal')).value;
    const teamId = getTeamId();
    await backendPostPromise('team-member-remove', JSON.stringify({ 'team-id': teamId, name, email: emailToDelete }))
        .then((team) => {
        window.store.selectedTeam = team;
        refreshTeamSize(team.id, team.members.length);
        showOkText(team);
    })
        .catch(logError);
};
// END Teams stuff
export const getMXData = async (domainName) => {
    const records = await fetch(`https://dns.google.com/resolve?name=${domainName}&type=MX`, {
        method: 'GET',
        mode: 'cors'
    });
    const recordsJson = await records.json();
    const answer = recordsJson.Answer;
    if (!answer) {
        return null;
    }
    const data = Array.from(answer).map((a) => a.data);
    return data;
};
