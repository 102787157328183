import { handleTeamCreation } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
export const createTeamButton = () => {
    const result = document.createElement('create-team-button');
    const addTeamBtn = document.createElement('button');
    addTeamBtn.setAttribute('class', 'primary-button darker-button');
    addTeamBtn.onclick = () => handleTeamCreation(getTranslation('first-team-name'), false);
    const btnIcon = document.createElement('img');
    btnIcon.className = 'btn-icon';
    btnIcon.src = './images/add-member.svg';
    const addTeamText = document.createElement('add-team');
    addTeamText.textContent = getTranslation('create-team');
    addTeamBtn.append(btnIcon, addTeamText);
    result.append(addTeamBtn);
    return result;
};
