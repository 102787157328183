import { formatToDate } from '../../utils/date';
import { error, Length, success, warning } from '../../utils/flash';
import { logError } from '../../utils/logger';
import { singularOrPluralString, currencyFormatter } from '../../utils/string';
import { loadAccountPage } from '../account';
import { backendPost, refreshOrder } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { percyModal } from './percy-modal';
let initialStatus = '';
let currentStatus = '';
const closeModal = (result) => {
    result.remove();
    if (currentStatus !== initialStatus) {
        loadAccountPage();
    }
};
const showSpinner = (result) => {
    const popupSpinner = document.createElement('percy-spinner');
    popupSpinner.id = 'popup-spinner';
    popupSpinner.className = 'pop-up-spinner';
    result.appendChild(popupSpinner);
};
const hideSpinner = () => {
    var _a;
    (_a = document.querySelector('#popup-spinner')) === null || _a === void 0 ? void 0 : _a.remove();
};
const append = (body, result) => {
    const modal = percyModal(getTranslation('order-details'), [], '', false, false, body, '', '', () => closeModal(result));
    result.append(modal);
};
export const orderDetailsModal = (order) => {
    const result = document.createElement('order-details-modal');
    const { 'external-update-time': externalUpdateTime, timestamp, type, credits, 'external-id': id, status, obs, sandbox, currency, value } = order;
    initialStatus = status;
    currentStatus = status;
    const descriptionWithoutEmail = obs ? obs.split('(')[0] : '';
    const body = document.createElement('modal-body');
    const orderBody = document.createElement('order-body');
    const orderIdRow = document.createElement('order-id-row');
    orderIdRow.className = 'order-details-row';
    const orderIdText = document.createElement('order-id-text');
    orderIdText.textContent = getTranslation('order-id') + ':';
    orderIdText.className = 'order-row-title';
    const orderIdValue = document.createElement('order-id-value');
    orderIdValue.textContent = id;
    orderIdRow.append(orderIdText, orderIdValue);
    const timestampRow = document.createElement('timestamp-row');
    timestampRow.className = 'order-details-row';
    const timestampText = document.createElement('timestamp-text');
    timestampText.textContent = getTranslation('date') + ':';
    timestampText.className = 'order-row-title';
    const timestampValue = document.createElement('timestamp-value');
    timestampValue.textContent = formatToDate(externalUpdateTime || timestamp);
    timestampRow.append(timestampText, timestampValue);
    const descriptionRow = document.createElement('description-row');
    descriptionRow.className = 'order-details-row';
    const descriptionText = document.createElement('description-text');
    descriptionText.textContent = getTranslation('description') + ':';
    descriptionText.className = 'order-row-title';
    const descriptionValue = document.createElement('description-value');
    descriptionValue.textContent = obs ? descriptionWithoutEmail : getTranslation(type);
    descriptionRow.append(descriptionText, descriptionValue);
    const amountRow = document.createElement('amount-row');
    amountRow.className = 'order-details-row';
    const amountText = document.createElement('amount-text');
    amountText.textContent = getTranslation('amount') + ':';
    amountText.className = 'order-row-title';
    const amountValue = document.createElement('amount-value');
    amountValue.textContent = singularOrPluralString(Number(credits), 'licenses');
    amountRow.append(amountText, amountValue);
    const priceRow = document.createElement('price-row');
    priceRow.className = 'order-details-row';
    const priceText = document.createElement('price-text');
    priceText.textContent = getTranslation('price') + ':';
    priceText.className = 'order-row-title';
    const priceValue = document.createElement('price-value');
    priceValue.textContent = currencyFormatter(currency, value);
    priceRow.append(priceText, priceValue);
    const statusRow = document.createElement('status-row');
    statusRow.className = 'order-details-row';
    const statusText = document.createElement('status-text');
    statusText.textContent = getTranslation('status') + ':';
    statusText.className = 'order-row-title';
    const statusValue = document.createElement('status-value');
    statusValue.textContent = getTranslation(status);
    statusValue.setAttribute('class', `status status-${status}`);
    statusRow.append(statusText, statusValue);
    if (!id) {
        orderBody.append(timestampRow, descriptionRow, amountRow, priceRow, statusRow);
        body.appendChild(orderBody);
        append(body, result);
        return result;
    }
    orderBody.append(orderIdRow, timestampRow, descriptionRow, amountRow, priceRow, statusRow);
    body.appendChild(orderBody);
    append(body, result);
    if (status !== 'pending') {
        return result;
    }
    showSpinner(result);
    backendPost('billing/refresh-order-status', JSON.stringify({ id, sandbox: sandbox === true }), (res) => {
        hideSpinner();
        currentStatus = res;
        statusValue.textContent = getTranslation(currentStatus);
        statusValue.setAttribute('class', `status status-${currentStatus}`);
        if (currentStatus !== 'pending') {
            return result;
        }
        const spinner = document.createElement('percy-spinner');
        spinner.className = 'approval-link-spinner';
        body.appendChild(spinner);
        const paypalBuyNow = document.createElement('paypal-buy-now');
        paypalBuyNow.style.visibility = 'hidden';
        body.appendChild(paypalBuyNow);
        backendPost('billing/approval-link', JSON.stringify({ 'external-id': id, sandbox }), (resultUrl) => {
            const paypalLink = document.createElement('a');
            paypalLink.title = getTranslation('finish-purchase');
            paypalLink.onclick = () => continuePaypalPayment(resultUrl);
            const paypalBtnImg = document.createElement('img');
            paypalBtnImg.src = 'https://www.paypalobjects.com/webstatic/en_AU/i/buttons/btn_paywith_primary_m.png';
            paypalBtnImg.alt = getTranslation('pay-with-paypal');
            paypalLink.appendChild(paypalBtnImg);
            paypalBuyNow.appendChild(paypalLink);
            const continuePaypalPayment = (url) => {
                const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
                const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
                const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
                const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
                const systemZoom = width / window.screen.availWidth;
                const left = (width - 1060) / 2 / systemZoom + dualScreenLeft;
                const top = (height - 700) / 2 / systemZoom + dualScreenTop;
                const newWindow = window.open(url, 'WIPaypal', `
                toolbar=no,
                location=no,
                directories=no,
                status=no,
                menubar=no,
                scrollbars=yes,
                resizable=yes,
                width=${1060 / systemZoom},
                height=${700 / systemZoom},
                top=${top},
                left=${left}
                `);
                newWindow === null || newWindow === void 0 ? void 0 : newWindow.focus();
                showSpinner(result);
                const timer = setInterval(() => {
                    if (!(newWindow === null || newWindow === void 0 ? void 0 : newWindow.closed)) {
                        return result;
                    }
                    clearInterval(timer);
                    const paymentStatus = localStorage.getItem('paypal-payment');
                    if (paymentStatus === 'done') {
                        refreshOrder(id, sandbox, () => success(getTranslation('transaction-completed'), Length.long), () => error(getTranslation('paypal-error-msg'), Length.long));
                        localStorage.removeItem('paypal-payment');
                        return result;
                    }
                    hideSpinner();
                    warning(getTranslation('order-canceled'), Length.long);
                    return result;
                }, 500);
            };
            spinner.remove();
            paypalBuyNow.style.visibility = 'visible';
        }, logError);
        return result;
    }, logError);
    return result;
};
