import { reportError } from '../../utils/logger';
export const profilePicture = (src = 'images/avatar.png', id = '') => {
    const result = document.createElement('profile-picture');
    const img = document.createElement('img');
    img.src = src;
    img.alt = '';
    if (id) {
        img.id = id;
    }
    img.setAttribute('class', 'card-pic');
    img.draggable = false;
    img.referrerPolicy = 'no-referrer';
    img.addEventListener('error', (err) => {
        reportError(err, 'ProfilePicture falhou ao carregar imagem src => ' + img.src);
        img.src = 'images/avatar.png';
    });
    result.appendChild(img);
    return result;
};
