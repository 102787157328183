import { getTranslation } from '@klauswuestefeld/jux-web';
export const actionBtn = (btnClass, src, text, format = '.svg') => {
    const result = document.createElement('action-btn');
    const btn = document.createElement('button');
    btn.setAttribute('class', btnClass);
    const icon = document.createElement('img');
    icon.className = format === '.svg' ? 'btn-icon' : 'btn-icon png-icon';
    icon.src = `./images/${src}${format}`;
    icon.alt = '';
    const textEl = document.createElement(text);
    textEl.textContent = getTranslation(text);
    btn.addEventListener('click', () => {
        result.dispatchEvent(new CustomEvent('action'));
    });
    btn.append(icon, textEl);
    result.appendChild(btn);
    return result;
};
