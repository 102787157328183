import { getTranslation } from '@klauswuestefeld/jux-web';
export const percyFlash = (type, msg) => {
    var _a;
    const result = document.createElement('percy-flash');
    const flashContainer = document.createElement('flash-container');
    flashContainer.setAttribute('class', type !== null && type !== void 0 ? type : 'info');
    const closeFlash = document.createElement('close-flash');
    closeFlash.setAttribute('class', 'closebtn');
    closeFlash.innerHTML = '&times;';
    closeFlash.addEventListener('click', (_e) => result.remove());
    const flashTitle = document.createElement('strong');
    flashTitle.textContent = getTranslation(type) + ': ';
    flashContainer.append(closeFlash, flashTitle, (_a = getTranslation(msg)) !== null && _a !== void 0 ? _a : type);
    result.appendChild(flashContainer);
    return result;
};
