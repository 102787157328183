import { Length, success } from '../../utils/flash';
import { logError } from '../../utils/logger';
import { backendPostPromise, getTeamId } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { percyModal, toggleBusyStatus } from './percy-modal';
import { onTeamUpdate } from '../teams';
const updateQuestionAction = async (newQuestion) => {
    const prompt = document.querySelector('appraisal-prompt');
    const question = newQuestion || getTranslation('default-question');
    const teamId = getTeamId();
    await backendPostPromise('update-question', JSON.stringify({ 'team-id': teamId, question }))
        .then((team) => {
        window.store.selectedTeam = team;
        success(getTranslation('ok-text'), Length.short);
        prompt.textContent = question;
    })
        .catch(logError);
};
const updateQuestionDetailsAction = async (details) => {
    const teamId = getTeamId();
    await backendPostPromise('update-question-details', JSON.stringify({ 'team-id': teamId, 'question-details': details }))
        .then((team) => {
        window.store.selectedTeam = team;
        onTeamUpdate(team);
        success(getTranslation('ok-text'), Length.short);
    })
        .catch(logError);
};
const updateQuestionModal = (currentQuestion) => {
    var _a;
    const textarea = document.createElement('textarea');
    textarea.id = 'question-textarea';
    textarea.className = 'question-text-area';
    textarea.maxLength = 150;
    textarea.placeholder = getTranslation('question-textarea-placeholder');
    textarea.textContent = currentQuestion;
    textarea.setAttribute('aria-label', 'question-textarea-aria-label');
    const modal = percyModal(getTranslation('update-question-title'), ['ok', 'cancel'], '', false, false, textarea, getTranslation('update'));
    modal.id = 'update-question-modal';
    modal.setAttribute('data-cy', 'update-question-modal');
    modal.addEventListener('ok', async () => {
        toggleBusyStatus();
        if (currentQuestion === textarea.value) {
            modal.remove();
            return;
        }
        await updateQuestionAction(textarea.value);
        toggleBusyStatus();
        modal.remove();
    });
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(modal);
    textarea.focus();
    textarea.select();
};
const appendQuestionDetailsModal = (text, readOnly) => {
    var _a;
    const textarea = document.createElement('textarea');
    textarea.rows = 14;
    textarea.maxLength = 2000;
    textarea.className = 'question-text-area';
    textarea.id = 'question-details-textarea';
    textarea.placeholder = getTranslation('appraisal-question-details-placeholder');
    if (text) {
        textarea.textContent = text;
    }
    const btnsArray = ['cancel'];
    if (!readOnly) {
        btnsArray.push('ok');
    }
    const closeButtonTxt = readOnly ? 'close' : 'alert-cancel-btn-txt';
    const modal = percyModal(getTranslation('appraisal-question-details'), btnsArray, '', false, false, textarea, getTranslation('update'), getTranslation(closeButtonTxt));
    if (readOnly) {
        textarea.disabled = true;
    }
    else {
        modal.addEventListener('ok', async () => {
            toggleBusyStatus();
            if (text === textarea.value) {
                modal.remove();
                return;
            }
            await updateQuestionDetailsAction(textarea.value);
            toggleBusyStatus();
            modal.remove();
        });
    }
    modal.id = 'question-details-modal';
    modal.setAttribute('data-cy', 'question-details-modal');
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(modal);
    textarea.focus();
    return modal;
};
export const questionDetails = (text, readOnly) => {
    const result = document.createElement('a');
    result.className = 'question-details-anchor';
    result.textContent = text ? getTranslation('details') : getTranslation('add-details');
    result.addEventListener('click', () => appendQuestionDetailsModal(text, readOnly));
    return result;
};
export const adminQuestion = (question = getTranslation('default-question'), details, isAdmin) => {
    const result = document.createElement('admin-question');
    const title = document.createElement('appraisal-prompt-title');
    title.textContent = getTranslation('appraisal-prompt-title');
    title.className = 'section-title';
    const prompt = document.createElement('appraisal-prompt');
    prompt.textContent = question;
    const detailsLink = questionDetails(details, !isAdmin);
    if (!isAdmin) {
        result.append(title, prompt);
        if (details)
            result.append(detailsLink);
        return result;
    }
    const img = document.createElement('img');
    img.className = 'btn-icon';
    img.src = './images/edit-appraisal-green.svg';
    img.alt = '';
    img.setAttribute('aria-label', getTranslation('edit-question'));
    prompt.addEventListener('click', () => updateQuestionModal(prompt.textContent));
    img.addEventListener('click', () => updateQuestionModal(prompt.textContent));
    result.append(title, img, prompt, detailsLink);
    return result;
};
