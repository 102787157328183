import { getTranslation } from '@klauswuestefeld/jux-web';
import { handleSignOut } from '../session';
import { contactUsModal } from './contact-us-modal';
export const sessionDropdown = () => {
    const result = document.createElement('session-dropdown');
    const buttons = [
        { id: 'privacy-policy', fn: () => window.open(getTranslation('privacy-policy-url')) },
        { id: 'cookies-policy', fn: () => window.open(getTranslation('cookies-policy-url')) },
        { id: 'contact-page', fn: () => { var _a; return (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(contactUsModal('contact-page')); } },
        { id: 'logout', fn: handleSignOut },
    ];
    for (const button of buttons) {
        const newButton = document.createElement('button');
        newButton.id = button.id;
        newButton.addEventListener('click', button.fn);
        newButton.textContent = getTranslation(button.id);
        result.appendChild(newButton);
    }
    return result;
};
