import { getTranslation } from '@klauswuestefeld/jux-web';
export const juxButton = (id, className, fn) => {
    const result = document.createElement('button');
    result.id = id;
    result.className = className;
    result.addEventListener('click', fn);
    const txt = document.createElement('button-text');
    txt.textContent = getTranslation(id + '-txt');
    const spinner = document.createElement('percy-spinner');
    result.append(txt, spinner);
    return result;
};
