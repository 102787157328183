import { logError } from '../../utils/logger';
import { appraise } from '../api-client';
import { moveCurrentAppraisalToMembersPool } from '../appraisals';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { appraisalCard } from './appraisal-card';
const refreshCurrentAppraisalCertainty = (appraisal) => {
    if (window.store.currentAppraisal) {
        window.store.currentAppraisal.certainty = appraisal.certainty;
    }
    const cardId = 'card-' + appraisal.email;
    const card = document.getElementById(cardId);
    const parent = card === null || card === void 0 ? void 0 : card.parentElement;
    if (!parent) {
        logError('certainty-button > refreshCurrentAppraisalCertainty > parent não encontrado', 'generic-help-msg');
        return;
    }
    const children = Array.from(parent.childNodes);
    const cardIndex = children.indexOf(card);
    parent.insertBefore(appraisalCard(cardId, appraisal), parent.children[cardIndex]);
    card.remove();
};
export const certaintyClicked = async (certainty, appraisal) => {
    var _a;
    appraisal.certainty = certainty;
    const appraiseId = appraisal.email;
    let appraiseLevel = appraisal.level;
    if (certainty === 0) {
        appraiseLevel = 0;
    }
    await appraise({
        certainty,
        appraised: appraiseId,
        level: Number(appraiseLevel)
    });
    // TODO members-pool sendo renderizado novamente em momento desnecessário
    if (certainty !== 0) {
        const membersPool = document.querySelector('members-pool');
        membersPool.appraisals = membersPool.appraisals.filter(app => app.email !== appraiseId);
    }
    if (certainty === 0) {
        moveCurrentAppraisalToMembersPool(appraisal);
    }
    (_a = document.querySelector('certainty-modal')) === null || _a === void 0 ? void 0 : _a.remove();
    appraisal.certainty = certainty;
    refreshCurrentAppraisalCertainty(appraisal);
};
export const certaintyButton = (certainty, appraisal) => {
    const result = document.createElement('certainty-button');
    const btn = document.createElement('button');
    const certGiven = appraisal.certainty ? appraisal.certainty : 0;
    btn.id = `certainty-${certainty}`;
    if (certGiven >= Number(certainty)) {
        btn.classList.add('active');
    }
    btn.onclick = () => certaintyClicked(Number(certainty), appraisal);
    btn.textContent = certainty === '1.0' ? '1' : certainty;
    const caption = document.createElement('certainty-description');
    caption.textContent = getTranslation(btn.id);
    result.append(caption, btn);
    return result;
};
