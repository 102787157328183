import { Length, warning } from '../../utils/flash';
import { cnpjFormatter, cpfFormatter, validateCnpjInput } from '../../utils/string';
import { setCnpj } from '../api-client';
import { countryDropdown } from '../components/country-dropdown';
import { licensesHistory } from '../components/licenses-history';
import { licensesSection } from '../components/licenses-section';
import { getTranslation } from '@klauswuestefeld/jux-web';
const formatInput = (data) => {
    const cleanedData = data.replace(/\D/g, '');
    if (cleanedData.length > 11) {
        return cnpjFormatter(data);
    }
    else {
        return cpfFormatter(data);
    }
};
const cnpjInput = () => {
    const result = document.createElement('input');
    result.id = 'cnpj-cpf';
    result.name = 'cnpj-cpf';
    result.type = 'text';
    result.className = 'textfield';
    result.value = window.store.currentUser.cnpj || '';
    result.addEventListener('keyup', (ev) => {
        result.value = formatInput(ev.target.value);
    });
    result.addEventListener('blur', async (ev) => {
        var _a;
        const input = ev.target;
        const newCnpj = input.value;
        const isCnpjChanged = newCnpj !== ((_a = window.store.currentUser) === null || _a === void 0 ? void 0 : _a.cnpj);
        if (newCnpj) {
            if (!validateCnpjInput(newCnpj)) {
                warning(getTranslation('invalid-cnpj'), Length.long);
            }
            else if (isCnpjChanged) {
                await setCnpj(newCnpj);
            }
        }
    });
    return result;
};
const renderBillingSection = (infoSection) => {
    var _a, _b;
    (_a = infoSection === null || infoSection === void 0 ? void 0 : infoSection.querySelector('user-billing-info')) === null || _a === void 0 ? void 0 : _a.remove();
    if (((_b = window.store) === null || _b === void 0 ? void 0 : _b.currentUser['paypal-country-code']) !== 'BR') {
        return;
    }
    const billingInfo = document.createElement('user-billing-info');
    billingInfo.className = 'account-field';
    const label = document.createElement('label');
    label.textContent = 'CNPJ/CPF*: ';
    label.htmlFor = 'cnpj-cpf';
    billingInfo.append(label, cnpjInput());
    infoSection === null || infoSection === void 0 ? void 0 : infoSection.appendChild(billingInfo);
};
export const accountPage = () => {
    const result = document.createElement('account-page');
    result.className = 'page-container';
    // Details Section
    const detailsSection = document.createElement('section');
    detailsSection.classList.add('account-section', 'account-details');
    const accountDetails = document.createElement('account-title');
    accountDetails.className = 'section-title';
    accountDetails.textContent = getTranslation('account-title');
    detailsSection.appendChild(accountDetails);
    // Info Section
    const infoSection = document.createElement('section');
    infoSection.className = 'account-section';
    infoSection.id = 'info-section';
    const email = document.createElement('user-email');
    email.className = 'account-field';
    const emailLabel = document.createElement('label');
    emailLabel.textContent = 'Email: ';
    const emailField = document.createElement('email-field');
    emailField.textContent = window.store.currentUser.email;
    email.append(emailLabel, emailField);
    const country = document.createElement('user-country');
    country.className = 'account-field';
    const countryLabel = document.createElement('label');
    countryLabel.htmlFor = 'paywall-country';
    countryLabel.textContent = getTranslation('country-label');
    const countryInput = countryDropdown();
    countryInput.addEventListener('action', () => renderBillingSection(infoSection));
    country.append(countryLabel, countryInput);
    infoSection.append(email, country);
    // Paywall Section
    result.append(detailsSection, infoSection, licensesSection());
    renderBillingSection(infoSection);
    // History Section
    const historySection = document.createElement('licenses-history-container');
    historySection.appendChild(licensesHistory());
    result.appendChild(historySection);
    return result;
};
