import { logError } from '../../utils/logger';
import { singularOrPluralString } from '../../utils/string';
import { backendGet, getSelectedTeam, setSelectedTeam } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { onTeamUpdate } from '../teams';
import { createTeamButton } from './create-team-button';
import { newTeamButton } from './new-team-button';
import { percySpacer } from './percy-spacer';
import { showSpinner } from './percy-spinner';
import { deleteTeamButton } from './delete-team-button';
export const refreshTeamName = (id, name) => {
    const el = document.querySelector('percy-team-list');
    const target = el.querySelector(`[id='row-${id}'] team-name`);
    target.textContent = name;
};
export const refreshTeamSize = (id, size) => {
    const el = document.querySelector('percy-team-list');
    const target = el === null || el === void 0 ? void 0 : el.querySelector(`[id='row-${id}'] team-size`);
    if (target) {
        target.textContent = singularOrPluralString(size, 'members').replace('.', '');
    }
};
const handleTeamSelection = async (selectedTeam, radio) => {
    if (window.store.teamId === selectedTeam.id)
        return;
    let isRequestDone = false;
    setTimeout(() => {
        if (!isRequestDone) {
            showSpinner(document.querySelector('percy-team'));
        }
    }, 2000);
    setSelectedTeam(selectedTeam.id);
    const team = await getSelectedTeam();
    isRequestDone = true;
    window.store.selectedTeam = team;
    onTeamUpdate(team);
    radio.checked = true;
};
const teamList = (teams) => {
    const selectedTeam = window.store.selectedTeam;
    const result = document.createElement('teams-list');
    teams.forEach((team) => {
        const row = document.createElement('team-info');
        row.id = 'row-' + team.id;
        const radio = document.createElement('input');
        radio.type = 'radio';
        radio.id = team.admin + '-radio-' + team.id;
        radio.name = 'team-admin';
        if ((selectedTeam === null || selectedTeam === void 0 ? void 0 : selectedTeam.id) === team.id) {
            radio.checked = true;
        }
        row.onclick = () => handleTeamSelection(team, radio);
        const label = document.createElement('label');
        const name = document.createElement('team-name');
        name.textContent = team.name;
        label.htmlFor = radio.id;
        label.appendChild(name);
        const admin = document.createElement('team-admin');
        admin.textContent = getTranslation('creator') + team.admin;
        const size = document.createElement('team-size');
        size.textContent = singularOrPluralString(team.size, 'members').replace('.', '');
        row.append(radio, label, admin, size);
        result.appendChild(row);
    });
    return result;
};
export const percyTeamList = () => {
    const chosenTeam = window.store.selectedTeam;
    const result = document.createElement('percy-team-list');
    result.style.display = 'flex';
    result.style.flexFlow = 'column';
    let selectedTeam = chosenTeam || { 'is-admin': false, 'admin': null, 'members': [] };
    selectedTeam = selectedTeam['is-admin']
        ? Object.assign(Object.assign({}, selectedTeam), { admin: window.store.currentUser.email }) : selectedTeam;
    result.setAttribute('selected-team', JSON.stringify(selectedTeam));
    backendGet('teams', (res) => {
        var _a, _b;
        window.store.teams = res;
        const teams = res;
        const sortedTeamList = teams.sort((a, b) => a.admin.localeCompare(b.admin) || a.name.localeCompare(b.name));
        if (!(teams === null || teams === void 0 ? void 0 : teams.length)) {
            const blank = document.createElement('blank-teams-state');
            blank.style.display = 'block';
            blank.innerHTML = getTranslation('logged-as') + `<span>${window.store.currentUser.email}</span><br>` + getTranslation('no-teams');
            result.append(blank, createTeamButton());
            return result;
        }
        const percyTeamsTitle = document.createElement('percy-teams-title');
        percyTeamsTitle.className = 'section-title';
        percyTeamsTitle.textContent = getTranslation('your-teams');
        const buttonsContainer = document.createElement('buttons-container');
        buttonsContainer.appendChild(newTeamButton());
        if (((_a = window.store.selectedTeam) === null || _a === void 0 ? void 0 : _a.creator) === window.store.currentUser.email) {
            buttonsContainer.appendChild(deleteTeamButton());
        }
        result.append(percyTeamsTitle, teamList(sortedTeamList), percySpacer({ vertical: 8 }), buttonsContainer);
        const row = result.querySelector(`#row-${(_b = window.store.selectedTeam) === null || _b === void 0 ? void 0 : _b.id}`);
        row === null || row === void 0 ? void 0 : row.scrollIntoView({ behavior: 'smooth' });
        if (teams.some((team) => team.admin === window.store.currentUser.email)) {
            return result;
        }
        return result;
    }, logError);
    return result;
};
