import { getTranslation } from '@klauswuestefeld/jux-web';
export const disconnectedPage = () => {
    const result = document.createElement('disconnected-page');
    const container = document.createElement('percy-container');
    const frown = document.createElement('b');
    frown.innerText = ':(';
    frown.style.fontSize = '64px';
    const br = document.createElement('br');
    const msg = document.createElement('generic-help-msg');
    msg.textContent = getTranslation('generic-help-msg');
    result.style.backgroundImage = "url('/images/percival-login.jpg')";
    container.append(frown, br, msg);
    result.append(container);
    return result;
};
