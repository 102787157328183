import { moveCurrentAppraisalToMembersPool } from '../appraisals';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { cancelAppraisalButton } from './cancel-appraisal-button';
import { certaintyButtons } from './certainty-buttons';
import { percyModal } from './percy-modal';
import { profilePicture } from './profile-picture';
const closeCertaintyModal = (el, appraisal) => {
    el.remove();
    if (appraisal.certainty === 0) {
        moveCurrentAppraisalToMembersPool(appraisal);
    }
};
export const certaintyModal = (appraisal) => {
    var _a, _b;
    const result = document.createElement('certainty-modal');
    const body = document.createElement('modal-body');
    const img = profilePicture(appraisal.picture, 'certainty-pic');
    const certaintyHeader = document.createElement('certainty-header');
    const memberName = document.createElement('member-name');
    memberName.className = 'certainty-name';
    memberName.textContent = (_a = appraisal.name) !== null && _a !== void 0 ? _a : '';
    const memberEmail = document.createElement('member-email');
    memberEmail.className = 'certainty-email';
    memberEmail.textContent = (_b = appraisal.email) !== null && _b !== void 0 ? _b : '';
    certaintyHeader.append(memberName, memberEmail);
    const certaintyQuestion = document.createElement('certainty-question');
    certaintyQuestion.textContent = getTranslation('certainty-question');
    const certaintyContainer = document.createElement('certainty-container');
    certaintyContainer.append(certaintyButtons(appraisal), cancelAppraisalButton(appraisal));
    body.append(img, certaintyHeader, certaintyQuestion, certaintyContainer);
    const modal = percyModal('', [], '', false, false, body, '', '', () => closeCertaintyModal(result, appraisal));
    result.appendChild(modal);
    return result;
};
