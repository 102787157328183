import { equalsIgnoreOrder } from '../utils/arrays';
import { Length, success } from '../utils/flash';
import { displayPage, Page } from '../utils/navigation';
import { addMember, getProfile, updateMember } from './api-client';
import { editMembersModal, getRole, ModalMode } from './components/edit-members-modal';
import { percyTeam } from './components/percy-team';
import { refreshTeamSize } from './components/percy-team-list';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { refreshCurrentUserHeader } from './session';
import { deleteTeamButton } from './components/delete-team-button';
const refreshTeamMembers = (team) => {
    var _a;
    if (!window.store.selectedTeam) {
        return;
    }
    window.store.selectedTeam.members = (_a = team.members) !== null && _a !== void 0 ? _a : [];
};
export const showOkText = async (result) => {
    onTeamUpdate(result);
    success(getTranslation('ok-text'), Length.short);
};
export const handleTeamMemberAdded = (result) => {
    onTeamUpdate(result);
    refreshTeamSize(result.id, result.members.length);
    // START - clearTeamMemberModal
    const modal = document.querySelector('percy-modal');
    if (modal) {
        modal.querySelector('#admin-checkbox').checked = false;
        modal.querySelector('#appraiser-checkbox').checked = true;
        modal.querySelector('#appraised-checkbox').checked = true;
        modal.querySelector('#member-email-modal').value = '';
        modal.querySelector('#member-id-modal').value = '';
        modal.querySelector('#member-name-modal').value = '';
        modal.querySelector('#member-name-modal').focus();
    }
    // END - clearTeamMemberModal
    success(getTranslation('modal-ok-text'), Length.long);
};
export const updateCurrentUser = async () => {
    const { name, picture } = await getProfile();
    window.store.currentUser.name = name;
    window.store.currentUser.picture = picture;
};
export const saveTeamMemberAction = async (previousName = '', previousRole = ['appraiser', 'appraised'], previousPicture = 'images/avatar.png') => {
    const modal = document.querySelector('percy-modal');
    const appraiser = modal === null || modal === void 0 ? void 0 : modal.querySelector('#appraiser-checkbox');
    const appraised = modal === null || modal === void 0 ? void 0 : modal.querySelector('#appraised-checkbox');
    const subadmin = modal === null || modal === void 0 ? void 0 : modal.querySelector('#admin-checkbox');
    const role = getRole(appraiser, appraised, subadmin);
    const name = (modal === null || modal === void 0 ? void 0 : modal.querySelector('#member-name-modal')).value;
    const id = (modal === null || modal === void 0 ? void 0 : modal.querySelector('#member-id-modal')).value;
    const email = (modal === null || modal === void 0 ? void 0 : modal.querySelector('#member-email-modal')).value;
    const getPicture = () => {
        const imgElement = modal === null || modal === void 0 ? void 0 : modal.querySelector('user-picture img');
        if (imgElement) {
            return imgElement.src;
        }
        return null;
    };
    const picture = getPicture() || 'images/avatar.png';
    if (id !== '' && (name !== previousName || !equalsIgnoreOrder(role, previousRole) || picture !== previousPicture)) {
        await updateMember(name, id, role, picture);
    }
    else if (id === '') {
        await addMember(name, email, role);
    }
};
export const showTeamMemberModal = (email, isTeamAdmin) => {
    var _a, _b;
    if (!isTeamAdmin) {
        return;
    }
    let teamMember;
    if (email) {
        teamMember = (_a = window.store.selectedTeam) === null || _a === void 0 ? void 0 : _a.members.find((member) => member.email === email);
    }
    const modal = editMembersModal(teamMember ? ModalMode.EDIT : ModalMode.ADD, teamMember);
    (_b = document.querySelector('percy-body')) === null || _b === void 0 ? void 0 : _b.appendChild(modal);
    modal.querySelector('#member-name-modal').focus();
};
export const onTeamUpdate = (team) => {
    var _a, _b, _c;
    (_a = document.querySelector('percy-team')) === null || _a === void 0 ? void 0 : _a.remove();
    (_b = document.querySelector('teams-section')) === null || _b === void 0 ? void 0 : _b.appendChild(percyTeam(true));
    (_c = document.querySelector('delete-team-button')) === null || _c === void 0 ? void 0 : _c.remove();
    if (window.store.currentUser.email === team.creator) {
        const container = document.querySelector('percy-team-list buttons-container');
        container === null || container === void 0 ? void 0 : container.appendChild(deleteTeamButton());
    }
    refreshTeamMembers(team);
    refreshCurrentUserHeader();
    window.store.currentUserIsAdmin = team['is-admin'];
};
export const loadTeamsPage = () => {
    displayPage(Page.TEAMS);
};
