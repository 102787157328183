const certaintyIcon = (isActive, width) => {
    const result = document.createElement('certainty-icon');
    result.className = isActive ? 'active' : '';
    result.style.width = width;
    return result;
};
export const certaintyLevel = (certainty) => {
    const result = document.createElement('certainty-level');
    result.append(certaintyIcon(certainty >= 0.1, '12px'), certaintyIcon(certainty >= 0.3, '15px'), certaintyIcon(certainty >= 0.6, '21px'), certaintyIcon(certainty >= 1.0, '28px'));
    return result;
};
