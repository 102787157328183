import { PERCIVAL_MEDIUM_GREEN, PERCIVAL_WHITE } from '../../utils/constants';
import { Position } from '../../utils/enums';
import { getTranslation } from '@klauswuestefeld/jux-web';
const getPosition = (position) => {
    switch (position) {
        case Position.TOP:
            return 'top-floating';
        case Position.BOTTOM:
            return 'bottom-floating';
        case Position.LEFT:
            return 'left-floating';
        case Position.RIGHT:
            return 'right-floating';
        case Position.BOTTOM_LEFT:
            return 'bottom-left-floating';
        case Position.BOTTOM_RIGHT:
            return 'bottom-right-floating';
        case Position.TOP_LEFT:
            return 'top-left-floating';
        case Position.TOP_RIGHT:
            return 'top-right-floating';
        default:
            return 'bottom-right-floating';
    }
};
export const percyFloatingButton = ({ text = 'click-me', icon = '/images/search.svg', color = PERCIVAL_MEDIUM_GREEN, textColor = PERCIVAL_WHITE, position = Position.BOTTOM_RIGHT }) => {
    const result = document.createElement('percy-floating-button');
    const button = document.createElement('button');
    button.style.backgroundColor = color;
    button.style.color = textColor;
    button.onclick = () => result.dispatchEvent(new CustomEvent('action'));
    const buttonText = document.createElement('button-text');
    buttonText.textContent = getTranslation(text);
    const img = document.createElement('img');
    img.width = 12;
    img.height = 12;
    img.alt = '';
    img.src = icon;
    button.append(img, buttonText);
    result.classList.add(getPosition(position));
    result.appendChild(button);
    return result;
};
