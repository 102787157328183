import { THRESHOLD_WIDTH } from '../../utils/constants';
import { Visibility } from '../../utils/enums';
import { logError } from '../../utils/logger';
import { displayPage, Page } from '../../utils/navigation';
import { backendPostPromise, getTeamId } from '../api-client';
import { appraisalLevels } from '../components/appraisal-levels';
import { appraisalSubheader } from '../components/appraisal-subheader';
import { MembersPool } from '../components/MembersPool';
import { percyFloatingButton } from '../components/percy-floating-button';
import { firstAccessOnboard } from '../onboard';
const appraised = () => window.store.allAppraisals.filter((appraisal) => appraisal.certainty !== 0);
const notAppraised = () => window.store.allAppraisals.filter((appraisal) => appraisal.certainty === 0);
const isSideBarVisible = (el) => el.visibility === Visibility.VISIBLE;
const toggleSidebar = (result) => {
    var _a;
    const sidebar = result.querySelector('members-pool');
    sidebar.visibility = isSideBarVisible(sidebar) ? Visibility.HIDDEN : Visibility.VISIBLE;
    result.style.gridTemplateColumns = isSideBarVisible(sidebar) ? '1.2fr 1fr' : '1fr';
    (_a = result.querySelector('percy-floating-button')) === null || _a === void 0 ? void 0 : _a.remove();
    const floatingBtn = percyFloatingButton({
        text: isSideBarVisible(sidebar) ? 'close' : 'search',
        icon: isSideBarVisible(sidebar) ? '/images/x-button-white.svg' : '/images/search.svg',
    });
    floatingBtn.addEventListener('action', () => toggleSidebar(result));
    result.appendChild(floatingBtn);
};
export const appraisalsPage = () => {
    const result = document.createElement('appraisals-page');
    if (window.innerWidth <= THRESHOLD_WIDTH) {
        const floatingBtn = percyFloatingButton({ text: 'search' });
        floatingBtn.addEventListener('action', () => toggleSidebar(result));
        result.appendChild(floatingBtn);
    }
    let requestDone = false;
    const spinner = document.createElement('percy-spinner');
    spinner.classList.add('large');
    result.append(appraisalSubheader(true), document.createElement('appraisal-levels'));
    setTimeout(() => {
        if (!requestDone) {
            result.appendChild(spinner);
        }
    }, 2000);
    backendPostPromise('appraisals', JSON.stringify({ 'team-id': getTeamId() }))
        .then((res) => {
        var _a;
        requestDone = true;
        window.store.allAppraisals = res;
        const levels = appraisalLevels(appraised());
        result.append(levels, new MembersPool(notAppraised()));
        const levelScroll = (_a = Array.from(levels.children).find((level) => level.children.length > 0)) !== null && _a !== void 0 ? _a : document.getElementById('cards-level0');
        levelScroll === null || levelScroll === void 0 ? void 0 : levelScroll.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        firstAccessOnboard();
        return result;
    })
        .catch((err) => {
        requestDone = true;
        logError(err);
        displayPage(Page.CANT_APPRAISE, { msg: err });
    }).finally(() => {
        spinner.remove();
    });
    return result;
};
