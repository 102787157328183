import { logError } from '../../utils/logger';
import { backendPostPromise, getTeamId } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { showOkText } from '../teams';
import { percyModal, toggleBusyStatus } from './percy-modal';
import { roundStatus } from './round-status';
const clearTeamAppraisals = async () => {
    const teamId = getTeamId();
    await backendPostPromise('export/new-round', JSON.stringify({ 'team-id': teamId }))
        .then(async () => {
        await backendPostPromise('team-profiles2', JSON.stringify({ 'team-id': teamId })).then((team) => {
            window.store.selectedTeam = team;
            showOkText(team);
        });
    })
        .catch(logError);
};
const showNewRoundAlert = () => {
    var _a;
    const text = document.createElement('b');
    text.textContent = getTranslation('new-round-alert');
    const modal = percyModal('', ['ok', 'cancel'], '', false, false, text);
    modal.setAttribute('data-cy', 'alert-modal');
    modal.addEventListener('ok', async () => {
        toggleBusyStatus();
        await clearTeamAppraisals();
        toggleBusyStatus();
        modal.remove();
    });
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(modal);
};
const hasAppraisalStarted = (members) => {
    return members.some((member) => {
        var _a;
        return ((_a = member.role) === null || _a === void 0 ? void 0 : _a.includes('appraiser'))
            && member.status !== 'pending'
            && member.status !== 'invited';
    });
};
const canStartNewRound = () => {
    const team = window.store.selectedTeam;
    if (team.members && hasAppraisalStarted(team.members)) {
        return true;
    }
    return false;
};
export const roundControl = () => {
    const team = window.store.selectedTeam;
    const result = document.createElement('round-control');
    const title = document.createElement('appraisal-round-title');
    title.textContent = getTranslation('appraisal-round-title');
    title.className = 'section-title';
    const status = roundStatus(team.round);
    result.append(title, status);
    if (canStartNewRound()) {
        const btn = document.createElement('button');
        btn.id = 'clear-appraisals-btn';
        btn.textContent = getTranslation(btn.id);
        btn.className = 'secondary-button';
        btn.addEventListener('click', () => showNewRoundAlert());
        result.insertBefore(btn, status);
    }
    return result;
};
