const currentYear = new Date().getFullYear();
export const translations = {
    en: {
        "title": "Percival - Fair Appraisals",
        "copyright-msg": "© Copyright 2019-" + currentYear + " | All rights reserved",
        "or": "or",
        "import-xlsx": "Import Excel file (xlsx): ",
        "browse-files": "Upload",
        "see-examples": "Do you want to see an example?",
        "download-xlsx-template": '<a href="/docs/example-team.xlsx" target="_blank">Download example-team.xlsx</a>',
        "open-google-sheet-template": '<a href="https://docs.google.com/spreadsheets/d/1wU8B2wXmcVF9-ia74I46w8cQcaDD8rbCbI6B7La4jLA/edit?usp=sharing" target="_blank">Google Spreadsheet</a>',
        "invalid-spreadsheet": "Invalid spreadsheet",
        "you-pasted": "You pasted:",
        "invalid-headers": "Pasted team data must contain a header line with Email, Name, Appraiser (optional), Appraised (optional), Admin (optional) and Avatar (optional).",
        "no-content": "No further table data, beside its header line, was detected.",
        "invalid-email": "An invalid email was detected at line ${line}.\r\n Invalid email: ${email}",
        "invalid-name": "A member without a name was detected at line ${line}.",
        "superadmin-tab": "SuperAdmin",
        "teams-tab": "Teams",
        "your-teams": "Selected Team",
        "appraisals-tab": "Appraisals",
        "results-tab": "Results",
        "superadmin-menu": "SuperAdmin",
        "admin-menu": "Teams",
        "appraisals-menu": "Appraisals",
        "results-menu": "Results",
        "logout-menu": "Logout",
        "logout": "Logout",
        "impersonate": "Impersonate",
        "google-label": "Continue with Google",
        "microsoft-label": "Continue with Microsoft",
        "email-label": "Continue with Email",
        "sign-in-salutation": "Welcome",
        "sign-in-explanation": "Percival is the premier tool for collaborative compensation.",
        "sign-in-msg-buy-now": "Please sign in to buy your licenses.",
        "sign-in-msg-trial": "Please sign in to start your trial.",
        "sign-in-msg-general": "How would you like to sign in?",
        "send-magic": "Send",
        "magic-link-request-title": "Sign-In with E-mail",
        "magic-link-request-subtitle": "You will receive a magic link to access Percival from any device.",
        "magic-link-sent": "Great! We sent your magic link to:",
        "magic-link-how-to": "Click on the link on the email to access Percival.</br></br>  We send your magic link in seconds, but sometimes it can take a few minutes to appear in your inbox, please check the spam folder.</br></br> If you did not receive the email after a few minutes, please ",
        "magic-link-let-us-know": "let us know.",
        "unable-magic-login": "Unable to open magic login link. Your internet connection might be down or the magic link might have expired. Try obtaining a new magic link.",
        "login-failed": "Login failed. You can try again later or using a different login method.",
        "team-members-title": "Team Members",
        "blank-members-state": "Click on the \"Add\" or \"Import Team\" buttons to add team members.",
        "appraisal-prompt-title": "Appraisal Question",
        "appraisal-question-details": "Appraisal Question Details",
        "appraisal-question-details-placeholder": "You can provide further details about the Appraisal Question: a list of things people should consider when making their appraisal, for example.",
        "add-details": "Add Details",
        "details": "Details",
        "team-name-prompt-title": "Team Name",
        "default-question": "What is the value of each person’s work worth to the company, compared to their colleagues?",
        "round-status-title": "Allow Appraisals",
        "add-member": "Add",
        "added-members": "Added members: ",
        "deleted-members": "Deleted members: ",
        "unaltered-members": "Unaltered members: ",
        "updated-members": "Updated members: ",
        "role": "Role",
        "import-members-title": "Import Team",
        "update-question-title": "Update Question",
        "appraisal-round-title": "Appraisal Round",
        "clear-appraisals-btn": "New Round",
        "clear-appraisals-msg": "All team appraisals cleared.",
        "new-team-modal-title": "Create Team",
        "send-invites-title": "Send invites",
        "send-invites-content": "Do you wish to send email invites to the pending team members?",
        "send-invites": "Send Invites",
        "new-team-content": "Do you wish to create a new team?",
        "status-pending": "Pending",
        "status-invited": "Invited",
        "status-appraising": "Appraising",
        "status-done": "Done",
        "tooltip-pending": "You haven't sent an invite to this person yet",
        "tooltip-invited": "You have already sent an invite to this person",
        "tooltip-appraising": "This person is appraising their peers",
        "tooltip-done": "This person has already appraised their peers",
        "import-team": "Import Team",
        "import-team-without-self-not-allowed": "You cant import a team without yourself as a member",
        "members-textarea-placeholder": "Copy team data from a spreadsheet and paste here.",
        "members-textarea-aria-label": "Insert both email and name of the team members you would like to import.",
        "question-textarea-placeholder": "Type the appraisal question here",
        "question-textarea-aria-label": "Set the appraisal question",
        "admin-not-you": "You are already in a team, with administrator: ",
        "edit-members-modal-title": "Edit Member",
        "edit-team-modal-title": "Edit Team",
        "add-members-modal-title": "Add Member",
        "edit": "Edit",
        "finish": "Finish",
        "finished": "Finished",
        "member-name-label": "Name:",
        "creator": "Created by: ",
        "team-creator": "Team Creator",
        "team": "Team : ",
        "team-name-label": "Name:",
        "first-team-name": "New Team 1",
        "duplicate-team-name": "Duplicate team name",
        "empty-team-name": "Team name cannot be empty",
        "name": "Name",
        "delete-member-btn": "Delete",
        "save-member-btn": "Update",
        "save-team-btn": "Update",
        "update": "Update",
        "save-member-btn2": "Add",
        "seats-usage-text": "team members",
        "error-text": "This is a very long error message.",
        "duplicated-user-error": "There is already a registered user with this email",
        "modal-ok-text": "The User has been added successfully.",
        "ok-text": "Changes saved.",
        "stop-appraisals-alert": "Do you want to prevent new appraisals from being made?",
        "unpublish-alert": "By allowing new appraisals the result will be made private. Do you want to proceed?",
        "new-round-alert": "Do you want to start a new test round clearing the current appraisals?",
        "close-round-button": "Close Round",
        "superadmin-section-title": "Superadmin Section",
        "accounts-label": "Add licenses:",
        "account-column-admin-title": "Account",
        "account-column-members-title": "People",
        "available-licenses-title": "Available Licenses",
        "members-with-licenses-title": "Active Licenses",
        "members-without-licenses-title": "Without Licenses",
        "licensed": "Licensed",
        "unlicensed": "Unlicensed",
        "teamless": "To appraise your peers, you can add yourself to the team.",
        "teamless-link": "Join team",
        "closed-round-msg-admin": "Appraisals are not allowed for this team.",
        "closed-round-msg": "The appraisal round has not yet been opened by the account administrator.",
        "closed-round-link": "Allow Appraisals",
        "certainty-question": "How high is your certainty when appraising this person?",
        "certainty-0.1": "Very Low",
        "certainty-0.3": "Low",
        "certainty-0.6": "High",
        "certainty-1.0": "Very High",
        "dont-appraise": "Don't Appraise",
        "ranking-menu": "Slicing",
        "certainty-menu": "Certainty",
        "data-menu": "Data",
        "ranking-results-bar": "Team Appraisal",
        "ranking-results-divergence": "Appraisal made by:",
        "certainty-results-topbar": "Total certainty of appraisals RECEIVED.",
        "certainty-results-bottonbar": "Total certainty of appraisals ASSIGNED.",
        "alert-modal-text": "Team members will be able to see round results. Other team members' names will be",
        "alert-ok-btn-txt": "Ok",
        "alert-cancel-btn-txt": "Cancel",
        "alert-publish-button": "Publish",
        "publish-bar-text": "This result has not yet been published for team members.",
        "publish-bar-btn": "Publish",
        "blank-results-msg": "Currently, there are no results.",
        "results-published": "Results published successfully!",
        "results-not-published": "There are currently no published results.",
        "results-data-title": "To access the raw results data, select one of the options below:",
        "final-result-btn-txt": "Open Last Result",
        "history-btn-txt": "Open History",
        "download-result-btn-txt": "Download Last Result",
        "loading-results": "Loading results...",
        "loading-report": "Loading report...",
        "results-error": "There was an error exporting your results data. Please, contact support.",
        "export-report-error": "There was an error exporting your users report. Please, contact support.",
        "account-title": "Account",
        "licenses-history-title": "Transaction History",
        "licenses-empty-history": "No licenses purchased yet.",
        "licenses-title": "Licenses",
        "licenses-explanation": "Each license is valid for one user for one year. When you add a user to a team, a license will be bound to that specific person. This license cannot be transferred to a different user. You can buy licenses in advance. If you don't use them, they will expire only after three years.",
        "amount": "Amount",
        "description": "Description",
        "registered-users-text": "Team Members: ",
        "country-label": "Billing Country:",
        "loading-option": "Loading...",
        "paid-licenses-text": "Annual Licenses: ",
        "licenses-button": "Buy Licenses",
        "unused-licenses-singular": "available license.",
        "unused-licenses-plural": "available licenses.",
        "licenses-singular": "license.",
        "licenses-plural": "licenses.",
        "licenses-missing": "New licenses needed: ",
        "licenses-available": "Licenses available in the account: ",
        "members-singular": "member.",
        "members-plural": "members.",
        "with-licenses": "Team members with licenses: ",
        "without-licenses": "Team members that need licenses: ",
        "bound-licenses": "All your team members already have active licenses. No additional licenses will be activated.",
        "not-enough-licenses": "Before you import this team, you need to buy some licenses.",
        "not-enough-licenses-subadmin": "Not enough licenses. Contact ",
        "to-buy-more-licenses": ", to buy more licenses.",
        "not-enough-credits": "Not enough licenses",
        "enough-credits": "You need one license for each team member to generate your teams' slicing. <br>(Free for teams up to 5 members)<br> Licenses expire one year after their purchase.<br>",
        "check-terms-of-use": 'I have read and agreed with the <a href="https://www.percival.live/terms-of-use" target="_blank">terms of use.</a>',
        "payment-modal-title": "Payment Method",
        "beneficiary-text": "Beneficiary: Jux Tecnologia ltda",
        "redirecting-payment": "Redirecting to paypal...",
        "payment-successfull-msg": "Payment approved successfully.",
        "payment-cancel-msg": "Payment canceled.",
        "account-tab": "Account",
        "account-menu": "Account",
        "privacy-policy": "Privacy Policy",
        "cookies-policy": "Cookies Policy",
        "contact-page": "Contact Us",
        "price": "Price",
        "contact-name-label": "Name*:",
        "contact-name-placeholder": "Your name...",
        "contact-email-label": "Work Email*:",
        "contact-email-placeholder": "Your email...",
        "contact-whatsapp-label": "WhatsApp:",
        "contact-whatsapp-placeholder": "Your WhatsApp...",
        "contact-subject-label": "Subject:",
        "contact-message-label": "Message*:",
        "contact-purchase-placeholder": "Hello, I would like to buy licenses for my team...",
        "contact-message-placeholder": "Type your message here...",
        "contact-submit": "Send",
        "form-success": "Your message was successfully sent",
        "onboard-page2a-title": "Configure the Appraisal Round Question",
        "onboard-page2a-text": "Define the question for this appraisal. Your team members will answer this question when appraising other team members.",
        "onboard-page2b-title": "Add team members",
        "onboard-page2b-text": "Using the \"Add\" button, add team members individually. If you are also going to appraise and be appraised by the team, include yourself in this list.",
        "onboard-page2c-title": "Import the whole team at once",
        "onboard-page2c-text": "You can also create an entire team at once using the \"Import\" button. A list with one team member per line, e-mails and names, separated by commas must be provided. Ctrl + C, Ctrl + V from a spreadsheet with these two columns, without a header also works.",
        "onboard-page2d-title": "To Open or Close, Just 1 Click",
        "onboard-page2d-text": "After setup, you can click \"Allow Appraisals\" if want to start the round. When the team complete their appraisals or if you want to close the round, use the same button to stop new appraisals.",
        "onboard-page3a-title": "Appraising Your Team Members",
        "onboard-page3a-text": "Rank your team members from those who contribute the most to the theme proposed in the appraisal question to those who contribute least, from top to bottom. More than one person can stay on the same level.",
        "onboard-page3b-title": "Don't worry! You don't have to be 100% right",
        "onboard-page3b-text": "Percival will ask you for your degree of certainty in each appraisal. This way, you can appraise not only those you know well, but also people with whom you interact less. If you prefer not to appraise someone, just press in the red X. ",
        "onboard-page4a-title": "The Final Appraisal is the Result of Team Collaboration!",
        "onboard-page4a-text": "Percival takes into account the opinion of the whole team and generates a fair final result. It also shows your position in this ranking and a comparison between the way you appraised the team and the group's appraisal. ",
        "onboard-page4b-title": "How Much Do You Know Your Team And They Know You",
        "onboard-page4b-text": "Here Percival will give you 2 results per person. The top bar indicates the degree of certainty in the appraisals that person received and the bottom one what he attributed. The more appraisals made or the higher the grade for certainty, the bars are longer. ",
        "onboard-page4c-title": "Do you want to model the data yourself?",
        "onboard-page4c-text": "In the Data tab, you can download a spreadsheet with everything that was calculated by Percival. That way you can model the data as best matches your company's culture and your needs.",
        "session-expired-msg": "Your session has expired. Please log in again.",
        "buy-message": "Hi, I would like to buy",
        "email-not-sent-message": "Hello, I did not receive a magic link through my email.",
        "success": "Success",
        "error": "Error",
        "warning": "Warning",
        "info": "Info",
        "success-msg": "Success!!!",
        "error-msg": "Error!!!",
        "warning-msg": "Warning!!!",
        "info-msg": "Info!!!",
        "order-canceled": "Payment canceled by the user.",
        "transaction-completed": "Transaction successfully completed",
        "licenses-purchase": "Bought licenses",
        "purchase-description": "Percival Licenses",
        "order-details": "Order Details",
        "pending": "Pending",
        "paid": "Paid",
        "cancelled": "Cancelled",
        "status-payer-action-required": "User Action Required",
        "order-id": "Order Id",
        "status": "Status",
        "order": "Paypal Order",
        "manual-credits": "Promotional Licenses",
        "paypal-error-msg": "An error occured with Paypal",
        "order-updated-msg": "Order updated",
        "order-error-msg": "An error ocurred while updating your order",
        "date": "Date",
        "finish-purchase": "Complete the payment",
        "pay-with-paypal": "Pay with PayPal",
        "select-country": "Select your billing country...",
        "no-selected-country": "Please select your billing country before proceeding",
        "country-changed": "Country successfully changed",
        "country-failed-change": "Failed to change country",
        "country-selection-prompt": "Please select your billing country bellow:",
        "invalid-cnpj": "Please enter a valid CNPJ or CPF.",
        "continue": "Continue",
        "invalid-captcha-response": "Invalid captcha",
        "consent-text": "Would you like to know all you can achieve and receive tips and updates about Percival?",
        "approval-text": "Yes, please",
        "refusal-text": "No, thank you",
        "welcome-title": "Welcome to Percival",
        "create-team": "Create Team",
        "new-team": "New Team",
        "delete-team": "Delete Team",
        "deleted-team-hint": " (deleted)",
        "deletion-confirmation": "Do you really wish to delete your team <b>${team}</b>?",
        "deletion-type": "Please type <b>${team}</b> to confirm.",
        "selected": "Selected",
        "logged-as": "You are logged in as: <br>",
        "no-teams": "It seems you haven't been invited to a team yet.</br>Please send a printscreen of this message to your team administrator.</br> Otherwise, you can create your first team!",
        "team-list-title": "To use Percival, you need to select a team. You can select the following teams:",
        "you": "You",
        "generic-help-msg": "Something went wrong. Check your internet connection and retry in a few minutes.",
        "publish-results-error": "There was an error trying to publish your team's results",
        "appraisals-failed": "Failed to retrieve appraisals. Check your internet connection and retry in a few moments.",
        "cannot-appraise-members": "To appraise your peers, you have to be set as appraiser in your team.",
        "appraise-failed": "There was an error trying to send your appraisal. Check your internet connection and retry in a few moments.",
        "privacy-policy-url": "https://www.percival.live/privacy",
        "cookies-policy-url": "https://www.percival.live/cookies",
        "percival-live-url": "https://www.percival.live/",
        "search": "Search",
        "close": "Close",
        "no-appraisal": "Failed to get member data",
        "send-notification": "Send an email notification to your team.",
        "send-nf": "Your Nota Fiscal will be sent to your email address.",
        "other-payment-methods": "Looking for more payment options? <a>Contact us.</a>",
        "request-for-other-payments": "Hello, I'm looking for other payment methods to purchase ",
        "before-buying-msg": "Before buying, please read and accept our terms",
        "buy-more": "Buy more",
        "invites-sent": "Your invites were sent!",
        "active-licenses": "Active licenses",
        "credits-consumption": "Licenças ativadas",
        "return-link": "Go back",
        "help": "Help",
        "user-picture-alt": "User",
        "search-for-users": "Search for users",
        "edit-question": "Edit question",
        "move-page-right": "Next page",
        "move-page-left": "Previous page",
        "results-paywall-msg": "To generate results for this team, you need to buy",
        "available-licenses": "Licenses bought, and able to be activated: ",
        "users-description": "Users can belong to more than one team.",
        "users-report": "View all users from all teams",
        "all-teams": "All Teams",
        "users": "Users",
        "appraiser-appraised": "Appraiser & Appraised",
        "both": "Appraiser & Appraised",
        "appraiser": "Appraiser",
        "appraised": "Appraised",
        "bind-licenses-warning": "To import this team, you will activate",
        "show": "Show",
        "hide": "Hide",
        "free-trial-info-1": "You have a single team with ",
        "free-trial-info-2": " people or less, so you can use Percival without buying additional licenses.",
        "leaving-free-trial": "You will no longer be able to use the Free Trial, if you create a new team",
        "team-id-not-found": "Team not found",
        "using-google-email": "You are using a Google E-mail",
        "using-microsoft-email": "You are using a Microsoft E-mail",
        "social-login-recommendation": "For a better experience we recommend signing in with ",
        "social-login-advantage": " This will allow your teammates to see your profile picture too.",
        "proceed-magic-link-request": "Sign in with Email anyway",
        "role-warning": "You need to select at least one value: Appraiser, Appraised or Admin.",
        "role-import-warning": "You need to set at least one role for ${team-member} using columns Appraiser, Appraised and Admin.",
        "cookie-error": "Activate your browser third-party cookies to login with Google, or choose another login option",
        "select-appraiser": "Select an appraiser",
        "key": "KEY",
        "photo-url": "Photo URL",
        "photo-url-placeholder": "User photo URL",
        "demo-explanation": "For this demo, please enter your favorite comedy:",
        "demo-placeholder": "Your favorite comedy movie or show",
        "no-demo-user-name": "Please type your favorite comedy movie or show",
        "demo-button-txt": "Enter",
        "visible": "Visible",
        "anonymized": "Hidden",
        "no-visibility-selected": "Please select the visibility level for your team results"
    },
    pt: {
        "title": "Percival - Avaliações Justas",
        "copyright-msg": "© Copyright 2019-" + currentYear + " | Todos os Direitos Reservados",
        "or": "ou",
        "import-xlsx": "Importar arquivo Excel (xlsx): ",
        "browse-files": "Upload",
        "see-examples": "Quer ver uma planilha de exemplo?",
        "download-xlsx-template": '<a href="/docs/time-exemplo.xlsx" target="_blank">Download time-exemplo.xlsx</a>',
        "open-google-sheet-template": '<a href="https://docs.google.com/spreadsheets/d/1SWUVoDhfdY3OUBzDJhQWCR0Z_Cp2SSutbowgdW0IllE/edit?usp=sharing" target="_blank">Google Planilhas</a>',
        "invalid-spreadsheet": "Planilha inválida",
        "you-pasted": "Você colou:",
        "invalid-headers": "Dados do time colados devem conter a linha de cabeçalho com Email, Nome, Avaliador(a) (opcional), Avaliado(a) (opcional), Admin (opcional) e Avatar (opcional).",
        "no-content": "Não foram detectados dados da planilha, além do cabeçalho da tabela.",
        "invalid-email": "Um email inválido foi detectado na linha ${line}.\r\n Email inválido: ${email}",
        "invalid-name": "Um membro sem nome foi detectado na linha ${line}.",
        "superadmin-tab": "SuperAdmin",
        "teams-tab": "Times",
        "your-teams": "Time Selecionado",
        "appraisals-tab": "Avaliações",
        "results-tab": "Resultados",
        "superadmin-menu": "SuperAdmin",
        "admin-menu": "Times",
        "appraisals-menu": "Avaliações",
        "results-menu": "Resultados",
        "logout-menu": "Sair",
        "logout": "Sair",
        "impersonate": "Personificar",
        "google-label": "Continuar com o Google",
        "microsoft-label": "Continuar com a Microsoft",
        "email-label": "Continuar com o Email",
        "sign-in-salutation": "Olá",
        "sign-in-explanation": "O Percival é a ferramenta ideal para remuneração colaborativa.",
        "sign-in-msg-buy-now": "Por favor, entre para comprar suas licenças.",
        "sign-in-msg-trial": "Por favor, entre para iniciar sua avaliação.",
        "sign-in-msg-general": "Como você gostaria de entrar?",
        "send-magic": "Enviar",
        "magic-link-request-title": "Fazer login com o E-mail",
        "magic-link-request-subtitle": "Você vai receber um link mágico para acessar o Percival de qualquer aparelho.",
        "magic-link-sent": "Ótimo! O seu mágic link foi enviado para:",
        "magic-link-how-to": "Basta clicar no link no email para acessar o Percival. </br></br> Nós enviamos o seu magic link em segundos, mas às vezes pode levar alguns minutos para aparecer no seu inbox, cheque a sua pasta de spam.</br></br> E se você não receber o email depois de alguns minutos, por favor ",
        "magic-link-let-us-know": "nos avise.",
        "unable-magic-login": "Não foi possível abrir o magic link. Sua conexão à Internet pode estar inoperante ou o magic link pode ter expirado. Tente obter um novo magic link",
        "login-failed": "Não foi possível fazer o login. Você pode tentar mais tarde ou usar um método diferente de login.",
        "team-members-title": "Membros do Time",
        "blank-members-state": "Clique \"Adicionar\" ou \"Importar Time\" para adicionar os membros do time.",
        "appraisal-prompt-title": "Pergunta da Avaliação",
        "appraisal-question-details": "Detalhes sobre a Pergunta da Avaliação",
        "appraisal-question-details-placeholder": "Você pode fornecer detalhes adicionais sobre a Pergunta da Avaliação: como uma lista do que as pessoas devem ter em mente enquanto fazem a avaliação, por exemplo",
        "add-details": "Adicionar Detalhes",
        "details": "Detalhes",
        "team-name-prompt-title": "Nome do Time",
        "default-question": "Qual o valor, para a empresa, da colaboração de cada pessoa, comparada aos colegas?",
        "round-status-title": "Permitir Avaliações",
        "add-member": "Adicionar",
        "added-members": "Membros adicionados: ",
        "deleted-members": "Membros excluídos: ",
        "unaltered-members": "Membros inalterados: ",
        "updated-members": "Membros atualizados: ",
        "role": "Papel",
        "import-members-title": "Importar Time",
        "update-question-title": "Atualizar Pergunta",
        "appraisal-round-title": "Rodada de Avaliações",
        "clear-appraisals-btn": "Nova Rodada",
        "clear-appraisals-msg": "Avaliações apagadas.",
        "new-team-modal-title": "Criar Time",
        "send-invites-title": "Enviar convites",
        "send-invites-content": "Deseja enviar convites de email para os membros pendentes do seu time?",
        "send-invites": "Enviar Convites",
        "new-team-content": "Deseja criar um novo time?",
        "status-pending": "Pendente",
        "status-invited": "Convidado(a)",
        "status-appraising": "Avaliando",
        "status-done": "Concluído",
        "tooltip-pending": "Você ainda não enviou o convite para essa pessoa",
        "tooltip-invited": "Você já enviou o convite para essa pessoa",
        "tooltip-appraising": "Essa pessoa está avaliando os membros do time",
        "tooltip-done": "Essa pessoa já avaliou os membros do time",
        "import-team": "Importar",
        "import-team-without-self-not-allowed": "Não é impossível importar um time sem você como um membro",
        "members-textarea-placeholder": "Copie os dados do time de uma planilha e cole aqui.",
        "members-textarea-aria-label": "Insira o e-mail e nome dos membros do time que deseja importar.",
        "question-textarea-placeholder": "Digite a pergunta da avaliação aqui",
        "question-textarea-aria-label": "Insira a pergunta de avaliação",
        "admin-not-you": "Você já faz parte do time do administrator: ",
        "edit-members-modal-title": "Editar Membro",
        "edit-team-modal-title": "Editar Time",
        "add-members-modal-title": "Adicionar Membro",
        "edit": "Editar",
        "finish": "Concluir",
        "finished": "Concluído",
        "member-name-label": "Nome:",
        "creator": "Criado por: ",
        "team-creator": "Criador do Time",
        "team": "Time: ",
        "team-name-label": "Nome:",
        "first-team-name": "Novo Time 1",
        "duplicate-team-name": "Nome de time duplicado",
        "empty-team-name": "Nome do time não pode ser vazio",
        "name": "Nome",
        "delete-member-btn": "Excluir",
        "save-member-btn": "Atualizar",
        "save-team-btn": "Atualizar",
        "update": "Atualizar",
        "save-member-btn2": "Adicionar",
        "seats-usage-text": "pessoas",
        "error-text": "Essa é uma mensagem de erro longa.",
        "duplicated-user-error": "Já existe um usuário cadastrado com esse e-mail",
        "modal-ok-text": "O usuário foi adicionado com sucesso.",
        "ok-text": "Alterações salvas.",
        "stop-appraisals-alert": "Deseja impedir que novas avaliações sejam feitas?",
        "unpublish-alert": "Ao permitir novas avaliações o resultado será tornado privado. Deseja prosseguir?",
        "new-round-alert": "Deseja iniciar uma nova rodada de avaliações limpando as avaliações atuais?",
        "close-round-button": "Fechar Rodada",
        "superadmin-section-title": "Gerenciamento de licenças",
        "accounts-label": "Adicionar licenças:",
        "account-column-admin-title": "Conta",
        "account-column-members-title": "Pessoas",
        "available-licenses-title": "Licenças Disponíveis",
        "members-with-licenses-title": "Licenças Ativas",
        "members-without-licenses-title": "Sem Licenças",
        "licensed": "Licença Ativa",
        "unlicensed": "Sem Licença",
        "teamless": "Para avaliar seus pares, você pode se adicionar ao time.",
        "teamless-link": "Ingressar no time",
        "closed-round-msg-admin": "Avaliações não estão permitidas para esse time.",
        "closed-round-msg": "A rodada de avaliação ainda não foi aberta pelo administrador da conta.",
        "closed-round-link": "Permitir Avaliações",
        "certainty-question": "Qual o nível de certeza de sua avaliação para essa pessoa?",
        "certainty-0.1": "Muito Baixo",
        "certainty-0.3": "Baixo",
        "certainty-0.6": "Alto",
        "certainty-1.0": "Muito Alto",
        "dont-appraise": "Não Avaliar",
        "ranking-menu": "Divisão",
        "certainty-menu": "Certeza",
        "data-menu": "Dados",
        "ranking-results-bar": "Avaliação do Time",
        "ranking-results-divergence": "Avaliação feita por:",
        "certainty-results-topbar": "Total de certezas das avaliações RECEBIDAS.",
        "certainty-results-bottonbar": "Total de certezas das avaliações ATRIBUÍDAS.",
        "alert-modal-text": "Os membros do time poderão visualizar os resultados. Os nomes dos outros membros estarão ",
        "alert-ok-btn-txt": "Sim",
        "alert-cancel-btn-txt": "Cancelar",
        "alert-publish-button": "Publicar",
        "publish-bar-text": "Este resultado ainda não foi publicado para os membros do time.",
        "publish-bar-btn": "Publicar",
        "blank-results-msg": "No momento não há nenhum resultado disponível.",
        "results-published": "Resultados publicados com sucesso!",
        "results-not-published": "No momento não há nenhum resultado publicado.",
        "results-data-title": "Para obter acesso aos dados brutos das avaliações, selecione uma das opções abaixo:",
        "final-result-btn-txt": "Acessar Resultado Final",
        "history-btn-txt": "Acessar Histórico",
        "download-result-btn-txt": "Baixar Último Resultado",
        "loading-results": "Carregando resultados...",
        "loading-report": "Carregando relatório...",
        "export-results-error": "Ocorreu um erro ao exportar os resultados. Por favor, tente novamente mais tarde ou entre em contato com nosso suporte.",
        "export-report-error": "Ocorreu um erro ao exportar o relatório de usuários. Por favor, tente novamente mais tarde ou entre em contato com nosso suporte.",
        "account-title": "Conta",
        "licenses-history-title": "Histórico de Transações",
        "licenses-empty-history": "Nenhuma licença comprada até o momento.",
        "licenses-title": "Licenças",
        "licenses-explanation": "Cada licença é válida para um usuário por um ano. Quando adicionar um usuário em um time, uma licença será vinculada àquela pessoa específica. Essa licença não pode ser transferida para um usuário diferente. Você pode comprar licenças antecipadamente. Caso não as use, elas só irão expirar após três anos.",
        "amount": "Quantidade",
        "description": "Descrição",
        "registered-users-text": "Membros do Time: ",
        "country-label": "País de Cobrança:",
        "loading-option": "Carregando...",
        "paid-licenses-text": "Licenças Anuais: ",
        "licenses-button": "Comprar Licenças",
        "unused-licenses-singular": "licença disponível.",
        "unused-licenses-plural": "licenças disponíveis.",
        "licenses-singular": "licença.",
        "licenses-plural": "licenças.",
        "licenses-missing": "Licenças novas necessárias: ",
        "licenses-available": "Licenças disponíveis na conta: ",
        "members-singular": "membro.",
        "members-plural": "membros.",
        "with-licenses": "Membros que possuem licenças: ",
        "without-licenses": "Membros que precisam de licenças: ",
        "bound-licenses": "Todos os membros do seu time já possuem licenças ativas. Nenhuma licença adicional será ativada.",
        "not-enough-licenses": "Antes de importar esse time, você precisa adquirir algumas licenças.",
        "not-enough-licenses-subadmin": "Sem licenças suficientes. Entre em contato com ",
        "to-buy-more-licenses": ", para comprar mais licenças.",
        "not-enough-credits": "Número de licenças insuficiente",
        "enough-credits": "Você precisa de uma licença para cada membro do time. <br>(Grátis para times de até 5 membros)<br> As licenças são válidas por um ano, a partir da data da compra.<br>",
        "check-terms-of-use": "Li e aceito todos os <a href='https://www.percival.live/terms-of-use?lang=pt' target='_blank'>termos de uso.</a>",
        "payment-modal-title": "Forma de Pagamento",
        "beneficiary-text": "Beneficiário: Jux Tecnologia ltda",
        "redirecting-payment": "Redirecionando para o paypal...",
        "payment-successfull-msg": "Pagamento realizado com sucesso.",
        "payment-cancel-msg": "Pagamento cancelado.",
        "account-tab": "Conta",
        "account-menu": "Conta",
        "privacy-policy": "Política de Privacidade",
        "cookies-policy": "Política de Cookies",
        "contact-page": "Fale Conosco",
        "price": "Preço",
        "contact-name-label": "Nome*:",
        "contact-name-placeholder": "Seu nome...",
        "contact-email-label": "Email da Empresa*:",
        "contact-email-placeholder": "Seu email...",
        "contact-whatsapp-label": "WhatsApp:",
        "contact-whatsapp-placeholder": "Seu WhatsApp...",
        "contact-subject-label": "Assunto:",
        "contact-message-label": "Mensagem*:",
        "contact-purchase-placeholder": "Olá, gostaria de comprar licenças para o meu time...",
        "contact-message-placeholder": "Digite sua mensagem aqui...",
        "contact-submit": "Enviar",
        "form-success": "Sua mensagem foi enviada com sucesso",
        "onboard-page2a-title": "Configure a Pergunta da Rodada de Avaliação",
        "onboard-page2a-text": "Defina qual a pergunta desta avaliação. Os membros do seu time responderão esta pergunta ao avaliar outros membros do time.",
        "onboard-page2b-title": "Adicione membros do time",
        "onboard-page2b-text": "Usando o botão \"Adicionar\", adicione membros do time individualmente. Se você também for avaliar e ser avaliado(a) pelo time, inclua-se nesta lista.",
        "onboard-page2c-title": "Importe todo o time de uma só vez",
        "onboard-page2c-text": "Você também pode criar um time inteiro de uma só vez usando o botão \"Importar\". Uma lista com um membro do time por linha, de e-mails e nomes, separados por vírgula deve ser fornecida. Ctrl+C, Ctrl+V de uma planilha com essas duas colunas, sem cabeçalho também funciona.",
        "onboard-page2d-title": "Para Abrir ou Fechar, Basta 1 Clique",
        "onboard-page2d-text": "Após configurar, você já pode clicar em \"Permitir avaliações\" para abrir a rodada de avaliações ao time. Quando concluírem suas avaliações ou quiser fechá-la, desative o botão para não permitir novas avaliações.",
        "onboard-page3a-title": "Avaliando os Membros do seu Time",
        "onboard-page3a-text": "Ordene os membros do seu time dos que mais contribuem para a temática proposta na pergunta da avaliação para os que menos contribuem, de cima para baixo. Mais de uma pessoa pode ficar no mesmo nível.",
        "onboard-page3b-title": "Não Se Preocupe! Você Não Precisa Estar 100% Certo",
        "onboard-page3b-text": "O Percival perguntará qual o seu grau de certeza em cada avaliação. Assim, você pode avaliar não só quem conhece bem, mas também pessoas com quem interage pouco. Se preferir não avaliar alguém, é só apertar no X vermelho.",
        "onboard-page4a-title": "A Avaliação Final é Resultado da Colaboração do Time!",
        "onboard-page4a-text": "O Percival leva em consideração a opinião de todo o time e gera um resultado final justo. Ele também mostra sua posição nesse ranking e uma comparação entre a forma como você avaliou o time e a avaliação do grupo.",
        "onboard-page4b-title": "O Quanto Você Conhece Seu Time E Quanto Ele Te Conhece",
        "onboard-page4b-text": "Aqui o Percival te dará 2 resultados por pessoa. A barra de cima indica o grau de certeza nas avaliações que aquela pessoa recebeu e a de baixo o que atribuiu. Quanto mais avaliações feitas ou maior o grau de certeza, mais comprida são as barras.",
        "onboard-page4c-title": "Quer Modelar os Dados Você Mesmo?",
        "onboard-page4c-text": "Em Dados, você consegue baixar uma planilha com tudo o que foi calculado pelo Percival. Dessa forma você consegue modelar os dados como melhor condizer com a cultura da sua empresa e sua necessidade atual.",
        "session-expired-msg": "Sua sessão expirou. Por favor, faça o login novamente.",
        "buy-message": "Olá, eu gostaria de comprar",
        "email-not-sent-message": "Olá, eu não recebi um magic link em meu email.",
        "success": "Sucesso",
        "error": "Erro",
        "warning": "Alerta",
        "info": "Info",
        "success-msg": "Sucesso!!!",
        "error-msg": "Erro!!!",
        "warning-msg": "Alerta!!!",
        "info-msg": "Info!!!",
        "order-canceled": "Pagamento cancelado pelo usuário.",
        "transaction-completed": "Transação efetuada com sucesso",
        "licenses-purchase": "Comprou licenças",
        "purchase-description": "Licenças do Percival",
        "order-details": "Detalhes da Compra",
        "pending": "Pendente",
        "paid": "Pago",
        "cancelled": "Cancelado",
        "order-id": "Número do Pedido",
        "status": "Status",
        "order": "Compra pelo Paypal",
        "manual-credits": "Licenças Promocionais",
        "paypal-error-msg": "Ocorreu um erro na comunicação com o Paypal",
        "order-updated-msg": "Pedido atualizado",
        "order-error-msg": "Ocorreu um erro ao atualizar seu pedido",
        "date": "Data",
        "finish-purchase": "Finalizar o pagamento",
        "pay-with-paypal": "Pagar com PayPal",
        "select-country": "Selecione seu país de cobrança...",
        "no-selected-country": "Por favor, selecione o seu país de cobrança antes de prosseguir",
        "country-changed": "País alterado com sucesso",
        "country-failed-change": "Falha em alterar o país",
        "country-selection-prompt": "Por favor, selecione seu país de cobrança abaixo:",
        "invalid-cnpj": "Por favor, digite um CNPJ ou CPF válido.",
        "continue": "Continuar",
        "invalid-captcha-response": "Captcha inválido",
        "consent-text": "Você deseja receber dicas e updates do Percival?",
        "approval-text": "Sim, por favor",
        "refusal-text": "Não, obrigado",
        "welcome-title": "Bem-vindo ao Percival",
        "create-team": "Criar Time",
        "new-team": "Novo Time",
        "delete-team": "Excluir Time",
        "deleted-team-hint": " (excluído)",
        "deletion-confirmation": "Você quer realmente excluir o seu time <b>${team}</b>?",
        "deletion-type": "Por favor, digite <b>${team}</b> para confirmar.",
        "selected": "Selecionado",
        "logged-as": "Você fez o login como: <br>",
        "no-teams": "Parece que você ainda não foi convidado(a) para nenhum time.</br>Por favor, envie um printscreen desta mensagem para o administrador do seu time.</br>Ou, se for o caso, crie o seu primeiro time!",
        "team-list-title": "Para usar o Percival, você precisa selecionar um time. Você pode selecionar um dos seguintes times:",
        "you": "Você",
        "generic-help-msg": "Algo deu errado. Verifique sua conexão com a internet e tente novamente em alguns minutos.",
        "publish-results-error": "Ocorreu um erro ao tentar publicar os resultados do seu time.",
        "appraisals-failed": "Ocorreu um erro ao buscar as avaliações. Verifique sua conexão com a internet e tente novamente em alguns instantes.",
        "cannot-appraise-members": "Para avaliar seus pares, você deve estar adicionado como avaliador(a) no time.",
        "appraise-failed": "Ocorreu um erro ao enviar sua avaliação. Verifique sua conexão com a internet e tente novamente em alguns instantes.",
        "privacy-policy-url": "https://www.percival.live/privacy?lang=pt",
        "cookies-policy-url": "https://www.percival.live/cookies?lang=pt",
        "percival-live-url": "https://www.percival.live/?lang=pt",
        "search": "Buscar",
        "close": "Fechar",
        "no-appraisal": "Falha ao buscar dados do membro",
        "send-notification": "Enviar um email notificando o seu time.",
        "send-nf": "A Nota Fiscal será enviada para o seu email.",
        "other-payment-methods": "Outras formas de pagamento? <a>Fale conosco.</a>",
        "request-for-other-payments": "Olá, gostaria de outras formas de pagamento para adquirir ",
        "before-buying-msg": "Antes de realizar sua compra, por favor leia e aceite nossos termos",
        "buy-more": "Comprar mais",
        "invites-sent": "Os seus convites foram enviados!",
        "active-licenses": "Licenças ativas",
        "credits-consumption": "Activated licenses",
        "return-link": "Voltar",
        "help": "Ajuda",
        "user-picture-alt": "Usuário",
        "search-for-users": "Buscar usuários",
        "edit-question": "Editar pergunta",
        "move-page-right": "Próxima página",
        "move-page-left": "Página anterior",
        "results-paywall-msg": "Para gerar os resultados deste time, você precisa comprar",
        "available-licenses": "Licenças compradas, disponíveis para serem ativadas: ",
        "users-description": "Usuários podem estar em mais de um time. ",
        "users-report": "Visualizar todos os usuários de todos os times",
        "all-teams": "Todos Times",
        "users": "Usuários",
        "appraiser-appraised": "Avaliador(a) & Avaliado(a)",
        "both": "Avaliador(a) & Avaliado(a)",
        "appraiser": "Avaliador(a)",
        "appraised": "Avaliado(a)",
        "bind-licenses-warning": "Para realizar essa importação, serão ativadas",
        "show": "Exibir",
        "hide": "Ocultar",
        "free-trial-info-1": "Você tem um único time com ",
        "free-trial-info-2": " pessoas ou menos, então você pode usar o Percival sem precisar comprar novas licenças.",
        "leaving-free-trial": "Ao criar um novo time, você não mais poderá usar o Teste Grátis",
        "team-id-not-found": "Time não encontrado",
        "using-google-email": "Você está usando um email Google",
        "using-microsoft-email": "Você está usando um email Microsoft",
        "social-login-recommendation": "Para uma melhor experiência, recomendados que utilize entrar com ",
        "social-login-advantage": " Isso permitirá que seus colegas de time vejam sua foto.",
        "proceed-magic-link-request": "Continuar a entrar com Email, mesmo assim",
        "role-warning": "Você precisa selecionar ao menos um valor: Avaliador(a), Avaliado(a) ou Admin.",
        "role-import-warning": "Você precisa preencher pelo menos uma das colunas Avaliador, Avaliado e Admin, para ${team-member}",
        "cookie-error": "Habilite os cookies de terceiros do seu navegador para fazer login com Google, ou escolha outra forma de login",
        "select-appraiser": "Escolha o(a) avaliador(a)",
        "key": "CHAVE",
        "photo-url": "URL da foto",
        "photo-url-placeholder": "URL da foto do usuário",
        "demo-explanation": "Para esta demonstração, por favor insira sua comédia favorita:",
        "demo-placeholder": "Seu filme ou série de comédia preferida",
        "no-demo-user-name": "Por favor insira seu filme ou série de comédia preferida",
        "demo-button-txt": "Entrar",
        "visible": "Visíveis",
        "anonymized": "Omitidos",
        "no-visibility-selected": "Escolha o nível de visibilidade para os resultados do seu time"
    }
};
