import { reportError } from '../../utils/logger';
import { appraise } from '../api-client';
import { showModal } from '../appraisals';
import { appraisalCard } from './appraisal-card';
const renderCards = (levels, appraisals) => {
    appraisals.forEach((appraisal) => {
        var _a;
        const newCard = appraisalCard('card-' + appraisal.email, appraisal);
        (_a = levels.querySelector('#cards-level' + appraisal.level)) === null || _a === void 0 ? void 0 : _a.appendChild(newCard);
    });
};
const levelChanged = (currentLevel) => {
    if (window.store.previousLevel) {
        window.store.previousLevel.classList.remove('drag-target');
    }
    if (currentLevel) {
        currentLevel.classList.add('drag-target');
    }
};
const dragOver = (event) => {
    // TODO melhorar experiência de scroll (Testar Chrome, Firefox, Edge)
    const el = document.querySelector('appraisal-levels');
    if (event.clientY < 175) {
        el === null || el === void 0 ? void 0 : el.scrollBy({ top: -20, behavior: 'smooth' });
    }
    else if (event.clientY > (window.screen.height - 120)) {
        el === null || el === void 0 ? void 0 : el.scrollBy({ top: 20, behavior: 'smooth' });
    }
    const targetLevel = findLevel(event.target);
    if (targetLevel) {
        event.preventDefault();
    }
    if (window.store.previousLevel === targetLevel) {
        return;
    }
    levelChanged(targetLevel);
    window.store.previousLevel = targetLevel;
};
const findLevel = (element) => {
    if (!element) {
        return null;
    }
    return (element.id.startsWith('cards-level'))
        ? element
        : findLevel(element.parentElement);
};
const drop = async (event) => {
    var _a;
    const appraisal = window.store.currentAppraisal;
    if (!appraisal) {
        reportError('Appraisals > drop > window.store.currentAppraisal não encontrado');
        return;
    }
    const targetLevel = findLevel(event.target);
    if (!targetLevel) {
        return;
    }
    event.preventDefault();
    const cardId = (_a = event === null || event === void 0 ? void 0 : event.dataTransfer) === null || _a === void 0 ? void 0 : _a.getData('card-id');
    const appraiseLevel = Number(targetLevel.id.substring(11));
    const card = document.getElementById(cardId);
    targetLevel.classList.remove('drag-target');
    appraisal.level = appraiseLevel;
    if (appraisal.certainty !== 0) {
        await appraise({
            certainty: appraisal.certainty,
            appraised: appraisal.email,
            level: appraiseLevel
        });
    }
    targetLevel.appendChild(card);
    if (appraisal.certainty === 0) {
        showModal('card-' + appraisal.email);
    }
};
export const appraisalLevels = (appraisals) => {
    const result = document.createElement('appraisal-levels');
    for (let i = 10; i >= -10; i--) {
        const appraisalLevel = document.createElement('appraisal-level');
        appraisalLevel.id = `cards-level${i}`;
        appraisalLevel.ondrop = (event) => drop(event);
        appraisalLevel.ondragover = (event) => dragOver(event);
        result.appendChild(appraisalLevel);
    }
    renderCards(result, appraisals);
    return result;
};
