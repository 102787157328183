import * as XLSX from 'xlsx';
import { logError } from '../../utils/logger';
import { changePage } from '../../utils/navigation';
import { isValidEmail, tsvToObj } from '../../utils/string';
import { backendGet } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { importPreviewModal } from './import-preview-modal';
import { labelWithValue } from './label-with-value';
import { percyModal } from './percy-modal';
const appendImportPreviewModal = (payload) => {
    var _a;
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(importPreviewModal(payload));
};
const xlsxToTable = (modal) => {
    var _a;
    const fileinput = document.getElementById('file-browser');
    if (!fileinput) {
        return;
    }
    if (!((_a = fileinput.files) === null || _a === void 0 ? void 0 : _a.length)) {
        return;
    }
    const file = fileinput.files[0];
    if (!file.name.includes('.xlsx')) {
        logError(`Import Team: Invalid file type ${file.name}`, 'invalid-spreadsheet');
        return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
        var _a;
        if (!e) {
            return;
        }
        if (!e.target) {
            return;
        }
        const data = e.target.result;
        const workbook = XLSX.read(data, {
            type: 'binary'
        });
        if (!workbook.SheetNames.length) {
            return;
        }
        const sheetName = workbook.SheetNames[0];
        const json = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { defval: '' });
        (_a = modal.parentElement) === null || _a === void 0 ? void 0 : _a.remove();
        appendImportPreviewModal({ data: json, title: file.name });
    };
    reader.onerror = logError;
    reader.readAsBinaryString(file);
};
const hasTableContent = (tableData) => {
    const tableDataAsArray = tableData.split('\n');
    return tableDataAsArray.length > 1;
};
const isHeaderLineValid = (tableData) => {
    const headerLine = tableData.split('\n')[0];
    return (headerLine.toLowerCase().includes(`email\tname`) || headerLine.toLowerCase().includes(`email\tnome`));
};
const parsePastedData = (pastedData) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    const headerLine = pastedData.split('\n')[0];
    // TODO: Simplificar.
    return pastedData.replace(headerLine, (_q = (_p = (_o = (_m = (_l = (_k = (_j = (_h = (_g = (_f = (_e = (_d = (_c = (_b = (_a = pastedData.split('\n')[0]) === null || _a === void 0 ? void 0 : _a.replaceAll('email', 'Email') // Esse replaceAll é um extension method nosso
    ) === null || _b === void 0 ? void 0 : _b.replaceAll('e-mail', 'Email') // que faz o replace ignorando o casing
    ) === null || _c === void 0 ? void 0 : _c.replaceAll('nome', 'Name')) === null || _d === void 0 ? void 0 : _d.replaceAll('avatar', 'Avatar')) === null || _e === void 0 ? void 0 : _e.replaceAll('admin', 'Admin')) === null || _f === void 0 ? void 0 : _f.replaceAll('appraiser', 'Appraiser')) === null || _g === void 0 ? void 0 : _g.replaceAll('avaliador(a)', 'Appraiser')) === null || _h === void 0 ? void 0 : _h.replaceAll('Avaliador(a)', 'Appraiser')) === null || _j === void 0 ? void 0 : _j.replaceAll('avaliador', 'Appraiser')) === null || _k === void 0 ? void 0 : _k.replaceAll('Avaliador', 'Appraiser')) === null || _l === void 0 ? void 0 : _l.replaceAll('appraised', 'Appraised')) === null || _m === void 0 ? void 0 : _m.replaceAll('avaliado(a)', 'Appraised')) === null || _o === void 0 ? void 0 : _o.replaceAll('Avaliado(a)', 'Appraised')) === null || _p === void 0 ? void 0 : _p.replaceAll('avaliado', 'Appraised')) === null || _q === void 0 ? void 0 : _q.replaceAll('Avaliado', 'Appraised'));
};
const alertWithPlaceholder = (type, placeholder, index) => {
    const toBeReplaced = '${' + placeholder + '}';
    return getTranslation(type).replace(toBeReplaced, index.toString());
};
const dataValidator = (dataAsString) => {
    const data = tsvToObj(dataAsString);
    const invalidData = { hasInvalidData: false, content: {} };
    data.every((memberEntry, index) => {
        const validEmail = isValidEmail(memberEntry.Email);
        const lineNumber = index + 2;
        if (memberEntry.Name && validEmail) {
            return true;
        }
        else if (!validEmail) {
            const email = memberEntry.Email;
            invalidData.hasInvalidData = true;
            const withLine = alertWithPlaceholder('invalid-email', 'line', lineNumber);
            const alert = alertWithPlaceholder(withLine, 'email', email);
            invalidData.content = { pastedInvalidData: { payload: dataAsString.replace(/\t/g, ', '), alert } };
            return false;
        }
        else {
            invalidData.hasInvalidData = true;
            invalidData.content = { pastedInvalidData: { payload: dataAsString.replace(/\t/g, ', '), alert: alertWithPlaceholder('invalid-name', 'line', lineNumber) } };
            return false;
        }
    });
    if (invalidData.hasInvalidData) {
        return invalidData.content;
    }
    else {
        return { data };
    }
};
const handleInput = (ev) => {
    var _a;
    let pastedData = ev.data || ((_a = ev === null || ev === void 0 ? void 0 : ev.target) === null || _a === void 0 ? void 0 : _a.value);
    if (!pastedData) {
        console.error('No data');
        return;
    }
    pastedData = parsePastedData(pastedData);
    if (!isHeaderLineValid(pastedData)) {
        appendImportPreviewModal({ pastedInvalidData: { payload: pastedData.replace(/\t/g, ', '), alert: getTranslation('invalid-headers') } });
        return;
    }
    if (!hasTableContent(pastedData)) {
        appendImportPreviewModal({ pastedInvalidData: { payload: pastedData.replace(/\t/g, ', '), alert: getTranslation('no-content') } });
        return;
    }
    appendImportPreviewModal(dataValidator(pastedData));
};
export const importMembersModal = () => {
    var _a, _b;
    const result = document.createElement('import-members-modal');
    const label = document.createElement('label');
    label.textContent = getTranslation('import-xlsx');
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.id = 'file-browser';
    fileInput.accept = '.xlsx';
    const browseFileLink = document.createElement('a');
    browseFileLink.id = 'browse-file-link';
    browseFileLink.textContent = getTranslation('browse-files');
    browseFileLink.addEventListener('click', () => {
        var _a;
        (_a = document.getElementById('file-browser')) === null || _a === void 0 ? void 0 : _a.click();
    });
    const admin = ((_b = (_a = window.store) === null || _a === void 0 ? void 0 : _a.selectedTeam) === null || _b === void 0 ? void 0 : _b.admin) || window.store.currentUser.email;
    backendGet(`credits-balance-for?admin=${admin}`, (res) => {
        const percyRow = document.createElement('percy-row');
        const licensesAvailable = labelWithValue('licenses-available', res);
        const buyMore = document.createElement('a');
        buyMore.textContent = getTranslation('buy-more');
        buyMore.onclick = () => changePage('account-tab');
        percyRow.append(licensesAvailable, buyMore);
        const inputContainer = document.createElement('input-container');
        inputContainer.append(label, fileInput, browseFileLink);
        const or = document.createElement('p');
        or.textContent = getTranslation('or');
        const textarea = document.createElement('textarea');
        textarea.id = 'members-textarea';
        textarea.placeholder = getTranslation('members-textarea-placeholder');
        textarea.setAttribute('aria-label', getTranslation('members-textarea-aria-label'));
        textarea.addEventListener('input', (ev) => handleInput(ev));
        const seeExamples = document.createElement('p');
        seeExamples.textContent = getTranslation('see-examples');
        const examples = document.createElement('p');
        examples.innerHTML = `${getTranslation('open-google-sheet-template')} ${getTranslation('or')} ${getTranslation('download-xlsx-template')}`;
        const body = document.createElement('import-members-modal-body');
        body.append(percyRow, inputContainer, or, textarea, seeExamples, examples);
        const modal = percyModal(getTranslation('import-members-title'), ['cancel'], '', false, false, body, getTranslation('import-team'), getTranslation('alert-cancel-btn-txt'), () => result.remove());
        modal.setAttribute('data-cy', 'import-members-modal');
        modal.id = 'import-members-modal';
        fileInput.onchange = () => xlsxToTable(modal);
        result.appendChild(modal);
        textarea.focus();
        return result;
    }, (err) => {
        result.remove();
        logError(err);
    });
    return result;
};
