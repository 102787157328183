import * as Sentry from '@sentry/browser';
import { chosenLanguage, initLanguage, reloadWithLanguageOverride } from '@klauswuestefeld/jux-web';
import '../css/main.css';
import { initGoogle } from './jux/google-login';
import { percyApp } from './percival/components/percy-app';
import { PercyStore } from './percival/PercyStore';
import { initSession } from './percival/session';
import { translations } from './percival/translations';
try {
    Sentry.init({
        dsn: 'https://d1e17ef2ca16427a87b6b29c4425250d@o1113684.ingest.sentry.io/6144395',
    });
}
catch (error) {
    console.info('Sentry.init failed with error:', error);
}
window.addEventListener('keydown', (ev) => {
    if (ev.key === 'F8' && ev.shiftKey && ev.ctrlKey) {
        if (chosenLanguage === 'pt') {
            reloadWithLanguageOverride('en');
        }
        else {
            reloadWithLanguageOverride('pt');
        }
    }
});
// Polyfill deve entrar em ação automaticamente quando em modo mobile
// tslint:disable-next-line: no-var-requires
window.DragDropTouch = require('drag-drop-touch');
window.store = new PercyStore();
const initApp = async () => {
    document.body.insertBefore(percyApp(), document.querySelector('noscript'));
    initLanguage(translations);
    initSession();
    await initGoogle();
};
initApp();
