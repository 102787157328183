import { logError } from '../../utils/logger';
import { singularOrPluralString } from '../../utils/string';
import { loadAccountPage } from '../account';
import { backendGetPromise, backendPost, backendPostPromise, getTeamId } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { showOkText, updateCurrentUser } from '../teams';
import { creatorName } from './appraisal-subheader';
import { collapsibleSection } from './collapsible-section';
// TODO: Remover essa dependência (há dependência circular)
import { importMembersModal } from './import-members-modal';
import { label, labelWithValue } from './label-with-value';
import { membersList } from './members-list';
import { percyColumn } from './percy-column';
import { percyModal, toggleBusyStatus } from './percy-modal';
import { refreshTeamSize } from './percy-team-list';
const doImportTeam = async (members) => {
    await backendPostPromise('team-import2', JSON.stringify({ 'team-id': getTeamId(), members }))
        .then(async (team) => {
        window.store.selectedTeam = team;
        if (members.includes(window.store.currentUser.email)) {
            await updateCurrentUser();
        }
        refreshTeamSize(team.id, team.members.length);
        showOkText(team);
    })
        .catch(logError);
};
const importTeamAction = async (members, toBeConsumed) => {
    var _a;
    if (toBeConsumed === 0) {
        await doImportTeam(members);
        return;
    }
    const text = document.createElement('b');
    text.textContent = `${getTranslation('bind-licenses-warning')} ${singularOrPluralString(toBeConsumed, 'licenses')}`;
    const modal = percyModal(`${getTranslation('import-members-title')}?`, ['ok', 'cancel'], '', false, false, text, getTranslation('import-team'));
    modal.setAttribute('data-cy', 'import-members-alert');
    modal.addEventListener('ok', async () => {
        toggleBusyStatus();
        await doImportTeam(members);
        toggleBusyStatus();
        modal.remove();
    });
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(modal);
};
const notEnoughLicensesToCreator = (result, body) => {
    var _a;
    const modal = percyModal(getTranslation('import-members-title'), ['ok', 'cancel'], '', false, false, body, getTranslation('licenses-button'), getTranslation('return-link'), () => result.remove());
    modal.setAttribute('data-cy', 'import-members-modal');
    modal.id = 'import-members-modal';
    result.appendChild(modal);
    modal.addEventListener('ok', async () => {
        toggleBusyStatus();
        loadAccountPage();
        toggleBusyStatus();
        result.remove();
    });
    modal.addEventListener('cancel', async () => {
        var _a;
        (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(importMembersModal());
        result.remove();
    });
    (_a = document.querySelector('import-members-modal')) === null || _a === void 0 ? void 0 : _a.remove();
    return result;
};
const notEnoughLicensesToAdmin = (result, body) => {
    var _a;
    const modal = percyModal(getTranslation('import-members-title'), ['cancel'], '', false, false, body, '', getTranslation('return-link'), () => result.remove());
    modal.setAttribute('data-cy', 'import-members-modal');
    modal.id = 'import-members-modal';
    result.appendChild(modal);
    modal.addEventListener('cancel', async () => {
        var _a;
        (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(importMembersModal());
        result.remove();
    });
    (_a = document.querySelector('import-members-modal')) === null || _a === void 0 ? void 0 : _a.remove();
    return result;
};
const creatorContactInfo = (nameOrEmail) => {
    var _a, _b;
    if (nameOrEmail.includes('@')) {
        return nameOrEmail;
    }
    return `${nameOrEmail} (${(_b = (_a = window.store) === null || _a === void 0 ? void 0 : _a.selectedTeam) === null || _b === void 0 ? void 0 : _b.creator})`;
};
const notEnoughLicenses = (result, body, licensesInfo) => {
    var _a, _b, _c;
    const isCreator = window.store.currentUser.email === ((_b = (_a = window.store) === null || _a === void 0 ? void 0 : _a.selectedTeam) === null || _b === void 0 ? void 0 : _b.creator);
    const msg = document.createElement('p');
    msg.textContent = getTranslation('not-enough-licenses');
    msg.className = 'not-enough-licenses-msg';
    licensesInfo.className = 'not-enough-licenses';
    body.insertBefore(msg, licensesInfo);
    if (isCreator) {
        return notEnoughLicensesToCreator(result, body);
    }
    const creator = creatorContactInfo(creatorName((_c = window.store) === null || _c === void 0 ? void 0 : _c.selectedTeam));
    msg.textContent = getTranslation('not-enough-licenses-subadmin') + creator + getTranslation('to-buy-more-licenses');
    return notEnoughLicensesToAdmin(result, body);
};
const invalidData = (result, data) => {
    var _a;
    const { payload, alert } = data;
    const pasted = document.createElement('label');
    pasted.textContent = getTranslation('you-pasted');
    const pre = document.createElement('pre');
    pre.innerHTML = payload;
    const column = percyColumn({ children: [pasted, pre] });
    const modal = percyModal(`Oops! ${alert}`, ['cancel'], '', false, false, column, '', getTranslation('return-link'), () => result.remove(), 'error-title');
    modal.setAttribute('data-cy', 'import-members-modal');
    modal.id = 'invalid-data';
    result.appendChild(modal);
    result.className = 'invalid-data';
    modal.addEventListener('ok', async () => {
        toggleBusyStatus();
        loadAccountPage();
        toggleBusyStatus();
        result.remove();
    });
    modal.addEventListener('cancel', async () => {
        var _a;
        (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(importMembersModal());
        result.remove();
    });
    (_a = document.querySelector('import-members-modal')) === null || _a === void 0 ? void 0 : _a.remove();
    return result;
};
const createLicensesInfo = (missing, balance, freeTrial, membersWithLicenses, membersWithoutLicenses) => {
    const result = document.createElement('licenses-info');
    if (freeTrial) {
        backendGetPromise('free-trial-members-max').then((freeTrialMembersMax) => {
            const info = label(getTranslation('free-trial-info-1') + freeTrialMembersMax + getTranslation('free-trial-info-2'));
            info.setAttribute('data-cy', 'free-trial');
            result.appendChild(info);
            return result;
        });
    }
    if (membersWithoutLicenses === 0) {
        const info = label('bound-licenses');
        info.setAttribute('data-cy', 'bound-licenses');
        result.appendChild(info);
        return result;
    }
    result.append(labelWithValue('with-licenses', membersWithLicenses), labelWithValue('without-licenses', membersWithoutLicenses), labelWithValue('licenses-available', balance));
    if (missing > 0 && missing !== membersWithoutLicenses) {
        result.appendChild(labelWithValue('licenses-missing', missing));
        return result;
    }
    return result;
};
const createSection = (data, title) => {
    const result = document.createElement(title);
    const collapseThreshold = 7;
    if (!data.length) {
        return result;
    }
    const isOpen = data.length <= collapseThreshold;
    const collapsible = collapsibleSection(getTranslation(title), membersList(data), isOpen, data.length.toString(), title, !isOpen);
    result.appendChild(collapsible);
    return result;
};
const parseToImportPreviewData = (data) => {
    const parsedData = [];
    for (const entry of data) {
        const role = [];
        if (entry.Admin || entry.admin) {
            role.push('sub-admin');
        }
        if (entry.Appraiser || entry.appraiser || entry.Avaliador || entry.avaliador || entry['Avaliador(a)'] || entry['avaliador(a)']) {
            role.push('appraiser');
        }
        if (entry.Appraised || entry.appraised || entry.Avaliado || entry.avaliado || entry['Avaliado(a)'] || entry['avaliado(a)']) {
            role.push('appraised');
        }
        parsedData.push({
            email: entry.Email || entry.email || entry['E-mail'] || entry['e-mail'],
            name: entry.Name || entry.name || entry.Nome || entry.nome,
            picture: entry.Avatar || entry.avatar || '',
            role,
        });
    }
    return parsedData;
};
export const importPreviewModal = (importedData) => {
    const result = document.createElement('import-preview-modal');
    if (importedData.pastedInvalidData) {
        return invalidData(result, importedData.pastedInvalidData);
    }
    const data = parseToImportPreviewData(importedData.data);
    backendPost('import-preview', JSON.stringify({ 'team-id': getTeamId(), members: data }), (res) => {
        var _a, _b;
        const { 'added-members': added, 'deleted-members': deleted, 'updated-members': updated, 'unaltered-members': unaltered, } = res;
        const { balance, missing, 'members-with-licenses': membersWithLicenses, 'members-without-licenses': membersWithoutLicenses, 'free-trial': freeTrial } = res['licenses-info'];
        const licensesInfo = createLicensesInfo(missing, balance, freeTrial, membersWithLicenses, membersWithoutLicenses);
        const body = document.createElement('import-preview-modal-body');
        body.append(createSection(deleted, 'deleted-members'), createSection(added, 'added-members'), createSection(updated, 'updated-members'), createSection(unaltered, 'unaltered-members'), licensesInfo);
        if (missing > 0) {
            return notEnoughLicenses(result, body, licensesInfo);
        }
        const modal = percyModal((_a = importedData.title) !== null && _a !== void 0 ? _a : getTranslation('import-members-title'), ['ok', 'cancel'], '', false, false, body, getTranslation('import-team'), getTranslation('return-link'), () => result.remove());
        modal.setAttribute('data-cy', 'import-members-modal');
        modal.id = 'import-members-modal';
        result.appendChild(modal);
        const toBeConsumed = freeTrial ? 0 : membersWithoutLicenses;
        modal.addEventListener('ok', async () => {
            toggleBusyStatus();
            await importTeamAction(data, toBeConsumed);
            toggleBusyStatus();
            result.remove();
        });
        modal.addEventListener('cancel', async () => {
            var _a;
            (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(importMembersModal());
            result.remove();
        });
        (_b = document.querySelector('import-members-modal')) === null || _b === void 0 ? void 0 : _b.remove();
        return result;
    }, (err) => {
        result.remove();
        logError(err);
    });
    return result;
};
