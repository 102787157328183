import { getTranslation } from '@klauswuestefeld/jux-web';
import { membersList } from './members-list';
export const membersSection = () => {
    const team = window.store.selectedTeam;
    const result = document.createElement('members-section');
    if (team && team.members && team.members.length) {
        const list = membersList(team.members, team['is-admin'] === true);
        result.appendChild(list);
        return result;
    }
    const blankMembers = document.createElement('blank-members-state');
    blankMembers.textContent = getTranslation('blank-members-state');
    result.appendChild(blankMembers);
    return result;
};
