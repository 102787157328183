import { getTranslation } from '@klauswuestefeld/jux-web';
export const userPicture = (picture) => {
    const result = document.createElement('user-picture');
    const img = document.createElement('img');
    img.id = 'user-picture';
    img.src = picture || 'images/avatar.png';
    img.alt = getTranslation('user-picture-alt');
    img.setAttribute('class', 'profile-pic');
    img.draggable = false;
    img.referrerPolicy = 'no-referrer';
    img.addEventListener('error', (_err) => {
        img.src = 'images/avatar.png';
    });
    result.appendChild(img);
    return result;
};
