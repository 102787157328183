import { getTranslation } from '@klauswuestefeld/jux-web';
export const createTableCell = (text, span, cell, style, isImg = false, icon, tooltip, onclick) => {
    const tableCell = document.createElement(cell);
    if (isImg) {
        const img = document.createElement('img');
        img.src = text || 'images/avatar.png';
        tableCell.appendChild(img);
        tableCell.classList.add('img-cell');
    }
    else if (icon) {
        const img = document.createElement('img');
        img.src = icon;
        tableCell.appendChild(img);
        tableCell.classList.add('img-cell');
    }
    else {
        tableCell.textContent = getTranslation(text);
    }
    tableCell.colSpan = span;
    if (tooltip) {
        tableCell.title = getTranslation(tooltip);
    }
    if (style) {
        tableCell.classList.add(style);
    }
    if (onclick) {
        tableCell.onclick = onclick;
    }
    return tableCell;
};
export const createTableRow = (cells, clickHandler) => {
    const tr = document.createElement('tr');
    cells.forEach(({ text, span, cell, style, isImg, icon, tooltip, onclick }) => {
        const tableCell = createTableCell(text, span, cell, style, isImg, icon, tooltip, onclick);
        tr.appendChild(tableCell);
    });
    if (clickHandler) {
        tr.addEventListener('click', clickHandler);
    }
    return tr;
};
export const createTable = (data, colsOptions) => {
    const table = document.createElement('table');
    const th = document.createElement('thead');
    const headerCells = [];
    for (const key in colsOptions) {
        if (Object.prototype.hasOwnProperty.call(colsOptions, key)) {
            const element = colsOptions[key];
            headerCells.push({ text: key, span: element === null || element === void 0 ? void 0 : element.span, cell: 'th' });
        }
    }
    const header = createTableRow(headerCells);
    th.appendChild(header);
    const tb = document.createElement('tbody');
    data.forEach((row) => {
        const rowCells = [];
        for (const key in row) {
            if (Object.prototype.hasOwnProperty.call(row, key)) {
                const element = colsOptions[key];
                rowCells.push({
                    text: row[key],
                    span: element === null || element === void 0 ? void 0 : element.span,
                    cell: 'td',
                    style: element === null || element === void 0 ? void 0 : element.style,
                    isImg: element === null || element === void 0 ? void 0 : element.isImg,
                });
            }
        }
        tb.appendChild(createTableRow(rowCells));
    });
    table.append(th, tb);
    return table;
};
