import * as Sentry from '@sentry/browser';
import { getTranslation } from '@klauswuestefeld/jux-web';
import * as Flash from './flash';
export const reportError = (err, extraDetails = '') => {
    const error = { details: extraDetails, error: err };
    console.error(error);
    try {
        Sentry.captureException(error);
    }
    catch (err) {
        console.info('Sentry.captureException failed with:', err);
    }
};
export const logError = (err, friendlyMsg = null) => {
    // TODO - algumas mensagens de erro são passadas para o usuário sem traduzir
    let msg = friendlyMsg || (err === null || err === void 0 ? void 0 : err.message) || (err === null || err === void 0 ? void 0 : err.error) || (err === null || err === void 0 ? void 0 : err.details) || err.toString() || 'generic-help-msg';
    if (msg === '[object XMLHttpRequest]') {
        msg = getTranslation('generic-help-msg');
    }
    Flash.error(getTranslation(msg.details || msg), Flash.Length.long);
    reportError(err, msg);
};
