import { changePage } from '../../utils/navigation';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { editAppraisalsButton } from './edit-appraisals-button';
import { questionDetails } from './admin-question';
export const creatorName = (team) => {
    const member = team === null || team === void 0 ? void 0 : team.members.find((m) => m.email === team.creator);
    if (!member) {
        return team.creator;
    }
    return member.name;
};
export const appraisalSubheader = (isApprasing = false) => {
    const team = window.store.selectedTeam;
    const result = document.createElement('appraisal-subheader');
    const appraisalQuestion = document.createElement('appraisal-question');
    const roundQuestion = document.createElement('round-question');
    roundQuestion.innerText = !team.question ? getTranslation('default-question') : team.question;
    if (team['question-details']) {
        const details = questionDetails(team['question-details'], true);
        roundQuestion.appendChild(details);
    }
    const name = document.createElement('team-name');
    name.className = 'subheader-info';
    name.innerText = getTranslation('team') + team.name;
    name.addEventListener('click', () => changePage('teams-tab'));
    const admin = document.createElement('team-admin');
    admin.className = 'subheader-info';
    const adminTxt = creatorName(team);
    admin.innerText = getTranslation('creator') + adminTxt;
    const container = document.createElement('info-container');
    container.append(name, admin);
    appraisalQuestion.append(roundQuestion, container);
    if (!isApprasing) {
        result.appendChild(appraisalQuestion);
        return result;
    }
    const button = editAppraisalsButton();
    result.append(appraisalQuestion, button);
    return result;
};
