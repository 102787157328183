import { logError } from '../../utils/logger';
import { isValidEmail } from '../../utils/string';
import { getMXData } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { handleMagicLinkRequest } from '../session';
import { googleCaptcha } from './google-captcha';
import { socialLoginModal } from './social-login-modal';
const recommendSocialLogin = (userEmail, mailExchanger, token) => {
    var _a, _b;
    (_a = document.querySelector('magic-link-modal')) === null || _a === void 0 ? void 0 : _a.remove();
    (_b = document.querySelector('percy-body')) === null || _b === void 0 ? void 0 : _b.appendChild(socialLoginModal(userEmail, mailExchanger, token));
};
const hasProvider = (data, provider) => {
    return data.some((entry) => entry.includes(provider));
};
const getMailExchanger = async (domainName) => {
    const data = await getMXData(domainName);
    if (!data) {
        return null;
    }
    if (hasProvider(data, 'outlook.com')) {
        return 'Microsoft';
    }
    if (hasProvider(data, 'google.com')) {
        return 'Google';
    }
    return null;
};
const onMagicLinkRequest = async (token, input) => {
    const email = input.value;
    if (!email.includes('-test@') && !token) {
        logError('invalid-captcha-response');
        return;
    }
    if (isValidEmail(email)) {
        const domain = email.split('@')[1] || '';
        const mailExchanger = await getMailExchanger(domain);
        const supportedMailExchanger = mailExchanger !== null;
        if (supportedMailExchanger) {
            recommendSocialLogin(email, mailExchanger, token);
            return;
        }
    }
    handleMagicLinkRequest(token);
};
export const magicEmailField = () => {
    const result = document.createElement('magic-email-field');
    const captcha = googleCaptcha();
    const input = document.createElement('input');
    input.id = 'mail-magic';
    input.type = 'text';
    input.setAttribute('class', 'textfield');
    input.placeholder = 'e-mail';
    input.addEventListener('keydown', (ev) => {
        if (ev.key === 'Enter') {
            onMagicLinkRequest(captcha.getAttribute('token'), input);
        }
    });
    const btn = document.createElement('button');
    btn.id = 'send-magic';
    btn.setAttribute('class', 'primary-button');
    btn.addEventListener('click', (_ev) => onMagicLinkRequest(captcha.getAttribute('token'), input));
    btn.textContent = getTranslation('send-magic');
    result.append(input, captcha, btn);
    return result;
};
