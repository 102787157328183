import { getTranslation } from '@klauswuestefeld/jux-web';
import { percySpacer } from './percy-spacer';
export const collapsibleSection = (title, content, isOpen = false, subtitle = '', subtitleStyle = '', showHandle = true) => {
    const result = document.createElement('details');
    result.className = 'collapsible-section';
    result.open = isOpen;
    const summary = document.createElement('summary');
    const b = document.createElement('b');
    b.textContent = title;
    const p = document.createElement('p');
    p.textContent = subtitle;
    p.className = subtitleStyle;
    summary.append(b, p, percySpacer({ horizontal: 8 }));
    result.onclick = () => false;
    if (showHandle) {
        const a = document.createElement('a');
        a.textContent = isOpen ? getTranslation('hide') : getTranslation('show');
        result.onclick = () => {
            const parent = result.parentElement;
            parent === null || parent === void 0 ? void 0 : parent.insertBefore(collapsibleSection(title, content, !isOpen, subtitle, subtitleStyle), result);
            result.remove();
        };
        summary.appendChild(a);
    }
    result.append(summary, content);
    return result;
};
