import { Length, success, warning } from '../../utils/flash';
import { logError } from '../../utils/logger';
import { backendPostPromise, getSelectedTeam, getTeamId } from '../api-client';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { percyModal, toggleBusyStatus } from './percy-modal';
const publishRanking = async (teamId, sendEmail, anonymized) => {
    await backendPostPromise('publish-results', JSON.stringify({ 'team-id': teamId, 'send-email': sendEmail, 'publish-anonymously': anonymized }))
        .then((_res) => {
        success(getTranslation('results-published'), Length.long);
        window.store.resultsData.published = true;
        const container = document.querySelector('publish-bar-container');
        if (!container) {
            logError('publish-bar-container não foi encontrado', 'generic-help-msg');
            return;
        }
        container.style.display = 'none';
    })
        .catch((err) => {
        logError(err, getTranslation('publish-results-error'));
    });
    await getSelectedTeam();
};
const visibilityOption = (type) => {
    const result = document.createElement('visibility-option');
    result.style.display = 'flex';
    result.style.columnGap = '8px';
    const input = document.createElement('input');
    input.name = 'visibility';
    input.id = `radio-${type}`;
    input.type = 'radio';
    const label = document.createElement('label');
    label.style.cursor = 'pointer';
    label.htmlFor = input.id;
    label.textContent = getTranslation(type);
    result.append(input, label);
    return result;
};
const showPublishAlert = () => {
    var _a;
    const teamId = getTeamId();
    if (teamId === null || teamId === undefined) {
        logError('team-id-not-found');
        return;
    }
    const body = document.createElement('publish-modal');
    const txt = document.createElement('b');
    txt.textContent = getTranslation('alert-modal-text');
    const resultsVisibility = document.createElement('results-visibility');
    const visibleOption = visibilityOption('visible');
    const visibleInput = visibleOption.querySelector('input');
    const anonymizedOption = visibilityOption('anonymized');
    const anonymizedInput = anonymizedOption.querySelector('input');
    resultsVisibility.append(visibleOption, anonymizedOption);
    const notifyMembers = document.createElement('notify-team');
    const checkbox = document.createElement('input');
    checkbox.id = 'notify-checkbox';
    checkbox.type = 'checkbox';
    const label = document.createElement('label');
    label.textContent = getTranslation('send-notification');
    label.htmlFor = checkbox.id;
    notifyMembers.append(checkbox, label);
    body.append(txt, resultsVisibility, notifyMembers);
    const modal = percyModal('', ['ok', 'cancel'], '', false, false, body);
    modal.setAttribute('data-cy', 'publish-modal');
    modal.setAttribute('ok', getTranslation('alert-publish-button'));
    modal.addEventListener('ok', async () => {
        if (!visibleInput.checked && !anonymizedInput.checked) {
            warning('no-visibility-selected');
            return;
        }
        toggleBusyStatus();
        await publishRanking(teamId, checkbox.checked, anonymizedInput.checked);
        toggleBusyStatus();
        modal.remove();
    });
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(modal);
};
export const publishBar = () => {
    const result = document.createElement('publish-bar');
    const text = document.createElement('publish-bar-text');
    text.textContent = getTranslation('publish-bar-text');
    const btn = document.createElement('publish-bar-btn');
    btn.textContent = getTranslation('publish-bar-btn');
    btn.setAttribute('class', 'primary-button');
    btn.addEventListener('click', showPublishAlert);
    result.append(text, btn);
    return result;
};
