import { formatDisplayValue } from '../../utils/string';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { buildGraphBar, buildNameLine, buildResultRow, calculateBarWidth, createProfileColumn, displayTextWidth, showBlankMessage } from './results-list';
const buildCertaintyRow = (item, maxValue) => {
    const result = document.createElement('results-row');
    // Profile pic
    createProfileColumn(result, item.picture);
    // Graph Container
    const container = document.createElement('graph-container');
    // Name
    container.appendChild(buildNameLine(item.name));
    // Certainty Received Bar
    const topBarWidth = calculateBarWidth(item.notoriety, maxValue);
    container.appendChild(buildGraphBar('received-certainty-bar', topBarWidth, formatDisplayValue(item.notoriety)));
    // Certainty Given Bar
    const bottomBarWidth = calculateBarWidth(item.knowledge, maxValue);
    container.appendChild(buildGraphBar('given-certainty-bar', bottomBarWidth, formatDisplayValue(item.knowledge)));
    result.appendChild(container);
    return result;
};
const sortResultsIfNecessary = (results) => {
    var _a, _b, _c, _d;
    if (((_b = (_a = window === null || window === void 0 ? void 0 : window.store) === null || _a === void 0 ? void 0 : _a.resultsData) === null || _b === void 0 ? void 0 : _b.results.length) > 1 &&
        ((_d = (_c = window === null || window === void 0 ? void 0 : window.store) === null || _c === void 0 ? void 0 : _c.resultsData) === null || _d === void 0 ? void 0 : _d.results.filter((result) => result.name !== undefined).length) === 1) {
        results.sort((a, b) => b.notoriety - a.notoriety);
    }
};
export const certaintySection = (element) => {
    const result = element;
    result.innerHTML = '';
    result.style.marginBottom = '0';
    if (!window.store.resultsData.results.length) {
        return showBlankMessage(result, 'blank-results-msg', 'blank-results-msg-no-padding');
    }
    let maxValue = Math.max.apply(Math, window.store.resultsData.results.map((i) => {
        return ((i.knowledge || 0) > (i.notoriety || 0)) ? (i.knowledge || 0) : (i.notoriety || 0);
    }));
    const containerWidth = 750; // page-container width - 50
    const maxValueWidth = displayTextWidth(formatDisplayValue(maxValue)) + 5;
    maxValue *= (containerWidth / (containerWidth - 10 - maxValueWidth));
    result.appendChild(buildResultRow({ element: 'received-certainty-bar', value: getTranslation('certainty-results-topbar') }, { element: 'given-certainty-bar', value: getTranslation('certainty-results-bottonbar') }));
    const exhibitedResults = [...window.store.resultsData.results];
    sortResultsIfNecessary(exhibitedResults);
    exhibitedResults.forEach((item) => result.appendChild(buildCertaintyRow(item, maxValue)));
    const publishBar = document.querySelector('publish-bar-container');
    if (publishBar) {
        publishBar.style.display = 'none';
    }
    return result;
};
