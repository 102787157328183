import { accountsSection } from '../components/accounts-section';
export const superAdminPage = () => {
    const result = document.createElement('super-admin-page');
    const container = document.createElement('superadmin-container');
    const page = document.createElement('superadmin-section');
    page.setAttribute('class', 'page-container');
    page.appendChild(accountsSection());
    container.appendChild(page);
    result.appendChild(container);
    return result;
};
