import { getTranslation } from '@klauswuestefeld/jux-web';
import { showLeavingFreeTrialAlert } from './leaving-free-trial-modal';
import { showNewTeamModal } from './new-team-modal';
const singleTeamOwner = () => {
    const ownedTeams = window.store.teams.filter((team) => team.admin === window.store.currentUser.email);
    return ownedTeams.length === 1;
};
export const newTeamButton = () => {
    const result = document.createElement('new-team-button');
    const btn = document.createElement('button');
    btn.setAttribute('class', 'primary-button small');
    btn.onclick = async () => {
        if (singleTeamOwner()) {
            showLeavingFreeTrialAlert();
        }
        else {
            showNewTeamModal();
        }
    };
    const icon = document.createElement('img');
    icon.className = 'btn-icon';
    icon.src = './images/add-member.svg';
    const btnTxt = document.createElement('add-team');
    btnTxt.textContent = getTranslation('new-team');
    btn.append(icon, btnTxt);
    result.append(btn);
    return result;
};
