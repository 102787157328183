import { getTranslation } from '@klauswuestefeld/jux-web';
export const headerLogo = () => {
    const result = document.createElement('header-logo');
    const a = document.createElement('a');
    a.href = getTranslation('percival-live-url');
    a.target = '_blank';
    a.style.height = '20px';
    // Center vertically:
    a.style.marginTop = 'auto';
    a.style.marginBottom = 'auto';
    const img = document.createElement('img');
    img.draggable = false;
    img.alt = 'Percival';
    img.src = 'images/logo-white-small.png';
    img.height = 20;
    img.width = 98;
    // Center vertically
    img.style.marginTop = 'auto';
    img.style.marginBottom = 'auto';
    a.appendChild(img);
    result.appendChild(a);
    return result;
};
