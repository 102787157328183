import { getGoogleUserProfile } from '../percival/api-client';
import { onAuthentication } from '../percival/session';
const handleCredentialResponse = (credentialResponse) => {
    const { credential } = credentialResponse;
    getGoogleUserProfile(credential, (userProfile) => {
        onAuthentication(userProfile, 'Google Login');
    });
};
export const initGoogle = async () => {
    while (typeof google === 'undefined') {
        await new Promise(resolve => setTimeout(resolve, 50));
    }
    google.accounts.id.initialize({
        client_id: process.env.NEW_GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse
    });
};
export const promptGoogleOneTap = () => {
    google.accounts.id.prompt((notification) => {
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
            return;
        }
    });
};
export const renderGoogleButton = (parent) => {
    google.accounts.id.renderButton(parent, {
        type: 'standard',
        theme: 'outline',
        size: 'large',
        text: 'continue_with',
        shape: 'rectangular',
        logo_alignment: 'center',
        width: 302,
        locale: document.documentElement.lang === 'pt' ? 'pt-BR' : 'en-US',
    });
};
