import { getTranslation } from '@klauswuestefeld/jux-web';
export const label = (text) => {
    const result = document.createElement('label');
    result.textContent = getTranslation(text);
    result.style.fontWeight = 'bold';
    return result;
};
export const labelWithValue = (type, amount) => {
    const result = document.createElement('label-with-value');
    const value = document.createElement('p');
    value.textContent = amount.toString();
    result.append(label(type), value);
    result.setAttribute('data-cy', type);
    return result;
};
