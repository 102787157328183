import { logError } from '../../utils/logger';
import { backendGetPromise } from '../api-client';
import { accountsSearch } from './accounts-search';
import { accountsTable } from './accounts-table';
export const accountsSection = () => {
    const result = document.createElement('accounts-section');
    const spinner = document.createElement('percy-spinner');
    spinner.className = 'large';
    let requestDone = false;
    setTimeout(() => {
        if (!requestDone) {
            result.appendChild(spinner);
        }
    }, 2000);
    backendGetPromise('accounts')
        .then((members) => {
        requestDone = true;
        const sortedMembers = members === null || members === void 0 ? void 0 : members.sort((a, b) => b.credits - a.credits ||
            b['members-with-licenses'] - a['members-with-licenses'] ||
            b['members-without-licenses'] - a['members-without-licenses']);
        const search = accountsSearch();
        search.addEventListener('action', (ev) => {
            var _a;
            const searchTerm = ev.detail.value.trim().toLowerCase();
            const m = members === null || members === void 0 ? void 0 : members.sort((a, b) => b.credits - a.credits);
            const filteredMembers = m === null || m === void 0 ? void 0 : m.filter((member) => member.admin.toLowerCase().includes(searchTerm));
            (_a = result.querySelector('accounts-table')) === null || _a === void 0 ? void 0 : _a.remove();
            result.appendChild(accountsTable(filteredMembers));
        });
        const sectionTitle = document.createElement('p');
        sectionTitle.style.marginTop = '20px';
        sectionTitle.textContent = 'Accounts';
        sectionTitle.className = 'section-title';
        result.append(sectionTitle, search, accountsTable(sortedMembers));
        return result;
    })
        .catch((err) => {
        requestDone = true;
        logError(err);
    })
        .finally(() => spinner === null || spinner === void 0 ? void 0 : spinner.remove());
    return result;
};
