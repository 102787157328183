import { logError } from '../../utils/logger';
import { getTranslation } from '@klauswuestefeld/jux-web';
import { OnboardModal } from './OnboardModal';
import { sessionDropdown } from './session-dropdown';
import { userPicture } from './user-picture';
const toggleProfileDropdown = () => {
    const dropDown = document.querySelector('session-dropdown');
    if (!dropDown) {
        logError('session-dropdown não foi encontrado', 'generic-help-msg');
        return;
    }
    dropDown.classList.toggle('force-display-none');
};
export const percySession = (name, picture) => {
    var _a;
    const result = document.createElement('percy-session');
    const img = document.createElement('img');
    img.src = 'images/help-btn.svg';
    img.width = 28;
    img.height = 28;
    img.alt = getTranslation('help');
    img.setAttribute('class', 'help-btn');
    img.draggable = false;
    img.addEventListener('click', () => new OnboardModal({}).show());
    const userAvatar = document.createElement('user-avatar');
    userAvatar.addEventListener('click', () => toggleProfileDropdown());
    const userName = document.createElement('button');
    userName.id = 'user-name';
    userName.textContent = name;
    userAvatar.appendChild(userName);
    const dropDown = sessionDropdown();
    dropDown.setAttribute('class', 'force-display-none');
    result.append(img, userPicture(picture), userAvatar, dropDown);
    (_a = result.querySelector('#user-picture')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', toggleProfileDropdown);
    return result;
};
