import { getTranslation } from '@klauswuestefeld/jux-web';
import { editTeamModal } from './edit-team-modal';
const openEditModal = () => {
    var _a;
    const modal = editTeamModal();
    (_a = document.querySelector('percy-body')) === null || _a === void 0 ? void 0 : _a.appendChild(modal);
    const input = modal.querySelector('#team-name-modal');
    input.focus();
    input.select();
};
export const teamNameSection = (isAdmin) => {
    const team = window.store.selectedTeam;
    const result = document.createElement('team-name-section');
    const title = document.createElement('team-name-prompt-title');
    title.textContent = getTranslation('team-name-prompt-title');
    title.className = 'section-title';
    const prompt = document.createElement('team-name-prompt');
    prompt.textContent = team.name;
    if (!isAdmin) {
        result.append(title, prompt);
        return result;
    }
    const img = document.createElement('img');
    img.className = 'btn-icon';
    img.src = './images/edit-appraisal-green.svg';
    img.alt = '';
    img.setAttribute('aria-label', getTranslation('edit-question'));
    prompt.addEventListener('click', () => openEditModal());
    img.addEventListener('click', () => openEditModal());
    result.append(title, img, prompt);
    return result;
};
