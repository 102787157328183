import { promptGoogleOneTap, renderGoogleButton } from '../jux/google-login';
import { loadAccountPage } from '../percival/account';
import { loadAppraisalsPage } from '../percival/appraisals';
import { blankPage } from '../percival/components/blank-page';
import { closedRound } from '../percival/components/closed-round';
import { percyFooter } from '../percival/components/percy-footer';
import { percyHeader } from '../percival/components/percy-header';
import { teamlessAdmin } from '../percival/components/teamless-admin';
import { demoPage } from '../percival/demo';
import { accountPage } from '../percival/pages/account-page';
import { appraisalsPage } from '../percival/pages/appraisals-page';
import { disconnectedPage } from '../percival/pages/disconnected-page';
import { loginPage } from '../percival/pages/login-page';
import { magicLinkRequestedPage } from '../percival/pages/magic-link-requested-page';
import { resultsPage } from '../percival/pages/results-page';
import { superAdminPage } from '../percival/pages/super-admin-page';
import { teamsPage } from '../percival/pages/teams-page';
import { refreshCurrentUserHeader } from '../percival/session';
import { loadTeamsPage } from '../percival/teams';
export var Page;
(function (Page) {
    Page[Page["ACCOUNT"] = 0] = "ACCOUNT";
    Page[Page["TEAMS"] = 1] = "TEAMS";
    Page[Page["APPRAISALS"] = 2] = "APPRAISALS";
    Page[Page["CANT_APPRAISE"] = 3] = "CANT_APPRAISE";
    Page[Page["CLOSED_ROUND"] = 4] = "CLOSED_ROUND";
    Page[Page["DISCONNECTED"] = 5] = "DISCONNECTED";
    Page[Page["LOGIN"] = 6] = "LOGIN";
    Page[Page["LOGOUT"] = 7] = "LOGOUT";
    Page[Page["MAGIC_LOGIN"] = 8] = "MAGIC_LOGIN";
    Page[Page["NO_RESULTS"] = 9] = "NO_RESULTS";
    Page[Page["NOT_ADMIN"] = 10] = "NOT_ADMIN";
    Page[Page["RESULTS"] = 11] = "RESULTS";
    Page[Page["RESULTS_DATA"] = 12] = "RESULTS_DATA";
    Page[Page["SUPER_ADMIN"] = 13] = "SUPER_ADMIN";
    Page[Page["TEAMLESS_ADMIN"] = 14] = "TEAMLESS_ADMIN";
    Page[Page["DEMO"] = 15] = "DEMO";
})(Page || (Page = {}));
// TODO: Desduplicar e simplificar navegação
export const displayPage = async (page, args) => {
    var _a, _b;
    const body = document.querySelector('percy-body');
    if (!body) {
        return;
    }
    body.innerHTML = '';
    pages.currentPage = page;
    const app = document.querySelector('percy-app');
    (_a = document.querySelector('percy-header')) === null || _a === void 0 ? void 0 : _a.remove();
    (_b = document.querySelector('percy-footer')) === null || _b === void 0 ? void 0 : _b.remove();
    switch (page) {
        case Page.ACCOUNT:
            app === null || app === void 0 ? void 0 : app.insertBefore(percyHeader(), body);
            body.appendChild(accountPage());
            break;
        case Page.TEAMS:
            app === null || app === void 0 ? void 0 : app.insertBefore(percyHeader(), body);
            body.appendChild(teamsPage());
            break;
        case Page.APPRAISALS:
            app === null || app === void 0 ? void 0 : app.insertBefore(percyHeader(), body);
            body.appendChild(appraisalsPage());
            break;
        case Page.CANT_APPRAISE:
            app === null || app === void 0 ? void 0 : app.insertBefore(percyHeader(), body);
            body.appendChild(blankPage(args.msg));
            break;
        case Page.TEAMLESS_ADMIN:
            app === null || app === void 0 ? void 0 : app.insertBefore(percyHeader(), body);
            body.appendChild(teamlessAdmin());
            break;
        case Page.DISCONNECTED:
            app === null || app === void 0 ? void 0 : app.insertBefore(percyHeader({ isLogin: true }), body);
            body.appendChild(disconnectedPage());
            app === null || app === void 0 ? void 0 : app.appendChild(percyFooter());
            break;
        case Page.SUPER_ADMIN:
            app === null || app === void 0 ? void 0 : app.insertBefore(percyHeader(), body);
            body.appendChild(superAdminPage());
            break;
        case Page.LOGIN:
            app === null || app === void 0 ? void 0 : app.insertBefore(percyHeader({ isLogin: true }), body);
            const login = loginPage();
            body.appendChild(login);
            app === null || app === void 0 ? void 0 : app.appendChild(percyFooter());
            const googleButtonHost = login.querySelector('new-google-button');
            renderGoogleButton(googleButtonHost);
            promptGoogleOneTap();
            break;
        case Page.MAGIC_LOGIN:
            app === null || app === void 0 ? void 0 : app.insertBefore(percyHeader({ isLogin: true }), body);
            body.appendChild(magicLinkRequestedPage());
            app === null || app === void 0 ? void 0 : app.appendChild(percyFooter());
            break;
        case Page.RESULTS:
            app === null || app === void 0 ? void 0 : app.insertBefore(percyHeader(), body);
            body.appendChild(resultsPage());
            break;
        case Page.CLOSED_ROUND:
            app === null || app === void 0 ? void 0 : app.insertBefore(percyHeader(), body);
            body.appendChild(closedRound());
            break;
        case Page.DEMO:
            app === null || app === void 0 ? void 0 : app.insertBefore(percyHeader({ isLogin: true }), body);
            body.appendChild(demoPage({ onAuthentication: args.onAuthentication, setBackendToken: args.setBackendToken }));
            app === null || app === void 0 ? void 0 : app.appendChild(percyFooter());
            break;
        default:
            break;
    }
};
export const currentTab = () => {
    switch (currentPage()) {
        case Page.TEAMS:
        case Page.NOT_ADMIN:
            return 'teams-tab';
        case Page.APPRAISALS:
        case Page.CLOSED_ROUND:
        case Page.TEAMLESS_ADMIN:
        case Page.CANT_APPRAISE:
            return 'appraisals-tab';
        case Page.RESULTS:
        case Page.RESULTS_DATA:
            return 'results-tab';
        case Page.ACCOUNT:
            return 'account-tab';
        case Page.SUPER_ADMIN:
            return 'superadmin-tab';
        default:
            return '';
    }
};
export const changePage = async (pageId) => {
    var _a;
    if (pageId === currentTab()) {
        return;
    }
    if (pageId === 'appraisals-tab') {
        if (window.store.currentUserIsAdmin && !((_a = window.store.selectedTeam) === null || _a === void 0 ? void 0 : _a.members.some((member) => member.email === window.store.currentUser.email))) {
            displayPage(Page.TEAMLESS_ADMIN);
        }
        else {
            loadAppraisalsPage();
        }
    }
    else if (pageId === 'results-tab') {
        displayPage(Page.RESULTS);
    }
    else if (pageId === 'account-tab') {
        loadAccountPage();
    }
    else if (pageId === 'superadmin-tab') {
        if (window.store.isSuperAdmin) {
            displayPage(Page.SUPER_ADMIN);
        }
    }
    else {
        loadTeamsPage();
    }
    refreshCurrentUserHeader();
};
const pages = {
    names: []
};
export const currentPage = () => pages.currentPage;
